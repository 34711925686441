import React, { useLayoutEffect, useState } from "react";
import { Grid, TextField, Checkbox } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Loader from "../global/loader/Loader";
import {
    GET_CONVERSIONTYPE_DATA,
    SET_CONVERSIONTYPE_DATA,
    getPreUrlAccordingToCart,
    SUCCESS,
    CART_INITIAL_STATE,
    printDebugLog,
    STOCKPRICINGFEATURE,
    GETSINGLECONFIGS,
    GETVIDEOPATH,
} from "../../constants/constant";
import DialogBox from "../global/DialogBox";
import {
    Button, FormControlLabel
} from "@mui/material";
import CustomDataGrid from "../global/CustomDataGrid";
import { useSelector } from "react-redux";
import { snpAxios } from "../global/api";
import axios from "axios";
import { SCREEN_CUSTOM_UNIT } from "../../constants/VideoConstant";
import YouTubeVideo from "../global/YouTubeVideo";
import SingleFab from "../global/fab/SingleFab";

export default function Conversion() {
    const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [conversionTypeData, setConversionTypeData] = useState([]);
    const [formSubmitted , setFormSubmitted] = useState(false);
    const [stockPricingFeature, setStockPricingFeature] = useState(false);
    const [videoModel, setVideoModel] = useState({show:false,path:""});
    const [conversionTypeSingleData, setSingleConversionTypeData] = useState({
        unitName: "",
        unitRestrictions: false,
        smallUnitName: "",
        smallUnitCount: 0,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        //console.log("handleInputChange", name);
        if (name === 'unitRestrictions') {
            let previousCheckedValue = conversionTypeSingleData.unitRestrictions;
           
            setSingleConversionTypeData({
                ...conversionTypeSingleData,
                unitRestrictions: !previousCheckedValue,
            });
        } else {
            setSingleConversionTypeData({
                ...conversionTypeSingleData,
                [name]: value,
            });
        }
    };


    const columns = [
        { field: 'unitName', headerName: 'Unit Name', width: 170 },
        {
            field: 'unitRestrictions', headerName: 'Unit Restriction', width: 170,
            renderCell: (val) => {
                return (val.row.unitRestrictions) ? 'Yes' : 'No';
            }
        },
        { field: 'smallUnitName', headerName: 'SmallUnit Name', width: 170 },
        { field: 'smallUnitCount', headerName: 'SmallUnit Count', width: 170 },
        {
            field: "View",
            headerName: "View",
            width: 120,
            renderCell: (cellValues) => {
                return (
                    <VisibilityIcon
                        onClick={(event) => {
                            handleCellClick(event, cellValues);
                        }}
                    >
                    </VisibilityIcon>
                );
            }
        }
    ];


    const handleCellClick = (param, event) => {
        setSingleConversionTypeData({
            unitName: event.row.unitName,
            unitCount: event.row.unitCount,
            unitRestrictions: event.row.unitRestrictions,
            smallUnitName: event.row.smallUnitName,
            smallUnitCount: event.row.smallUnitCount,
        });
        setEdit(true);
        setShowDialog(true);
    };

    const handleSubmit = async () => {
        setFormSubmitted(true);
        if (!isValid()) {
            return;
        }
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + SET_CONVERSIONTYPE_DATA;
        //console.log("handleSubmit conversionTypeSingleData => ", conversionTypeSingleData);
        let filledData = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                unitName: conversionTypeSingleData.unitName,
                smallUnitName: conversionTypeSingleData.smallUnitName,
                unitCount: parseInt(conversionTypeSingleData.unitCount),
                smallUnitCount: parseInt(conversionTypeSingleData.smallUnitCount),
                unitRestrictions: conversionTypeSingleData.unitRestrictions,
            }
        };
        //console.log("handleSubmit => ", filledData);
        setShowDialog(false);
        snpAxios.post(catgUrl,filledData)
        .then(response => {
            console.log("responses => ", response.data);
            setDataLoaded(true);
            if (response.data.status === SUCCESS) {
                getConversionData();
            }
        }).catch(error => {
            console.log("error : ", error);
            //window.location.href = "#/error";
        });
    };

    const getConversionData = async () => {
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + GET_CONVERSIONTYPE_DATA;
        let data = {  
             jcJson : {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                createNew: false,
             }
        };
        setDataLoaded(false);
        snpAxios.post(
          catgUrl,
          data
        )
          .then((response) => {
            setDataLoaded(true);
            printDebugLog("Conversion Data", response.data);
            if (response.data.status === SUCCESS) {
              setConversionTypeData(response.data.jcJson.value_2);              
            }
          }).catch((error) => {
            setDataLoaded(true);
            console.log("error : ", error);
            //window.location.href = "#/error";
          });
      };

      const getSingleConfigs = (mConfigName) => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETSINGLECONFIGS + `?adminId=${CART_INITIAL_STATE.apiToken}`
          + `&configName=${mConfigName}` + `&shopHash=${storeActiveShopHash.id}`;
        let data = {};
        //const urlFormattedString = encodeURIComponent(cUrl);
        //console.log("getSingleConfigs => ", cUrl);
        axios.get(
          cUrl,
          data
        )
          .then((response) => {
            //console.log("getSingleConfigs<=",response.data);
            if (response.data !== '') {
              if (mConfigName === STOCKPRICINGFEATURE)
                setStockPricingFeature(response.data === 'Yes');
            }
          }).catch((error) => {
            console.log("error : ", error);
            //window.location.href = "#/error";
          });
      };
    

    const getErrorMessage = (field) => {
        if (!formSubmitted) {
            return;
        } else if (conversionTypeSingleData[field] === 'number' && conversionTypeSingleData[field] === 0) {
            return 'Cannot be 0'
        } else if (!conversionTypeSingleData[field]) {
            return 'Field is required';
        }
    }

    const getYoutubeVideo = (videoName,lang) => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETVIDEOPATH + `?adminId=${CART_INITIAL_STATE.apiToken}`
            + `&name=${videoName}` + `&lang=${lang}`;
        let data = {};
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                //printLog("getSingleConfigs<=",response.data);
                if (response.data !== '') {
                    setVideoModel({...videoModel,path : response.data});
                } else 
                    setVideoModel({...videoModel,path : ''});
            }).catch((error) => {
                //printLog("error : ", error);
                //window.location.href = "#/error";
                setVideoModel({...videoModel,path : ''});
            });
    };

    useLayoutEffect(() => {
        //  console.log('Printing Login Details : ',CART_INITIAL_STATE);
        getYoutubeVideo(SCREEN_CUSTOM_UNIT,'hi');
        getSingleConfigs(STOCKPRICINGFEATURE);
        getConversionData(0, 5);
    }, [storeCartType,storeApiUrl]);

    const renderDialogContent = () => {
        return (
            <React.Fragment>
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                disabled={isEdit}
                                id="unitName"
                                label="Enter Unit Name"
                                name="unitName"
                                defaultValue={conversionTypeSingleData.unitName}
                                value={conversionTypeSingleData.unitName}
                                onChange={handleInputChange}
                                error={formSubmitted && conversionTypeSingleData.unitName === ""}
                                helperText={formSubmitted ? getErrorMessage('unitName') : "Enter Only Alphabets(A-Z a-z)"}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel disabled={isEdit} control={<Checkbox checked={conversionTypeSingleData.unitRestrictions}
                                onChange={handleInputChange} id="unitRestrictions" />} name="unitRestrictions" label="Unit Restrictions" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                id="smallUnitCount"
                                label="Enter SmallUnit Count"
                                name="smallUnitCount"
                                defaultValue={conversionTypeSingleData.smallUnitCount}
                                onChange={handleInputChange}
                                disabled={isEdit }
                                error={formSubmitted && !conversionTypeSingleData.smallUnitCount}
                                helperText={formSubmitted ? getErrorMessage('smallUnitCount') : "Enter Only Digit(0-9)"}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                id="smallUnitName"
                                label="Enter SmallUnit Name"
                                name="smallUnitName"
                                disabled={isEdit}
                                defaultValue={conversionTypeSingleData.smallUnitName}
                                onChange={handleInputChange}
                                error={formSubmitted && conversionTypeSingleData.smallUnitName === ""}
                                helperText={formSubmitted ? getErrorMessage('smallUnitName') : "Enter Only Alphabets(A-Z a-z)"}
                            />
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment >

        );
    }

    const renderDialogAction = () => {
        return (
            <React.Fragment>
                {
                    !isEdit ? <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Add
                    </Button> : null
                }
                <Button variant="contained" color="primary" onClick={handleDialogClose}>
                    Dismiss
                </Button>
            </React.Fragment>
        );
    }

    const isValid = () => {
        let isConversionDataValid = true;

        if (!conversionTypeSingleData.unitName || !conversionTypeSingleData.smallUnitName || !conversionTypeSingleData.smallUnitCount ) {
            isConversionDataValid = false;
        }

        return isConversionDataValid;
    }

    const handleDialogClose = () => {
        //console.log("handleDialogClose 1");
        setSingleConversionTypeData({
            ...conversionTypeSingleData,
            unitName: "",
            unitCount: 1,
            unitRestrictions: false,
            smallUnitName: "",
            smallUnitCount: 1,
        });
        setShowDialog(false);
        setEdit(false);
        setFormSubmitted(false);
    };



    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                    {
                        stockPricingFeature && <div style={{ padding: 10 }}>
                        <Button variant="contained" color="primary" sx={{ boxShadow: 12 }} style={{ float: 'right' }} className="float-right"
                            onClick={(event) => {
                                setShowDialog(true);
                            }}>
                            Add New Conversion
                        </Button>
                    </div>
                    }
                    

                    {
                        isDataLoaded ? <CustomDataGrid
                            rows={conversionTypeData}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                        /> : <Loader />
                    }
                </Grid>
            </Grid>
            < DialogBox
                showDialog={showDialog}
                title={!isEdit ? "Add New Conversion" : "View Conversion"}
                onClose={handleDialogClose}
                dialogContent={renderDialogContent()}
                dialogActions={renderDialogAction()}
            ></DialogBox>
            {videoModel.show && <YouTubeVideo videoId={videoModel.path} onDismiss={(e) => setVideoModel({...videoModel,show:false})}/> }
            {videoModel.path && <SingleFab onClick={(e)=> setVideoModel({...videoModel,show:true})}/> }
        </React.Fragment>
    );
}


