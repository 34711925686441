import React from 'react';
import { Container, Grid, TextField, Button, Typography, Box } from '@mui/material';


function LoginFormNew() {
  return (
    <Container sx={{ my: 5 }} maxWidth="md">
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 5 }}>
            <Box sx={{ textAlign: 'center' }}>
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/lotus.webp"
                alt="logo"
                style={{ width: '185px' }}
              />
              <Typography variant="h4" sx={{ mt: 1, mb: 5, pb: 1 }}>
                We are The Lotus Team
              </Typography>
            </Box>

            <Typography>Please login to your account</Typography>

            <TextField
              label="Email address"
              id="form1"
              type="email"
              variant="outlined"
              fullWidth
              sx={{ mb: 4 }}
            />
            <TextField
              label="Password"
              id="form2"
              type="password"
              variant="outlined"
              fullWidth
              sx={{ mb: 4 }}
            />

            <Box sx={{ textAlign: 'center', pt: 1, mb: 5, pb: 1 }}>
              <Button variant="contained" color="primary" fullWidth sx={{ mb: 4 }}>
                Sign in
              </Button>
              <Typography variant="body2" color="textSecondary">
                <a href="#!" style={{ textDecoration: 'none', color: 'inherit' }}>Forgot password?</a>
              </Typography>
            </Box>

            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pb: 4, mb: 4 }}
            >
              <Typography variant="body1">Don't have an account?</Typography>
              <Button variant="outlined" color="error" sx={{ ml: 2 }}>
                Danger
              </Button>
            </Box>
          </Box>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
              height: '100%',
              p: 4,
              mb: 4,
            }}
          >
            <Typography variant="h4" sx={{ mb: 4, color: 'white' }}>
              We are more than just a company
            </Typography>
            <Typography variant="body2" sx={{ color: 'white' }}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default LoginFormNew;
