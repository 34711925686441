export const SCREEN_DASHBOARD_RESTO = "screen_dashboard_resto";
export const SCREEN_CATEOGORY = "screen_cateogory";
export const SCREEN_INVENTORY = "screen_inventory";
export const SCREEN_BILL = "screen_bill";
export const SCREEN_ACCOUNT = "screen_account";
export const SCREEN_TABLE_CONFIG = "screen_table_config";
export const SCREEN_CONFIG = "screen_config";
export const SCREEN_EMP = "screen_employee";
export const SCREEN_ITEM_SALE_DASHBOARD = "screen_item_sale_dashboard";
export const SCREEN_OWNER_DASHBOARD = "screen_ownner_dashboard";
export const SCREEN_SUPPLIER_MANAGEMENT = "screen_supplier_management";
export const SCREEN_THEME_MANAGEMENT = "screen_theme_management";
export const SCREEN_CUSTOM_UNIT = "screen_custom_unit";
export const SCREEN_CUSTOMER_DETAILS = "screen_customer_details";
export const SCREEN_COMBO_OFFER = "screen_combo_offer";
export const SCREEN_TRACK_RIDER = "screen_track_rider";
export const SCREEN_PROFILE = "screen_profile";
export const SCREEN_ECART = "screen_ecart";