import React, { useEffect, useState } from "react";
import SnpComponent from "../global/SnpComponent";
import { useSelector } from "react-redux";
import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { ASKHELP, AXIOS_TIMEOUT,  CART_INITIAL_STATE, SUCCESS, getPreUrlAccordingToCart, isEmpty, printInfoLog } from "../../constants/constant";
import imageUploadService from "../../service/imageUploadService";
import { snpAxios } from '../global/api';

export default function HelpModule({ setComponentIndex }) {
    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        isLoader: false,
        iconReq: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
    };
    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const todayFormattedDate = new Date().toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
    const HELPDATA = {
        ticketId: "",
        attachments: [],
        comments: "",
        createdAt: todayFormattedDate,
        solvedAt: "",
        solved: false,
        adminComments: "",
    };
    const [helpData, setHelpData] = useState(HELPDATA);
    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };

    const [uploadedDocument, setUploadedDocument] = useState([]);

    const handleSnpCancel = () => {
        setSnpComponent(INITIAL_SNP_COMPONENT);
    };

    const handleSnpDialogClose = () => {
        setSnpComponent(INITIAL_SNP_COMPONENT);
    };

    const handleSnpSubmit = () => {
        console.log("handleSnpSubmit", snpComponent.requestType);
    };


    useEffect(() => {
        //UseEfffect
    }, [storeActiveShopHash,storeApiUrl]);
    const handleInputChange = (event) => {
        //console.log("handleInputChange",event);
        const { name, value } = event.target;
        // console.log("handleInputChange", name, value);
        if (name === 'imgPath') {
            let file = event.target.files[0];
            if (!file) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Invalid File Selected! Please Try Again",
                    snackBarOpen: true,
                })
            } else if (file.size > 5242880) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Invalid File Size Should be less than 5Mb",
                    snackBarOpen: true,
                })
            } else if (!(file.type.includes("image"))) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Please Select Image",
                    snackBarOpen: true,
                })
            } else {
                setUploadedDocument(prevDocuments => [...prevDocuments, file]);
            }
        } else {
            setHelpData({
                ...helpData,
                [name]: value,
            });
        }
    };

    const handleImageUpload = async () => {
        if (!uploadedDocument) {
            setSnpComponent({
                ...snpComponent,
                isLoader: true,
            })
            handleSubmitHelp("");
        } else {
            setSnpComponent({
                ...snpComponent,
                isLoader: true,
            })
            let formData = new FormData();
            formData.append('fnm', CART_INITIAL_STATE.apiToken);
            formData.append("file", uploadedDocument[0]);
            await imageUploadService.upload(storeApiUrl.BACKEND_BASE_URL,getPreUrlAccordingToCart(storeActiveShopHash.cartType), formData).then(response => {
                console.log("handleImageUpload", response.data);
                if (response.data.folderName) {
                    handleSubmitHelp(response.data.folderName);
                } else {
                    handleSubmitHelp("");
                }

            }
            ).catch(error => {
                handleSubmitHelp("");
                console.log("handleImageUpload = > ", error);
                // window.location.href = "#/error";
            });
        }


    };
    const handleSubmitHelp = (folderName) => {
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + ASKHELP;

        let filledData = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                comments: helpData.comments,
                ticketId: helpData.ticketId,
                attachments: folderName,
            }
        };
        printInfoLog("handleSubmitHelp filledData => ", filledData);
        snpAxios.post(catgUrl,filledData)
        .then(response => {
            setSnpComponent({
                ...snpComponent,
                isLoader: false,
            })
            printInfoLog("responses => ", response.data);
            if (response.data.status === SUCCESS) {

                setHelpData({
                    ...helpData, ...response.data.jcJson
                });
            }
        }).catch(error => {
            setSnpComponent({
                ...snpComponent,
                isLoader: false,
            })
            console.log("error : ", error);
            //window.location.href = "#/error";
        });
    }

    const renderSnpContent = () => {
        return (
            <React.Fragment>
                <Grid container spacing={0}>
                    <Grid item md={12} xs={12} lg={12} sm={12}>
                        <Paper
                            elevation={0}
                            autoHeight
                            fullWidth
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                backgroundColor: "background.paper",
                                boxShadow: 9,
                                borderRadius: 2,
                                padding: 2,
                            }}
                        >
                            <div>
                                {
                                    !isEmpty(helpData.ticketId) &&
                                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                        <Typography variant="p" fontSize={18} alignItems={"left"} justifyItems={"left"} style={{ color: "#ee1a1a" }}>
                                            {"Ticket Id: " + helpData.ticketId}
                                        </Typography>
                                    </div>
                                }
                                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                    <Typography variant="p" fontSize={16} align="right" alignItems={"right"} justifyItems={"right"}>
                                        {isEmpty(helpData.ticketId) ? "Today Date: " + helpData.createdAt : "Created At: " + helpData.createdAt}
                                    </Typography>
                                    <br /><br />
                                </div>
                                <Grid container spacing={1} justifyContent="flex-start"
                                    alignItems="flex-start" sx={{
                                        padding: 0,
                                    }}>
                                    {
                                        helpData.solved &&
                                        <Typography variant="p" fontSize={16} style={{ color: "#0c1485" }}>
                                            {"Resolved On: " + helpData.solvedAt}
                                        </Typography>
                                    }
                                    <Grid item md={8} xs={12} lg={8} sm={8}>
                                        <TextField
                                            required
                                            id="comments"
                                            variant="filled"
                                            size="small"
                                            label="Enter Your Query"
                                            name="comments"
                                            disabled={helpData.solved}
                                            helperText="You Can ask for new design or any existing change or if any issue in using?"
                                            defaultValue={helpData.comments}
                                            onChange={handleInputChange}
                                            multiline={true}
                                            rows={5}
                                            style={{ width: "100%" }}
                                        />
                                    </Grid>
                                    {
                                         isEmpty(helpData.ticketId) &&
                                         <Grid item md={8} xs={12} lg={8} sm={8}>
                                        <TextField
                                            accept="image/*"
                                            id="imgPath"
                                            name="imgPath"
                                            label=""
                                            multiple
                                            type="file"
                                            autoHighlight
                                            size="small"
                                            helperText="Attached Screenshots(If Any and it should be less than 5Mb.)"
                                            onChange={handleInputChange}
                                            fullWidth
                                        />

                                    </Grid>
                                    }
                                    

                                    {helpData.attachments[0] &&
                                        <Grid item md={8} xs={12} lg={8} sm={8}>
                                            <img
                                                alt=""
                                                style={{ height: "80px", width: "80px", borderRadius: "40%" }}
                                                // src={storeApiUrl.BACKEND_BASE_URL + "files/" + imgv}
                                                src={storeApiUrl.BASE_IMAGE_URL + helpData.attachments[0]}
                                            />

                                        </Grid>}
                                    <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{helpData.adminComments}</p>
                                    <Grid item md={8} xs={12} lg={8} sm={8}>
                                        <Button
                                            variant="contained" color="primary" onClick={handleImageUpload}>
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };



    return (
        <React.Fragment>
            <SnpComponent
                content={renderSnpContent()}
                showDialog={snpComponent.showDialog}
                isLoader={snpComponent.isLoader}
                title={snpComponent.title}
                subTitle={snpComponent.subTitle}
                negativeAction={snpComponent.negativeAction}
                positiveReq={snpComponent.positiveReq}
                positiveAction={snpComponent.positiveAction}
                negativeReq={snpComponent.negativeReq}
                handleDialogClose={handleSnpDialogClose}
                iconReq={snpComponent.iconReq}
                onSnackClose={handleSnackClose}
                cancelExtra={snpComponent.cancelExtra}
                onCancel={handleSnpCancel}
                snackBarOpen={snpComponent.snackBarOpen}
                snackBarMsg={snpComponent.snackBarMsg}
                outSideClickDismiss={snpComponent.outSideClickDismiss}
                handleSubmit={handleSnpSubmit}
            />
        </React.Fragment>
    );
}