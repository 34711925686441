import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Paper,
  Avatar
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Link, Typography } from '@mui/material';
import Button from "@mui/material/Button";
import useForm from "./useForm";
import validate from "./validateInfo";
import "./Form.scss";
import {  useSelector } from "react-redux";
import { setApiUrl } from "../../actions";
import axios from "axios";
import { CALLINGURL, GETIPADDRESS, isEmpty, offline_enable,printDebugLog,printInfoLog,setUrl } from "../../constants/constant";
import YouTubeVideo from "../global/YouTubeVideo";
import SingleFab from "../global/fab/SingleFab";
import Loader from "../global/loader/Loader";

const paperStyle = {
  padding: "30px 20px",
  height: "auto",
  '@media (maxWidth: 768px)': {
    justifyContent:'center',
  },
  width: 300,
  display:"flex",
  flexDirection:"column",
  margin: "140px auto",
};
const avatarStyle = { backgroundColor: "#1bbd7e" };
const btnstyle = { margin: "10px 0" };

const LoginForm = ({ submitForm }) => {
  const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
  const [baseUrl, setBaseUrl] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [loader,setLoader] =useState(false);
  useEffect(() => {
    console.log("useEffect Login" ,storeApiUrl,storeApiUrl.BACKEND_BASE_URL);
    if (offline_enable) {
      getIpAddress();
    } else {
      let baseUrl = setUrl(CALLINGURL);
      setApiUrl(baseUrl);
      setBaseUrl(baseUrl.BACKEND_BASE_URL);
    }
}, []);


const getIpAddress = () => {
  let data = {};
    
  axios.get(GETIPADDRESS,data)
      .then((response) => {
          printDebugLog("getIpAddress:",response.data);
          if (!isEmpty(response.data)) {
              let baseUrl = setUrl(response.data);
              setApiUrl(baseUrl);
              setBaseUrl(baseUrl.BACKEND_BASE_URL);
          } else {
              printInfoLog("not running");
          }
      }).catch((error) => {
          printInfoLog("error : ", error);
          //window.location.href = "#/error";
      });
};




  const { handleInputChange, values, handleSubmit, errors, errResponse } = useForm(
    baseUrl,
    submitForm,
    validate,
    setLoader
  );

  return (
    <div style={{
      //backgroundImage: `url("https://4.bp.blogspot.com/-n-rV-fF2-vA/V0RqmAN4pBI/AAAAAAAAK2M/I6btAd8JoaY_BLpcForZzorg4pOhCQHXwCLcB/s1600/cobblestone-sidewalk-at-night-light-photography-1920x1200-wallpaper136975.jpg")`
      backgroundColor: "primary.paper"
    }}>
      <form autoComplete="off" onSubmit={handleSubmit} className="formInputs">

        <Grid container spacing={1}>
          <Paper elevation={14} style={paperStyle} sx={{
            borderRadius: 4,
            backgroundColor: "white",
          }}>
            
            <Grid align="center">
              <Avatar style={avatarStyle}>
                <LockOutlinedIcon />
              </Avatar>
              <h2 className="signIn">Sign In</h2>
            </Grid>
            {showVideo && <YouTubeVideo videoId={'wQ1gN30QJVM'} onDismiss={(e) => setShowVideo(false)}/> }
            <TextField
              variant="standard"
              label="Useremail"
              placeholder="Enter useremail"
              value={values.useremail}
              type="email"
              name="useremail"
              required
              disabled={loader}
              fullWidth
              onChange={handleInputChange}
            />
            {errors.useremail && <p>{errors.useremail}</p>}
            <TextField
              variant="standard"
              label="Password"
              disabled={loader}
              placeholder="Enter password"
              value={values.password}
              type="password"
              name="password"
              required
              fullWidth
              onChange={handleInputChange}
            />
            {errors.password && <p>{errors.password}</p>}
            {errResponse.error && <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{errResponse.errMsg}</p>}
            {
              !loader && <Button
                type="submit"
                color="primary"
                variant="contained"
                style={btnstyle}
                fullWidth
              // onClick={login}
              >
                Sign in
              </Button>
            }
            {loader && <Loader/>}
            <Copyright sx={{ pt: 1}} />
          </Paper>
        </Grid>
      </form>
      <SingleFab onClick={(e)=> setShowVideo(true)}/>
    </div>
  );
};


function Copyright(props) {
  return (
    <Typography
      variant="p"
      color="grey"
      fontSize={7}
      align="right"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="http://snpnextgen.com/">
        SNP A2Z Solutions
      </Link>{" "}
      {new Date().getFullYear()}
      {". An ISO 9001:2015 Certified Company"}
    </Typography>
  );
}
export default LoginForm;
