import axios from "axios";
import { AXIOS_TIMEOUT, IMAGE_UPLOAD_ENDPOINT } from "../constants/constant";

class ImageUploadService {
  upload(baseUrl,type, formData) {
    console.log("fd: ", formData);
    let folderName;
    for (var key of formData.entries()) {
      console.log(key[0] + ", " + key[1]);
      if(key[0]==="fnm")
        folderName=key[1];
      
    }

    //const url = BACKEND_BASE_URL + IMAGE_UPLOAD_ENDPOINT;
    const url= baseUrl +type+ IMAGE_UPLOAD_ENDPOINT + `?fnm=${folderName}`;
    console.log("url: ", url);
    return axios.post(
      url,formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: AXIOS_TIMEOUT
      }
    );
  }
}
export default new ImageUploadService();
