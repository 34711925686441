import { useState } from "react";
import {
  VERIFY_USER_CREDENTIALS,
  detectBrowser,
  getOperatingSystem,
  SUCCESS,
  ACCESSDENIED,
  FAILED,
  setLoginResponse,
  VALIDITYEXPIREDERR,
  isEmpty,
  setUrl
} from "../../constants/constant";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setCartType,setActiveShopHash, setApiUrl } from "../../actions";

const useForm = (baseUrl,callback, validate,setLoader) => {
  const dispatch = useDispatch();
  
  const [values, setValues] = useState({
    useremail: "",
    password: "",
  });
  
  const [errors, setErrors] = useState({});
  const [errResponse, setErrResponse] = useState({
    error: false,
    errMsg: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors(validate(values));
    if (Object.keys(errors).length === 0) {
      verifyCredentials();
    }
    // setIsSubmit(true);
  };

  const verifyCredentials = async () => {
    setLoader(true);
    let data = {
      userEmail: values.useremail,
      userPassWord: values.password,
      apiToken: "",
      deviceName: getOperatingSystem() + "," + detectBrowser(),
    };
    //  console.log("sending... " , data);
    axios.post(
      baseUrl+ VERIFY_USER_CREDENTIALS,
      data
    )
      .then((response) => {
        //console.log("login data... ", response.data);
        setLoader(false);
        if (response.data.errCode === SUCCESS) {
          setLoginResponse(response.data, values.password);
          dispatch(setCartType(response.data.cartType))
          dispatch(setActiveShopHash(response.data.activeShopHash))
          if (!isEmpty(response.data.ip)) {
            let baseUrl = setUrl(response.data.ip);
            setApiUrl(baseUrl);
            console.log("setApiUrl: after login",baseUrl);
          }
          callback();
          console.log("Login Successfull");
        } else if (response.data.errCode === ACCESSDENIED) {
          setErrResponse({
            error: true,
            errMsg: "Sorry! This Feature is Inactive for you ! Contact the SNP",
          });
        } else if (response.data.errCode === FAILED) {
          setErrResponse({
            error: true,
            errMsg: "UserName and Password is wrong",
          });
        } else if (response.data.errCode === VALIDITYEXPIREDERR) {
          setErrResponse({
            error: true,
            errMsg: "Your Validity is Expired!Contact SNP Administrator",
          });
        }
      }).catch((error) => {
        console.log("a2z_login err ",error.toJSON());
        setLoader(false);
        //window.location.href = "#/error";
        setErrResponse({
          error: true,
          errMsg: "Network Error,Please check your internet. Try again!",
        });
      });
  };

  // useEffect(() => {
  //   if (Object.keys(errors).length === 0 && isSubmit) {
  //     callback();           //navigate to other page
  //   }
  // }, [errors]);

  return { handleInputChange, values, handleSubmit, errors , errResponse};
};

export default useForm;
