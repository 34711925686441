import React, { useLayoutEffect, useState } from "react";
import { Grid, TextField, Checkbox, Autocomplete, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Snackbar from '@mui/material/Snackbar';
import {
    SHOWUSERTABLE,
    UPDATETABLEDATA,
    AXIOS_TIMEOUT,
    getPreUrlAccordingToCart,
    SUCCESS,
    CART_INITIAL_STATE,
     isRoleAllowed, FETCHFEWEMPLOYEE, CHANGEINTABLE, isEmpty, INVALIDBILL, TABLEOWNERRIGHTS, ADMINRIGHTS,
     GETVIDEOPATH,
     GETSINGLECONFIGS,
     ECARTAPP,
     printInfoLog,
     detectBrowser,
     printDebugLog
} from "../../constants/constant";

import DialogBox from "../global/DialogBox";
import {
    Button,
} from "@mui/material";
import CustomDataGrid from "../global/CustomDataGrid";
import { useSelector } from "react-redux";
import { snpAxios } from "../global/api";
import { SCREEN_TABLE_CONFIG } from "../../constants/VideoConstant";
import YouTubeVideo from "../global/YouTubeVideo";
import SingleFab from "../global/fab/SingleFab";
import axios from "axios";
import SnpButton from "../global/SnpButton";
import DismissButton from "../global/DismissButton";
import utilities from "../../utilities/utilities";
import printJS from "print-js";

export default function BookTable({ showDialogBoxWithPaper }) {
    const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [employeeData, setEmployee] = useState([]);
    const [isEdit, setEdit] = useState(false);
    const [videoModel, setVideoModel] = useState({show:false,path:""});

    const [singleEmployeeData, setSingleEmployee] = useState({
        id: "",
        name: "",
    });
    const INITIALTABLESINGLEDATA = {
        id: "",
        tableNo: 1,
        size: 0,
        changeSize: 0,
        tableName: "",
        tableBooked: false,
        tableLocked: false,
        offlineTable: false,
    };
    const [tableSingleData, setSingleTableData] = useState(INITIALTABLESINGLEDATA);
    const [transferOrMergeTableNo, setTransferOrMergeTableNo] = useState('');
    const [utils, setUtils] = useState({
        snackBarOpen: false,
        snackBarMsg: "",
    });

    const [ecartApp,setEcartApp] = useState(false);

    // const handleEmployeeChange = (event, option) => {
    //     if (option != null) {
    //         //console.log("handleSupplierChange", event, option);
    //         setSingleEmployee({
    //             ...singleEmployeeData,
    //             id: option.id,
    //             name: option.name
    //         });
    //     }
    // };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        //console.log("handleInputChange",name , value);
        if (name === 'tableLocked') {
            let previousCheckedValue = tableSingleData.tableLocked;
            setSingleTableData({
                ...tableSingleData,
                tableLocked: !previousCheckedValue,
            });
        } else if (name === 'transferOrMergeTableNo') {
            setTransferOrMergeTableNo(value);
        } else if (name === 'offlineTable') {
            let previousCheckedValue = tableSingleData.offlineTable;
            setSingleTableData({
                ...tableSingleData,
                offlineTable: !previousCheckedValue,
            });
        } else {
            setSingleTableData({
                ...tableSingleData,
                [name]: value,
            });
        }
    };

    const columns = [
        { field: "tableNo", headerName: "No", width: 70 },
        { field: "tableName", headerName: "Name", width: 150 },
        {
            field: "tableBooked",
            headerName: "Booked Status",
            width: 150,

        },
        // { field: "assignedName", headerName: "Table Owner", width: 150 },
        {
            field: "view",
            headerName: "View",
            width: 110,
            renderCell: (cellValues) => {
                return (
                    <EditIcon
                        onClick={(event) => {
                            if (isRoleAllowed(TABLEOWNERRIGHTS) || isRoleAllowed(ADMINRIGHTS)) {
                                handleCellClick(event, cellValues);
                            } else {
                                showDialogBoxWithPaper("You Are not allowed to Update Table.Contact the Owner");
                            }
                        }}
                    ></EditIcon>
                );
            },
        },
    ];

    const handleCellClick = (param, event) => {
        setEdit(true);
        setSingleEmployee({
            ...singleEmployeeData,
            id: event.row.assignedId,
            name: event.row.assignedName,
        });

        setSingleTableData({
            ...tableSingleData,
            id: event.row.id,
            tableNo: event.row.tableNo,
            tableName: event.row.tableName,
            tableBooked: event.row.tableBooked,
            tableLocked: event.row.tableLocked,
            offlineTable: event.row.offlineTable,
        });
        setShowDialog(true);
    };

    const handleTableUpdate = async () => {
        let catgUrl =
            storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + UPDATETABLEDATA;
        let filledData = {
            jcJson: {
                value: CART_INITIAL_STATE.apiToken,
                mult: (tableSingleData.changeSize > 0),
                changeSize: parseInt(tableSingleData.changeSize),
                id: tableSingleData.id,
                tableBooked: tableSingleData.tableBooked,
                tableLocked: tableSingleData.tableLocked,
                tableName: tableSingleData.tableName,
                assignedTo: singleEmployeeData.id,
                offlineTable: tableSingleData.offlineTable,
                shopHash: storeActiveShopHash.id,
            },
        };
        //console.log("handleTableUpdate => ",filledData);
        setShowDialog(false);
        setDataLoaded(false);
        snpAxios.post(catgUrl,filledData)
            .then((response) => {
                //console.log("responses => ", response.data);
                setDataLoaded(true);
                handleDialogClose();
                if (response.data.status === SUCCESS) {
                    getTableData();
                }
            })
            .catch((error) => {
                setDataLoaded(true);
                console.log("error : ", error);
                //window.location.href = "#/error";
            });
    };


    const handleTableChange = async (mTransfer) => {
        if (isEmpty(transferOrMergeTableNo)) {
            setUtils({
                ...utils,
                snackBarOpen: true,
                snackBarMsg: "Enter Transfer/Merge Table Number",
              });
        } else {
            let catgUrl =
            storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + CHANGEINTABLE;
        let filledData = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                isTransfer: mTransfer,
                id: tableSingleData.id,
                tableNo: transferOrMergeTableNo,
                shopHash: storeActiveShopHash.id,
            },
        };
        //console.log("handleTableChange => ",filledData);
        setShowDialog(false);
        setDataLoaded(false);
        snpAxios.post(catgUrl,filledData)
            .then((response) => {
                console.log(" handleTableChangeresponses => ", response.data);
                setDataLoaded(true);
                if (response.data.status === SUCCESS) {
                    getTableData();
                } else if (response.data.status === INVALIDBILL) {
                    setUtils({
                        ...utils,
                        snackBarOpen: true,
                        snackBarMsg: "Table Number: "+transferOrMergeTableNo+" is already booked",
                      });
                }
                handleDialogClose();
            })
            .catch((error) => {
                setDataLoaded(true);
                console.log("error : ", error);
                //window.location.href = "#/error";
            });
        }
    };

    const getTableData = async () => {
        let custUrl =
            storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + SHOWUSERTABLE;
        let data = {
            jcJson: {
                value: CART_INITIAL_STATE.apiToken,
                offline: true,
                shopHash: storeActiveShopHash.id,
            },
        };
        //   console.log("verifyCredentials => ",data);
        setDataLoaded(false);
        snpAxios
            .post(custUrl, data)
            .then((response) => {
                //console.log("getTableData response",response.data);
                setDataLoaded(true);
                if (response.data.status === SUCCESS) {
                    setSingleTableData({
                        ...tableSingleData,
                        tableNo: parseInt(response.data.jcJson.size) + 1,
                        size: parseInt(response.data.jcJson.size),
                    });
                    setTableData(response.data.jcJson.value_2);
                } else {
                    setTableData([]);
                    setSingleTableData(INITIALTABLESINGLEDATA);
                }
            })
            .catch((error) => {
                setDataLoaded(true);
                console.log("Error : ", error);
                //window.location.href = "#/error";
            });
    };

    const getEmployeeData = async () => {
        let custUrl =
            storeApiUrl.BACKEND_BASE_URL + FETCHFEWEMPLOYEE;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
            },
        };
        setDataLoaded(false);
        snpAxios
            .post(custUrl, data)
            .then((response) => {
                console.log("getEmployeeData response", response.data);
                setDataLoaded(true);
                if (response.data.status === SUCCESS) {
                    setEmployee(response.data.jcJson.value_2);
                }
                getTableData(0, 5);
            })
            .catch((error) => {
                setDataLoaded(true);
                console.log("Error : ", error);
                //window.location.href = "#/error";
                getTableData(0, 5);
            });
    };

    const getSingleConfigs = (mConfigName) => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETSINGLECONFIGS + `?adminId=${CART_INITIAL_STATE.apiToken}`
            + `&configName=${mConfigName}&shopHash=${storeActiveShopHash.id}`;
        let data = {};
        //const urlFormattedString = encodeURIComponent(cUrl);
        //printLog("getSingleConfigs => ", cUrl);
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                //printLog("getSingleConfigs<=",response.data);
                if (response.data !== '') {
                    if (mConfigName === ECARTAPP)
                        setEcartApp(response.data === 'Yes')
                }
            }).catch((error) => {
                //printLog("error : ", error);
                //window.location.href = "#/error";
            });
    };

    const getYoutubeVideo = (videoName,lang) => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETVIDEOPATH + `?adminId=${CART_INITIAL_STATE.apiToken}`
            + `&name=${videoName}` + `&lang=${lang}`;
        let data = {};
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                //printLog("getSingleConfigs<=",response.data);
                if (response.data !== '') {
                    setVideoModel({...videoModel,path : response.data});
                } else 
                    setVideoModel({...videoModel,path : ''});
            }).catch((error) => {
                //printLog("error : ", error);
                //window.location.href = "#/error";
                setVideoModel({...videoModel,path : ''});
            });
    };

    const printFallBackMechanism = (printUrl) => {
        //let browser = detectBrowser();
        const url = storeApiUrl.BACKEND_BASE_URL + "files/" + printUrl;
        window.open(url, '_blank');
        // if (browser === 'Chrome') {
        //     printJS(url);
        // } else
            
    };

    const genQrCode = (hash) => {
        setDataLoaded(false);
        hash = utilities.encode(hash);
        let shopHash = utilities.encode(storeActiveShopHash.id);
        let cUrl = storeApiUrl.BACKEND_BASE_URL + "cart/generate-table-qr" + `?shopHash=${shopHash}`
            + `&tableHash=${hash}`;
        let data = {};
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                printDebugLog("genQrCode responses => ", response.data);
                setDataLoaded(true);
                if (response.data.status === SUCCESS) {
                    printFallBackMechanism(response.data.jcJson.path);
                }
            }).catch((error) => {
                printInfoLog("genQrCode error : ", error);
                //window.location.href = "#/error";
                setDataLoaded(true);
                
            });
    }


    useLayoutEffect(() => {
        //console.log('Printing storeCartType : ', storeCartType);
        getYoutubeVideo(SCREEN_TABLE_CONFIG,'hi');
        getSingleConfigs(ECARTAPP);
        //getEmployeeData();
        getTableData(0, 5);
    }, [storeCartType, storeActiveShopHash,storeApiUrl]);

    const renderDialogContent = () => {
        return (
            <React.Fragment>
                <div>
                    <Grid container spacing={1} justifyContent="center"
                        alignItems="center" sx={{
                            padding: 0.1,
                        }}>
                        {/* <Grid item md={12} xs={12} lg={12} sm={12} spacing={0.1}>
                            <FormControlLabel control={<Checkbox checked={tableSingleData.offlineTable}
                                onChange={handleInputChange} id="offlineTable" />} name="offlineTable" label="Check If Table is Offline" />
                        </Grid> */}
                        <Grid item md={6} xs={12} lg={6} sm={6} spacing={0.1}>
                            <TextField
                                disabled
                                id="tableNo"
                                label="Table Number"
                                variant="filled"
                                size="small"
                                name="tableNo"
                                defaultValue={tableSingleData.tableNo}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item md={6} xs={12} lg={6} sm={6} spacing={0.1}>
                            <TextField
                                disabled={tableSingleData.tableBooked}
                                id="tableName"
                                label="Enter Table Name"
                                variant="filled"
                                size="small"
                                name="tableName"
                                defaultValue={tableSingleData.tableName}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>
                        {/* <Grid item md={6} xs={12} lg={6} sm={6} spacing={0.1}>
                            <FormControlLabel control={<Checkbox checked={tableSingleData.tableLocked}
                                onChange={handleInputChange} id="tableLocked" />} name="tableLocked" label="Reserved" />
                        </Grid> */}
                        {/* <Grid item md={8} xs={12} lg={8} sm={12}>
                            < Autocomplete
                                id="emp-select"
                                variant="filled"
                                size="small"
                                options={employeeData}
                                autoHighlight
                                onChange={handleEmployeeChange}
                                fullWidth
                                disabled={!isEmpty(singleEmployeeData.name)}
                                getOptionLabel={(option) => option.name}
                                defaultValue={singleEmployeeData}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                    >
                                        {option.name}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Table Owner"
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                    />
                                )}
                            />
                        </Grid> */}
                        {
                            tableSingleData.tableBooked && <Grid item md={6} xs={12} lg={6} sm={6}> <TextField
                                id="transferOrMergeTableNo"
                                label="Enter Transfer/Merge Table Number"
                                variant="filled"
                                size="small"
                                type="number"
                                helperText="Only One Table Can be merge at a time"
                                name="transferOrMergeTableNo"
                                defaultValue={transferOrMergeTableNo}
                                onChange={handleInputChange}
                                fullWidth
                            /> </Grid>
                        }

                        {
                            !isEdit && <Grid md={12} xs={12} lg={12} sm={12}>
                                <center>
                                    <p ><u>**Enter How Many Tables Are there**</u> </p></center>
                            </Grid>
                        }
                        {
                            !isEdit && <Grid item md={6} xs={12} lg={6} sm={6}>
                                <TextField
                                    id="changeSize"
                                    label="Enter Total Tables"
                                    type="number"
                                    name="changeSize"
                                    defaultValue={tableSingleData.changeSize}
                                    variant="filled"
                                    size="small"
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            (ecartApp && !isEmpty(tableSingleData.id))  &&
                            <Grid item md={6} xs={12} lg={6} sm={6} spacing={0.1}>
                                <TextField
                                    id="aboutTable"
                                    label="Enter Table Features"
                                    variant="filled"
                                    size="small"
                                    name="aboutTable"
                                    rows={2}
                                    minRows={2}
                                    maxRows={5}
                                    defaultValue={tableSingleData.aboutTable}
                                    onChange={handleInputChange}
                                    helperText="Like Size of Table"
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            ecartApp && <Grid item md={6} xs={12} lg={6} sm={6} spacing={0.1}>
                                <SnpButton variant="contained" color="primary" onClick={(e) => genQrCode(tableSingleData.id)}>
                                    Generate Table Qr Code
                                </SnpButton>
                            </Grid>
                        }
                    </Grid>
                </div>
            </React.Fragment>
        );
    };

    const renderDialogAction = () => {
        return (
            <React.Fragment>
                <SnpButton variant="contained" color="primary" onClick={handleTableUpdate}>
                    Update
                </SnpButton>
                {
                    tableSingleData.tableBooked &&
                    <SnpButton mbgcolor="#420452"  onClick={() => handleTableChange(true)}>
                        Transfer
                    </SnpButton>
                }
                {
                    tableSingleData.tableBooked && <SnpButton mbgcolor="#52040c" onClick={() => handleTableChange(false)}>
                        Merge Table
                    </SnpButton>
                }
                <DismissButton  onClick={handleDialogClose}>
                    Dismiss
                </DismissButton>
            </React.Fragment>
        );
    };
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setUtils({
          ...utils,
          snackBarOpen: false,
          snackBarMsg: "",
        });
      };
    const handleDialogClose = () => {
        // console.log("handleDialogClose");
        setEdit(false);
                
        setSingleTableData({
            ...tableSingleData,
            id: "",
            tableName: "",
            changeSize: 0,
            tableBooked: false,
            tableLocked: false,
            offlineTable: false,
        });
        setTransferOrMergeTableNo('');
        setSingleEmployee({
            ...singleEmployeeData,
            id: "",
            name: "",
        });
        setShowDialog(false);
    };

    return (
        <React.Fragment>
            <div>
              <Snackbar
                open={utils.snackBarOpen}
                autoHideDuration={3000}
                message={utils.snackBarMsg}
                onClose={() => handleSnackClose()}
              />
            </div>
            <Grid container spacing={3}>
                {/* <Grid item xs={12}>
        </Grid> */}
                <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ padding: 10 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ float: "right" }}
                            onClick={(event) => {
                                if (isRoleAllowed(TABLEOWNERRIGHTS) || isRoleAllowed(ADMINRIGHTS)) {
                                    setShowDialog(true);
                                } else {
                                    showDialogBoxWithPaper("You Are not allowed to Create Table.Contact the Owner");
                                }
                            }}
                        >
                            Add New Table
                        </Button>
                    </div>

                    <CustomDataGrid
                        rows={tableData}
                        columns={columns}
                        loading={!isDataLoaded}
                    />
                </Grid>
            </Grid>
            <DialogBox
                showDialog={showDialog}
                title={"Add New Table"}
                onClose={handleDialogClose}
                dialogContent={renderDialogContent()}
                dialogActions={renderDialogAction()}
            ></DialogBox>
            {videoModel.show && <YouTubeVideo videoId={videoModel.path} onDismiss={(e) => setVideoModel({...videoModel,show:false})}/> }
            {videoModel.path && <SingleFab onClick={(e)=> setVideoModel({...videoModel,show:true})}/> }
        </React.Fragment>
    );
}
