export const initialState = {
    cartType: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_CART_TYPE" : {
            return {
                ...state, 
                cartType : action.cartType
            }
        }
        default : return state;
    }
}

export default reducer;