import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";

export default function CustomFilterInput(props) {

  const {
    items = [],
    mFilt={},
    onSubmit = () => { },
    onClear = () => {},
  } = props;

  

  const [keys, setKey] = useState(items[0]);
  const [filt, setFilt] = useState(mFilt);

  const handleChange = (e) => {
    //console.log("handleChange", e.target.value)
    setFilt({
      ...filt, value: e.target.value,
    })
  };

  const handleSubmit = (e) => {
    //console.log("handleSubmit", filt)
    if (onSubmit)
      onSubmit(filt)
  }

  const handleClear = (e) => {
    console.log("handleClear")
    setKey(items[0]);
    setFilt(mFilt);
    if (onClear)
      onClear()
  }

  return (
    <React.Fragment>
      <Grid container spacing={1} sx={{
        padding: 1, margin: 0,
      }}>
        <Grid item xs={6} md={6} sm={6}>
          <Autocomplete
            id="search-select"
            options={items}
            autoHighlight
            onChange={(e, opt) => {
              //console.log("CustomFilterInput",e.target.value,opt)
              setKey(opt);
              setFilt({
                ...filt, key: opt.key,
              });
            }}
            isOptionEqualToValue={(option, value) => option.key === value.key}
            getOptionLabel={(option) => option.value}
            defaultValue={keys}
            value={keys}
            variant="standard"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Columns"
                variant="standard"
                size="small"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} md={6} sm={6}>
          <TextField
            id="filtVal"
            label={`Filter ${keys.value}`}
            name="filtVal"
            variant="standard"
            size="small"
            defaultValue={filt.value}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} md={6} sm={6}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Apply Filter
          </Button>
        </Grid>
        <Grid item xs={6} md={6} sm={6}>
          <Button variant="contained" color="primary" onClick={handleClear}>
            Clear Filter
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
