export default function validateInfo(values) {
  let errors = {};

  if (!values.useremail) {
    errors.useremail = "UserEmail required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.useremail)
  ) {
    errors.useremail = "Email address is invalid";
  }

  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 4) {
    errors.password = "Password needs to be 4 characters or more";
  }

  return errors;
}
