import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import SearchIcon from '@mui/icons-material/Search';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { alpha, Badge, Fab, InputBase, styled } from '@mui/material';
import NameCircle from '../global/NameCircle';
import SnpProgress from '../global/loader/SnpPrgress';

export default function EcartNavbar({shopName, totalQuantity = 0, custName, shopIconClick , custIconClick , searchLoader, handleSearch}) {

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('sm')]: {
        width: '20ch',
        '&:focus': {
          width: '28ch',
        },
      },
    },
  }));

  const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  });


  return (
    <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ fontFamily:"monospace", flexGrow: 1 }}>
            {shopName}
          </Typography>
          <Search>
            <SearchIconWrapper>
              {searchLoader ?  <SnpProgress mColor={"error"} mSize={30}/> : <SearchIcon /> }
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search Product(Min. 3 letter)"
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Search>
          {
            totalQuantity > 0 && 
            <StyledFab color="secondary" aria-label="add" onClick={shopIconClick} sx={{
              position: 'absolute',
              left: '42%',
              transform: 'translateX(-50%)',
              top: { xs: 'none', md: '30px' },
              display: { xs: 'none', md: 'block' }, 
            }}>
              <Badge badgeContent={totalQuantity} color="error">
                <ShoppingCartIcon   />
              </Badge>
            </StyledFab>
          }
          <IconButton color="inherit" onClick={custIconClick}>
            <NameCircle name={custName} size={50} defaultIcon={<AccountCircle />} />
          </IconButton>
        </Toolbar>
      </AppBar>
  );
}
