import React, { useState } from 'react';
import { jsPDF } from "jspdf";


export default function PrintBill() {

  const [pdfContent, setPdfContent] = useState(null);

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text("Hello, this is a PDF generated with jsPDF!", 10, 10);

    // Generate the data URL for the PDF content
    const pdfDataUri = doc.output('datauristring');
    
    // Set the PDF content to the state
    setPdfContent(pdfDataUri);
  };

  return (
    <div>
      <h1>PDF Viewer</h1>
      <button onClick={generatePDF}>Generate PDF</button>
      {pdfContent && (
        <iframe
          title="PDF Viewer"
          width="100%"
          height="500"
          src={pdfContent}
        ></iframe>
      )}
    </div>
  );
}