import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Badge, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NameCircle from '../global/NameCircle';
import { getRandomColor } from '../../constants/constant';
import { isEmpty } from 'lodash';
const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: '0 auto',
});

export default function BottomAppBar({ shopName, totalQuantity = 0, custName ,shopIconClick, custIconClick }) {
  return (
    <>
    <Box sx={{width: {
    xs: "767px", // For extra-small screens (xs)
    sm: "1280px", // For small and larger screens (sm and above)
  }}}>
      <AppBar position="fixed" color="primary" sx={{
        top: 'auto', bottom: 0, display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <Toolbar sx={{
          width: '100%',
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          padding: { xs: '0 8px', sm: '0 8px' }, // Adjust padding based on screen size
        }}>
          {/* <IconButton color="inherit" aria-label="open drawer" sx={{ display: { xs: 'block', md: 'none' } }}>
            <MenuIcon />
          </IconButton> */}
          <Typography variant="p"  sx={{ fontFamily: "monospace",  fontSize:(!isEmpty(shopName) ? shopName.length > 20 ? "14px" : "17px" :"0px") , 
            fontWeight:(!isEmpty(shopName) ? shopName.length > 20 ? "bold" : "normal" :"normal") , maxWidth:"312px" }}>
            {shopName}
          </Typography>
          {totalQuantity > 0 &&
            <StyledFab color="secondary" onClick={shopIconClick} aria-label="add" sx={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              bottom: { xs: '12px', md: 'none' },
              display: { xs: 'block', md: 'none' }, // Show only on mobile
            }}>
              <Badge badgeContent={totalQuantity} color="error">
                <ShoppingCartIcon />
              </Badge>
            </StyledFab>
          }
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <IconButton color="inherit" onClick={custIconClick}>
              <NameCircle name={custName} size={50} defaultIcon={<AccountCircleIcon />} />
            </IconButton>
            {/* <IconButton color="inherit">
            <LogoutIcon />
          </IconButton> */}
          </Box>
        </Toolbar>
      </AppBar></Box>
    </>
  );
}
