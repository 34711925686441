import React, { useLayoutEffect, useState } from "react";
import "../resturant/RestoProductStock.css";
import {
  SUCCESS,
  CART_INITIAL_STATE,
  printDebugLog,
  GETRESTOSTOCKENTRY,
  COMPONENT_PARAMS,
  DashboardComponent,
} from "../../constants/constant";
import {
  Button,
} from "@mui/material";

import CustomDataGrid from "../global/CustomDataGrid";
import { useSelector } from "react-redux";
import SnpComponent from "../global/SnpComponent";

import { snpAxios } from '../global/api';

export default function RestoStockInformation({setComponentIndex}) {
  
  const storeApiUrl = useSelector(
    (state) => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL
  );
  
  const storeActiveShopHash = useSelector(
    (state) => state.setActiveShopHashReducer.activeShopHash
  );
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [listStockData, setListStockData] = useState([]);
  
  const columns = [
    { field: "typeStr", headerName: "Stock Type", width: 150 },
    { field: "stockName", headerName: "Item Name.", width: 200 },
    { field: "billNo", headerName: "Bill No.", width: 180 },
    { field: "quanStr", headerName: "Quantity", width: 180,
          renderCell: (cellValues) => {
              let resp = cellValues.row.quantity + " " + cellValues.row.unitName;
              if (!cellValues.row.unitRestrictions)
                  resp = resp + "/" + cellValues.row.smallQuantity + " " + cellValues.row.smallUnitName;
              return resp;
          }
      },
    { field: "mfgDate", headerName: "Mfg. Date", width: 120 },
    { field: "expiryDate", headerName: "Expiry Date", width: 120 },
    { field: "purchasePrice", headerName: "Purchase Price", width: 150 },
    { field: "createdAt", headerName: "At", width: 180 },
    { field: "description", headerName: "Description", width: 250 },
  ];


  const getStockData = async () => {
    let cartUrl = storeApiUrl.BACKEND_BASE_URL +  GETRESTOSTOCKENTRY;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        stockId:COMPONENT_PARAMS.prdId,
      }
    };
    //  console.log("getSupplierDetails  => ",data);
    setDataLoaded(false);
    snpAxios.post(
      cartUrl,
      data
    )
      .then((response) => {
        printDebugLog("response  => ", response.data);
        setDataLoaded(true);
        if (response.data.status === SUCCESS) {
          setListStockData(response.data.jcJson.value_2);
        }
      }).catch((error) => {
        setDataLoaded(true);
        console.log("errr ", error);
        //window.location.href = "#/error";
      });
  };

  useLayoutEffect(() => {
    getStockData();
  }, [storeActiveShopHash, storeApiUrl]);


    const handleGoBack = () => {
        COMPONENT_PARAMS.prdId = "";
        setComponentIndex(DashboardComponent.PRODUCTENTRY);
    }
  
    const renderSnpContent = () => {
        return (
            <>
                <Button variant="contained" color="primary" onClick={handleGoBack}>
                    Go Back
                </Button>
                <CustomDataGrid
                    rows={listStockData}
                    columns={columns}
                    loading={!isDataLoaded}
                />
            </>
        )
    };


  return (
    <>
      <SnpComponent
        content={renderSnpContent()}
      />
    </>
  );

}
