import React, { useState ,useEffect, useRef} from "react";
import './BillingModule.css';
import { Grid, TextField, Box, MenuItem, Select, InputLabel, Checkbox } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
//import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SearchIcon from '@mui/icons-material/Search';
import Loader from "../global/loader/Loader";
import Snackbar from '@mui/material/Snackbar';
//import MuiAlert from '@mui/material/Alert';
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from '@mui/material/CircularProgress';
//Need to refactor this usage
import { withStyles } from "@mui/styles";
import CustomDataGrid from "../global/CustomDataGrid";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import Alert from '@mui/material/Alert';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider, DesktopDatePicker } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';

import Typography from "@mui/material/Typography";
import {
  SUCCESS,
  NODATA,
  getPreUrlAccordingToCart,
  GET_PRODUCT_DATA, PRINT_BILL,
  GET_STOCK_DATA_BILLING,
  OfferType,
  CART_INITIAL_STATE,
  SearchOption,
  GET_CUSTOMER_DETAIS,
  POST_BILL,
  CASHPAY,
  ONLINEPAY,
  BillStatus,
  CartDets,
  isEmpty, isNumberEmpty, FETCH_BILL, CHECKQUAN, FAILED, COMPONENT_PARAMS, isRoleAllowed, ADMINRIGHTS, BillItem, GETBILLBYREGEX,
  DashboardComponent,
} from "../../constants/constant";
import axios from "axios";
import DialogBox from "../global/DialogBox";
import {
  Button, FormControl, RadioGroup, FormControlLabel,
  FormLabel, Radio
} from "@mui/material";
import { useSelector } from "react-redux";

export default function BillingModule({ billingprops,setComponentIndex }) {
  const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
  const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
  const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
  const [productData, setProductData] = useState([]);
  const [utils, setUtils] = useState({
    snackBarOpen: false,
    snackBarMsg: "",
    wantLoader: false,
    showDialog: false,
    isEditProduct: false,
    isDataLoaded: false,
    showLoading: false,
    showMainLoader: false,
  });
  let searchValue = "";
  const [productSingleData, setSingleProductData] = useState({
    prdName: "",
    prdId: "",
    productImage: "",
    brandName: "",
    composition: "",
  });
  const [selectOption, setSelectOption] = useState({
    id: SearchOption.SEARCHBYBARC, optName: "Search By Bar Code",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [custFormSubmitted, setCustFormSubmitted] = useState(false);
  const [addNewItem, setAddNewItem] = useState(false);


  const [stockQuantity, setStockQuantity] = useState({
    addedBigQuan: 0,
    addedSmallQuan: 0,
  });

  const [billDetails, setBillDetails] = useState({
    subTotal: 0.0,
    pendingAmt: 0.0,
    partialAmt: 0.0,
    roundOff: 0.0,
    cashTender: 0.0,
    cashReturn: 0.0,
    pointRedeem: 0.0,
    isPointAdded: false,
    total: 0.0,
  });
  const [custPrint, setCustPrint] = useState('');

  const [stockSingleData, setStockSingleData] = useState({
    prdName: "",
    prdId: "",
    productImage: [],
    brandName: "",
    composition: "",
    catgName: "",
    stockRefNo: "",
    productmrPrice: 0,
    offerDenom: 1,
    offerAmt: 0,
    productSellingPrice: 0,
    productWholeSalePrice: 0,
    sproductmrPrice: 0,
    sofferDenom: 1,
    sofferAmt: 0,
    sproductSellingPrice: 0,
    sproductWholeSalePrice: 0,
    smallTotalCount: 0,
    bigTotalCount: 0,
    customBigTotalCount: 0,
    customSmallTotalCount: 0,
    unitName: "",
    bigUnitName: "",
    unitRestrictions:false,
    priorityHighinSell: false,
    sgst: 0,
    cgst: 0,
    igst: 0,
    localGst: 0,
    sbcCessTax: 0,
    rack_no:"",
  });

  const INITIAL_BILSTOCK_DATA = {
    stockRefNos: [],
    prdId: "",
    prdName: "",
    brandName: "",
    productImage: [],
    sgst: 0,
    cgst: 0,
    igst: 0,
    productmrPrice: 0.0,
    sproductmrPrice: 0.0,
    price: 0.0,
    note: "",
    quantity: 0,
    sQuantity: 0,
    sbcCessTax: 0,
    unitName: "",
    smallUnitName: "",
    offerDenom: 1,
    offerAmt: 0.0,
    unitChecked:false,
    smallUnitChecked:false,
    customSmallTotalCount:0,
  };

  const [billStockData, setBillStockData] = useState(INITIAL_BILSTOCK_DATA);

  const INITIAL_LISTBILLING_DATA = {
    billNo: "",
    value_2: [],
    custId: "",
    custMob: "",
    custName: "",
    custEmail: "",
    onlinePayThrough: "",
    onlinePayMedium: CART_INITIAL_STATE.onlinePayMethod[0],
    cashPayThrough: "",
    onlinePay: 0.0,
    cashPay: 0.0,
    billStatus: BillStatus.PAID,
    pointRedeem: 0,
    roundOff: 0.0,
    cashTender: 0.0,
    cashReturn: 0.0,
    createdAt: new Date().toDateString(),
  };

  const [listbillingData, setBillingData] = useState(INITIAL_LISTBILLING_DATA);

  const [isOnlinePayEnabled, setOnlinePayEnb] = useState(false);
  const [isCashPayEnabled, setCashPayEnb] = useState(false);
  const [isCustLoading, setIsCustLoading] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [isCustDataAvailable, setIsCustDataAvailable] = useState(false);

  const optionList = [
    { id: SearchOption.SEARCHBYBARC, optName: "Search By Bar Code" },
    { id: SearchOption.SEARCHBYBATCHN, optName: "Search By Batch Number" },
    { id: SearchOption.SEARCHBYPRODN, optName: "Search By Product Name" }
  ];

  const ESCAPE_KEYS = ["27", "Escape"];
  const useEventListener = (eventName, handler, element = window) => {
    const savedHandler = useRef();

    useEffect(() => {
      savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
      const eventListener = (event) => savedHandler.current(event);
      element.addEventListener(eventName, eventListener);
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    }, [eventName, element]);
  };
  const handlerKeyDown = ({ key }) => {
    //console.log("handler => ", key, String(key));
    if (!utils.showDialog && ESCAPE_KEYS.includes(String(key))) {
      console.log("handler true=> ", key, String(key));
      setUtils({
        ...utils,
        showDialog: true,
      });
      setSelectOption({
        ...selectOption,
        id: SearchOption.SEARCHBYPRODN, optName: "Search By Product Name",
      });
    } else if (utils.showDialog && ESCAPE_KEYS.includes(String(key))) {
      console.log("handler false=> ", key, String(key));
      setUtils({
        ...utils,
        showDialog: false,
      });
      setSelectOption({
        ...selectOption,
        id: SearchOption.SEARCHBYBARC, optName: "Search By Bar Code",
      });
    }
  };

  useEventListener("keydown", handlerKeyDown);

  const [alert, setAlert] = useState(false);

  const columns = [
    {
      field: 'prddets', headerName: 'Item', width: 150,
      renderCell: (val) => {
        return val.row.prdName + " " + val.row.brandName;
      }
    },
    {
      field: 'quantity', headerName: 'Qty', width: 120,
      renderCell: (val) => {
        let data = "";
        if (val.row.unitChecked)
          data = val.row.quantity + " " + val.row.unitName;
        return data;
      }
    },
    {
      field: 'productmrPrice', headerName: 'MRP', width: 140,
      renderCell: (val) => {
        return val.row.unitChecked ? ("\u20B9 " + val.row.productmrPrice + "/-" + val.row.unitName): "";
      }

    },
    {
      field: 'squantity', headerName: 's.Qty', width: 120,
      renderCell: (val) => {
        let data = "";
        if (val.row.smallUnitChecked) 
          data = val.row.sQuantity + " " + val.row.smallUnitName;
        return data;
      }
    },
    {
      field: 'sproductmrPrice', headerName: 's.MRP', width: 140,
      renderCell: (val) => {
        return val.row.smallUnitChecked ? ("\u20B9 " + val.row.sproductmrPrice + "/-" + val.row.unitName) : "";
      }

    },
    {
      field: 'cgst', headerName: 'CGST(%)', width: 140
    },
    {
      field: 'sgst', headerName: 'SGST(%)', width: 140
    },
    {
      field: 'offer', headerName: 'Offer', width: 140,
      renderCell: (val) => {
        return (parseInt(val.row.offerDenom) === OfferType.No_Offer) ?
          0.0 : (parseInt(val.row.offerDenom) === OfferType.Percentage) ? val.row.offerAmt + "%" : "Flat" + val.row.offerAmt;
      }
    },
    {
      field: 'price', headerName: 'F.Price', width: 120,
      renderCell: (val) => {
        return "\u20B9 " + val.row.price;
      }
    },
    {
      field: "select",
      headerName: "Edit",
      width: 110,
      renderCell: (cellValues) => {
        return (
          <EditIcon style={{ cursor: "pointer" }}
            onClick={(event) => {
              if (!isPaid) {
                handleCellClick(event, cellValues);
              }

            }}
          >
          </EditIcon>
        );
      }
    },
    {
      field: "del",
      headerName: "Del",
      width: 110,
      renderCell: (cellValues) => {
        return (
          <DeleteIcon style={{ cursor: "pointer" }}
            onClick={(event) => {
              handleCellClick(event, cellValues);
            }}
          >
          </DeleteIcon>
        );
      }
    }
  ];

  const handleDialogClose = () => {
    // console.log("handleDialogClose");
    setAddNewItem(false);
    setDataAlert(false);
    setUtils({
      ...utils,
      snackBarOpen: false,
      snackBarMsg: "",
      wantLoader: false,
      showDialog: false,
      isEditProduct: false,
      isDataLoaded: false,
      showLoading: false,
      showMainLoader: false,
    });
    searchValue = "";
    setSelectOption({
      ...selectOption,
      id: SearchOption.SEARCHBYBARC, optName: "Search By Bar Code",
    });
    setSingleProductData({
      ...productSingleData,
      prdName: "",
      prdId: "",
      productImage: [],
      brandName: "",
      composition: "",
    });
    setChoiceUnit({
      ...choiceUnit,
      unitChecked : false,
      smallUnitChecked:false,
    });
    setBillStockData({
      ...billStockData,
      stockRefNos: [],
      prdId: "",
      price: 0.0,
      note: "",
      quantity: 0,
      sQuantity: 0,
      offerDenom: 1,
      offerAmt: 0.0,
      unitChecked:false,
      smallUnitChecked:false,
    });
  };
  const handleSelectChange = (event, opt) => {
    if (opt != null) {
      // console.log("handleSelectChange", opt);
      setSelectOption({ ...selectOption, id: opt.id, optName: opt.optName })
      if (opt.id === SearchOption.SEARCHBYPRODN) {
        if (productData.length <= 0) {
          setUtils({
            ...utils,
            wantLoader: true,
          });
          getProductDetails();
        }
      }
    }
  };

  React.useEffect(() => {
    
    if (COMPONENT_PARAMS.prdId === '') {
    //console.log("UseEffect");
      getProductDetails();
    }  else {
      setUtils({
        ...utils,
        wantLoader: true,
      });
      searchValue = COMPONENT_PARAMS.prdId;
      //console.log("searchValue ",searchValue);
      handleSearch();
    }
  }, [storeCartType,storeActiveShopHash,storeApiUrl]);

  const handleProductSelectChange = (event, opt) => {
    if (opt != null) {
      //  console.log("handleSelectChange", opt);
      setSingleProductData({
        ...productSingleData,
        prdId: opt.prdId,
        prdName: opt.prdName,
        productImage: opt.productImage
      });
      setUtils({
        ...utils,
        wantLoader: true,
      });
      searchValue = opt.prdId;
      handleSearch();
    }
    //console.log("handleSelectChange-> ",productSingleData);
  };




  const updateStockPrice = (m_OfferDenom, m_OfferAmt, m_Quantity,m_Squantity,mUnitChecked,mSmallUnitChecked) => {
    //console.log("updateStockPrice-> ",m_OfferDenom,m_OfferAmt,m_Quantity,m_Squantity,mUnitChecked,mSmallUnitChecked);
    let bPrice = mUnitChecked ? stockSingleData.productSellingPrice : 0;
    let sPrice = mSmallUnitChecked ? stockSingleData.sproductSellingPrice : 0;
    //console.log("updateStockPrice 0-> ",sPrice,bPrice);
    if (parseInt(m_OfferDenom) === OfferType.No_Offer) {
      bPrice = mUnitChecked ? stockSingleData.productSellingPrice : 0;
      sPrice = mSmallUnitChecked ? stockSingleData.sproductSellingPrice : 0;
    } else if (parseInt(m_OfferDenom) === OfferType.Flat && m_OfferAmt > 0) {
      bPrice = mUnitChecked ? (bPrice - m_OfferAmt) : 0;
      sPrice = mSmallUnitChecked ? (sPrice - m_OfferAmt) : 0;
    } else if (parseInt(m_OfferDenom) === OfferType.Percentage && m_OfferAmt > 0) {
      if (mSmallUnitChecked)
        sPrice = sPrice - ((sPrice * m_OfferAmt) / 100);
      if (mUnitChecked)
        bPrice = bPrice - ((bPrice * m_OfferAmt) / 100);
    }
    
    let gst = stockSingleData.localGst;
    //console.log("updateStockPrice 1-> ",sPrice,bPrice,gst,stockSingleData.sbcCessTax);
    if (stockSingleData.sbcCessTax > 0.0)
      gst = gst + stockSingleData.sbcCessTax;
    if (mSmallUnitChecked)
      sPrice = sPrice + ((gst * sPrice) / 100);
    if (mUnitChecked)  
      bPrice = bPrice + ((gst * bPrice) / 100);
    
      if (mSmallUnitChecked)
        sPrice = sPrice * m_Squantity;
      if (mUnitChecked)  
        bPrice = bPrice * m_Quantity;

    let finalPrice = sPrice + bPrice;
    //console.log("updateStockPrice 2-> ",finalPrice,sPrice,bPrice);
    setBillStockData({
      ...billStockData,
      offerDenom: m_OfferDenom,
      offerAmt: m_OfferAmt,
      price: finalPrice,
      quantity: m_Quantity,
      sQuantity: m_Squantity,
      unitName:mUnitChecked ? stockSingleData.bigUnitName : "",
      smallUnitName:mSmallUnitChecked ? stockSingleData.unitName : "",
      unitChecked:mUnitChecked,
      smallUnitChecked:mSmallUnitChecked,
      customSmallTotalCount: stockSingleData.customSmallTotalCount,
    });
    setStockQuantity({
      ...stockQuantity,
      addedBigQuan:m_Quantity,
      addedSmallQuan:m_Squantity,
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    //console.log("handleInputChange", name, value);
    if (name === 'unitChecked') {
      let previousCheckedValue = choiceUnit.unitChecked;
      //console.log("handleInputChange unitChecked", previousCheckedValue);
      setChoiceUnit({
         ...choiceUnit,
         unitChecked:!previousCheckedValue,
      });
    } else if (name === 'smallUnitChecked') {
      let previousCheckedValue = choiceUnit.smallUnitChecked;
      setChoiceUnit({
         ...choiceUnit,
         smallUnitChecked:!previousCheckedValue,
      });
    }else if (name === 'search-barccode' || name === 'search-batchno') {
      searchValue = value;
    } else if (name === 'sBillNo') {
      searchValue = value;
    } else if (name === 'offerDenom') {
      updateStockPrice(value, billStockData.offerAmt, billStockData.quantity,billStockData.sQuantity,choiceUnit.unitChecked,choiceUnit.smallUnitChecked);
    } else if (name === 'offerAmt') {
      updateStockPrice(billStockData.offerDenom, value, billStockData.quantity,billStockData.sQuantity,choiceUnit.unitChecked,choiceUnit.smallUnitChecked);
    } else if (name === 'onlinePay') {
      let val = !isOnlinePayEnabled;
      setBillingData({
        ...listbillingData,
        onlinePayThrough: val ? value : "",
        onlinePay: isCashPayEnabled ? (billDetails.total > 0) ? (parseFloat(billDetails.total) / 2) : 0.0 : billDetails.total,
        cashPay: val ? (billDetails.total > 0) ? (parseFloat(billDetails.total) / 2) : 0.0 : billDetails.total,
      });
      setOnlinePayEnb(val);
    } else if (name === 'onlinePayAmt') {
      if (parseFloat(value) > billDetails.total) {
        setUtils({
          ...utils,
          snackBarOpen: true,
          snackBarMsg: "Exceeds Total Amount i.e " + billDetails.total,
        });
      } else {
        setBillingData({
          ...listbillingData,
          onlinePay: parseFloat(value),
          cashPay: isCashPayEnabled ? (billDetails.total - parseFloat(value)) : 0.0,
        });
      }
    } else if (name === 'cashPay') {
      let val = !isCashPayEnabled;
      setBillingData({
        ...listbillingData,
        cashPayThrough: val ? value : "",
        cashPay: isOnlinePayEnabled ? (billDetails.total > 0) ? (parseFloat(billDetails.total) / 2) : 0.0 : billDetails.total,
        onlinePay: val ? (billDetails.total > 0) ? (parseFloat(billDetails.total) / 2) : 0.0 : billDetails.total,
      });
      setCashPayEnb(val);
    } else if (name === 'cashPayAmt') {
      if (parseFloat(value) > billDetails.total) {
        setUtils({
          ...utils,
          snackBarOpen: true,
          snackBarMsg: "Exceeds Total Amount i.e " + billDetails.total,
        });
      } else {
        setBillingData({
          ...listbillingData,
          cashPay: parseFloat(value),
          onlinePay: isOnlinePayEnabled ? (billDetails.total - parseFloat(value)) : 0.0,
        });
      }
    } else if (name === 'quantity') {
      let quan = stockSingleData.unitRestrictions ? stockSingleData.bigTotalCount : stockSingleData.smallTotalCount;
      let checkQuan = (stockSingleData.unitRestrictions ? parseInt(value) : (parseInt(value) * stockSingleData.customSmallTotalCount)) + billStockData.sQuantity;
      //console.log("handleInputChange unitChecked",value, checkQuan,quan);
      if (checkQuan > quan) {
        setUtils({
          ...utils,
          snackBarOpen: true,
          snackBarMsg: "Exceeds Total Count i.e " + stockSingleData.bigTotalCount + " " + stockSingleData.bigUnitName,
        });
      } else {
        updateStockPrice(billStockData.offerDenom, billStockData.offerAmt, parseInt(value),billStockData.sQuantity,choiceUnit.unitChecked,choiceUnit.smallUnitChecked);
      }
    } else if (name === 'sQuantity') {
      let checkQuan = (billStockData.quantity * stockSingleData.customSmallTotalCount) + parseInt(value) ;
      //console.log("handleInputChange smallUnitChecked",value, checkQuan);
      if (checkQuan > stockSingleData.smallTotalCount) {
        setUtils({
          ...utils,
          snackBarOpen: true,
          snackBarMsg: "Exceeds Total Count i.e " + stockSingleData.smallTotalCount + " " + stockSingleData.unitName,
        });
      } else {
        updateStockPrice(billStockData.offerDenom, billStockData.offerAmt, billStockData.quantity,parseInt(value),choiceUnit.unitChecked,choiceUnit.smallUnitChecked);
      }
    } else if (name === 'partialAmt') {
      //console.log(value, billDetails.total);
      if (parseFloat(value) > parseFloat(billDetails.total)) {
        setUtils({
          ...utils,
          snackBarOpen: true,
          snackBarMsg: "Exceeds Total Amt i.e " + billDetails.total,
        });
        setBillDetails({
          ...billDetails,
          pendingAmt: 0, partialAmt: 0,
        });
      } else {
        var dues = parseFloat(billDetails.total) - parseFloat(value);
        setBillDetails({
          ...billDetails,
          pendingAmt: dues.toFixed(2), partialAmt: value,
        });
      }
    } else if (name === 'cashTender') {
      if (billDetails.total > 0.0) {
        if (billDetails.partialAmt > 0.0) {
          let rt = parseFloat(value) - billDetails.partialAmt;
          if (rt >= 0.0) {
            setBillDetails({
              ...billDetails,
              cashTender: parseFloat(value),
              cashReturn: rt,
            });
          } else {
            setBillDetails({
              ...billDetails,
              cashTender: value,
              cashReturn: 0.0,
            });
          }
        } else {
          let rt = parseFloat(value) - billDetails.total;
          if (rt >= 0.0) {
            setBillDetails({
              ...billDetails,
              cashTender: parseFloat(value),
              cashReturn: rt,
            });
          } else {
            setBillDetails({
              ...billDetails,
              cashTender: value,
              cashReturn: 0.0,
            });
          }
        }
      } else {
        setUtils({
          ...utils,
          snackBarOpen: true,
          snackBarMsg: "Please add Item to start Bill process",
        });
        setBillDetails({
          ...billDetails,
          cashTender: 0.0,
          cashReturn: 0.0,
        });
      }
    } else {
      setBillStockData({
        ...billStockData,
        [name]: value,
      });
    }
  };

  const handleSearch = (event) => {
    //console.log("handleSearch", searchValue);
    setUtils({
      ...utils,
      isDataLoaded: false,
    });
    getStockSearchDetails();
  };

  const handleCellClick = (btn, event) => {
    if (event.field === 'select') {
      //console.log("handleCellClick", event.row);
      setBillStockData({
        ...billStockData,
        // stockRefNos: [],
        prdId: event.row.prdId,
        price: event.row.price,
        productImage: event.row.productImage,
        note: event.row.note,
        quantity: event.row.quantity,
        offerDenom: event.row.offerDenom,
        offerAmt: event.row.offerAmt
      });
      setChoiceUnit({
        ...choiceUnit,
        unitChecked:event.row.unitChecked,
        smallUnitChecked:event.row.smallUnitChecked,
      })
      setUtils({ ...utils, showDialog: true, isEditProduct: true, isDataLoaded: true });
    } else if (event.field === 'del') {
      //console.log("handleCellClick", event.row);
      if (isPaid) {
        setUtils({
          ...utils,
          snackBarMsg: "Paid Item Can't be Deleted",
          snackBarOpen: true,
        });
      } else {
        var array = [...listbillingData.value_2];
        var index = array.findIndex(singleValue => singleValue.prdId === event.row.prdId);
        if (index !== -1) {
          let prdId = array[index].prdId;
          let addedBigQuan = parseFloat(billStockData.quantity);
          let addedSmallQuan = parseFloat(billStockData.sQuantity);
          //console.log("handleCellClick b", index, array);
          let oldPrice = parseFloat(array[index].price);
          array.splice(index, 1);
          //console.log("handleCellClick ", listbillingData.value_2);
          doTotal(oldPrice, 0.0, 0.0);
          setBillingData({
            ...listbillingData,
            value_2: array,
          });
          checkQuantity(BillItem.Delete, prdId,addedBigQuan,addedSmallQuan);
        }
      }
    }
  };

  const handleReserveQuantity = () => {
    console.log("handleCheckQuan=> listbillingData",listbillingData);
    console.log("handleCheckQuan=> billStockData",billStockData);
    let process = BillItem.New;
    var index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === billStockData.prdId);
    if (index !== -1) 
      process = BillItem.Update;
    let addedBigQuan = parseFloat(billStockData.quantity);
    let addedSmallQuan = parseFloat(billStockData.sQuantity);
    checkQuantity(process, billStockData.prdId,addedBigQuan,addedSmallQuan);
  };


  const checkQuantity = async (mProcess,prdId, maddedBigQuan,maddedSmallQuan) => {
    
    console.log("checkQuantity",mProcess,prdId,maddedBigQuan,maddedSmallQuan);
    setUtils({
      ...utils,
      isDataLoaded: false,
    });
    let billUrl = storeApiUrl.BACKEND_BASE_URL + CHECKQUAN;
    let data = {
      jcJson: {
        value: CART_INITIAL_STATE.apiToken,
        value_1: prdId,
        shopHash:storeActiveShopHash.id,
        addedBigQuan: maddedBigQuan,
        addedSmallQuan: maddedSmallQuan,
        value_2: listbillingData.billNo,
        process:mProcess,
      }
    };

    axios.post(
      billUrl,
      data
    )
      .then((response) => {
        console.log("checkQuantity response  => ", response.data);
        setUtils({
          ...utils,
          isDataLoaded: true,
        });
        if (response.data.status === SUCCESS) {
          setStockQuantity({
            ...stockQuantity,
            addedBigQuan: response.data.jcJson.addedBigQuan,
            addedSmallQuan: response.data.jcJson.addedSmallQuan,
          });
          console.log("checkQuantity mProcess  => ", mProcess);
          if (mProcess !== BillItem.Delete) {
            console.log("checkQuantity call for handleAddItem");
            handleAddItem();
          }
        } else if (response.data.status === FAILED) {
          setStockQuantity({
            ...stockQuantity,
            addedBigQuan: response.data.jcJson.addedBigQuan,
            addedSmallQuan: response.data.jcJson.addedSmallQuan,
          });
           setUtils({
              ...utils,
              snackBarOpen: true,
              snackBarMsg: "Sorry! Exceeds Total Quantity",
            });
        }
      }).catch((error) => {
        setUtils({
          ...utils,
          isDataLoaded: true,
        });
        console.log("errr ", error);
        //window.location.href = "#/error";
      });
  }

  const handleAddItem = () => {
    setAddNewItem(true);
    if (!isItemValid()) {
      return;
    }
    console.log("billStockData : ", billStockData);
    let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === billStockData.prdId);
    console.log("handleAddItem:  ", index, listbillingData.value_2);
    let oldPrice = 0.0;
    if (parseInt(index) === -1) {
      setBillingData(prvsState => ({
        ...listbillingData,
        value_2: [...prvsState.value_2, { ...billStockData, price: parseFloat(billStockData.price.toFixed(2)) }]
      }));
    } else {
      console.log("prvs dets => ",listbillingData.value_2[index]);
      oldPrice = parseFloat(listbillingData.value_2[index].price);
      listbillingData.value_2[index].quantity = parseFloat(billStockData.quantity);
      listbillingData.value_2[index].sQuantity = parseFloat(billStockData.sQuantity);
      listbillingData.value_2[index].unitName = billStockData.unitName;
      listbillingData.value_2[index].smallUnitName = billStockData.smallUnitName;
      listbillingData.value_2[index].unitChecked = billStockData.unitChecked;
      listbillingData.value_2[index].smallUnitChecked = billStockData.smallUnitChecked;
      listbillingData.value_2[index].offerDenom = parseInt(billStockData.offerDenom);
      listbillingData.value_2[index].offerAmt = billStockData.offerAmt;
      listbillingData.value_2[index].price = parseFloat(billStockData.price.toFixed(2));
      listbillingData.value_2[index].note = billStockData.note;

      setBillingData({
        ...listbillingData,
        value_2: listbillingData.value_2,
      });
    }
    //console.log("oldPrice=>",oldPrice);
    handleDialogClose();
    doTotal(oldPrice, parseFloat(billStockData.price), parseFloat(listbillingData.pointRedeem));
  };

  const fetchBill = async (regextStr) => {
    let fetchBillUrl = storeApiUrl.BACKEND_BASE_URL + GETBILLBYREGEX+"?adminId="+CART_INITIAL_STATE.apiToken+"&shopHash="+storeActiveShopHash.id+"&regexStr="+regextStr;
    console.log("fetchBill=> ",fetchBillUrl);
    let data = { };
    // setUtils({
    //   ...utils,
    //   showMainLoader: true,
    // });
    axios.post(
      fetchBillUrl,
      data
    )
      .then((response) => {
        console.log("fetchBill   => ", response.data);
        // setUtils({
        //   ...utils,
        //   showMainLoader: false,
        // });
        if (response.data.status === SUCCESS) {
          setListAllBill(response.data.jcJson.listBills);
          if (COMPONENT_PARAMS.isSearchByShowBills) {
            COMPONENT_PARAMS.sBillNo = "";
            COMPONENT_PARAMS.isSearchByShowBills = false;
            handleSelectBill("", response.data.jcJson.listBills[0]);
          }
        }
      }).catch((error) => {
        // setUtils({
        //   ...utils,
        //   showMainLoader: false,
        // });
        console.log("errr ", error);
        //window.location.href = "#/error";
      });
  };

  const handleSelectedBillSearch = () => {
    if (COMPONENT_PARAMS.sBillNo !== '') {
      fetchBill(COMPONENT_PARAMS.sBillNo);
    }
  };
  const getProductDetails = async () => {
    let cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + GET_PRODUCT_DATA;
    let data = {
      jcJson: {
        Admin: CART_INITIAL_STATE.userEmail,
        value: CART_INITIAL_STATE.userPassWord,
        cartType: storeCartType,
      }
    };
    setUtils({
      ...utils,
      showMainLoader: true,
    });
    //console.log("getProductDetails  => ", data);

    axios.post(
      cartUrl,
      data
    )
      .then((response) => {
        //console.log("getProductDetails  => ", response.data.jcJson.value_2);
        setUtils({
          ...utils,
          showMainLoader: false,
        });
        if (response.data.status === SUCCESS) {
          setProductData(response.data.jcJson.value_2);
        }
        handleSelectedBillSearch();
      }).catch((error) => {
        console.log("errr ", error);
        setUtils({
          ...utils,
          showMainLoader: false,
        });
        handleSelectedBillSearch();
        //window.location.href = "#/error";
      });
  };

  const getStockSearchDetails = async () => {
    setUtils({
      ...utils,
      wantLoader: true,
    });
    let cartUrl = storeApiUrl.BACKEND_BASE_URL + GET_STOCK_DATA_BILLING;
    let data = {
      jcJson: {
        Admin: CART_INITIAL_STATE.userEmail,
        value: searchValue,
        searchId: (COMPONENT_PARAMS.prdId !== '' ? 3 : parseInt(selectOption.id)) ,
      }
    };
    //console.log("getStockSearchDetails  => ",data);
    axios.post(
      cartUrl,
      data
    )
      .then((response) => {
        if (response.data.status === NODATA) {
          throw new Error("Stock Not Available")
        }
        console.log("response  => ", response.data);
        if (COMPONENT_PARAMS.prdId !== '') {
          setUtils({
            ...utils,
            wantLoader: false,
            isDataLoaded: true,
            showDialog: true,
          });
        } else {
          setUtils({
            ...utils,
            wantLoader: false,
            isDataLoaded: true,
          });
        }
        
        COMPONENT_PARAMS.prdId = "";
        if (response.data.status === SUCCESS) {
          setStockSingleData({
            ...stockSingleData,
            prdName: response.data.jcJson.prdName,
            brandName: response.data.jcJson.brandName,
            prdId: response.data.jcJson.prdId,
            productImage: response.data.jcJson.productImage,
            stockRefNo: response.data.jcJson.stockRefNo,
            sgst: response.data.jcJson.sgst,
            cgst: response.data.jcJson.cgst,
            igst: response.data.jcJson.igst,
            localGst: response.data.jcJson.localGst,
            sbcCessTax: response.data.jcJson.sbcCessTax,
            productmrPrice: response.data.jcJson.productmrPrice,
            offerDenom: response.data.jcJson.offerDenom,
            offerAmt: response.data.jcJson.offerAmt,
            productSellingPrice: response.data.jcJson.productSellingPrice,
            productWholeSalePrice: response.data.jcJson.productWholeSalePrice,
            sproductmrPrice: response.data.jcJson.sproductmrPrice,
            sofferDenom: response.data.jcJson.sofferDenom,
            sofferAmt: response.data.jcJson.sofferAmt,
            sproductSellingPrice: response.data.jcJson.sproductSellingPrice,
            sproductWholeSalePrice: response.data.jcJson.sproductWholeSalePrice,
            catgName: response.data.jcJson.catgName,
            unitName: response.data.jcJson.unitName,
            bigUnitName: response.data.jcJson.bigUnitName,
            smallTotalCount: response.data.jcJson.smallTotalCount,
            bigTotalCount: response.data.jcJson.bigTotalCount,
            customSmallTotalCount:response.data.jcJson.customSmallTotalCount,
            customBigTotalCount:response.data.jcJson.customBigTotalCount,
            unitRestrictions:response.data.jcJson.unitRestrictions,
            rack_no:response.data.jcJson.rack_no,
          });

          let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === response.data.jcJson.prdId);

          // console.log("getStockSearchDetails:  ", index);
          if (parseInt(index) === -1) {
            setBillStockData({
              ...billStockData,
              prdId: response.data.jcJson.prdId,
              productmrPrice: response.data.jcJson.productmrPrice,
              sproductmrPrice: response.data.jcJson.sproductmrPrice,
              prdName: response.data.jcJson.prdName,
              brandName: response.data.jcJson.brandName,
              productImage: response.data.jcJson.productImage,
              sgst: response.data.jcJson.sgst,
              cgst: response.data.jcJson.cgst,
              igst: response.data.jcJson.igst,
              //sbcCessTax: response.data.jcJson.sbcCessTax,
              //offerDenom: response.data.jcJson.offerDenom,
              //offerAmt: response.data.jcJson.offerAmt,
              //unitName: (parseInt(response.data.jcJson.bigTotalCount) !== -1) ? response.data.jcJson.bigUnitName : response.data.jcJson.unitName,
            });
          } else {
            let prvsVal = listbillingData.value_2[index];
            setBillStockData({
              ...billStockData,
              prdId: response.data.jcJson.prdId,
              prdName: response.data.jcJson.prdName,
              brandName: response.data.jcJson.brandName,
              productImage: response.data.jcJson.productImage,
              sgst: response.data.jcJson.sgst,
              cgst: response.data.jcJson.cgst,
              igst: response.data.jcJson.igst,
              sbcCessTax: response.data.jcJson.sbcCessTax,
              productmrPrice: response.data.jcJson.productmrPrice,
              sproductmrPrice: response.data.jcJson.sproductmrPrice,
              offerDenom: prvsVal.offerDenom,
              offerAmt: prvsVal.offerAmt,
              price: parseFloat(prvsVal.price),
              note: prvsVal.note,
              unitName: prvsVal.unitName,
              smallUnitName: prvsVal.smallUnitName,
              quantity: parseFloat(prvsVal.quantity),
              sQuantity: parseFloat(prvsVal.sQuantity),
            });
          }

        }
      }).catch((error) => {
        setUtils({
          ...utils,
          wantLoader: false,
          isDataLoaded: false,
        });
        COMPONENT_PARAMS.prdId = "";
        console.log("errr ", error);
        //window.location.href = "#/error";
        setDataAlert(true);
        setStockSingleData({
          ...stockSingleData,
          prdName: '',
          brandName: '',
          prdId: '',
          productImage: '',
          stockRefNo: '',
          sgst: '',
          cgst: '',
          igst: '',
          localGst: '',
          sbcCessTax: '',
          productmrPrice: '',
          offerDenom: 1,
          offerAmt: '',
          productSellingPrice: '',
          productWholeSalePrice: '',
          sproductmrPrice: '',
          sofferDenom: 1,
          sofferAmt: '',
          sproductSellingPrice: '',
          sproductWholeSalePrice: '',
          smallTotalCount: -1,
          bigTotalCount: -1,
          catgName: '',
          unitName: '',
          rack_no:'',
          bigUnitName: '',
        });
      });
  };

  const BrownTextTypography = withStyles({
    root: {
      color: "#A52A2A"
    }
  })(Typography);
  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setUtils({
      ...utils,
      snackBarOpen: false,
      snackBarMsg: "",
    });
  };

  const [choiceUnit, setChoiceUnit] = useState({
    unitChecked: false,
    smallUnitChecked: false,
  });

  const getPriceTag =()=> {
      return (
          <React.Fragment>
          {
            stockSingleData.offerDenom === OfferType.Flat &&
            <div>
              <p style={{ color: 'red' }}>Flat &#8377;{stockSingleData.offerAmt + " "}
                <span style={{ color: 'black' }}><s><i>&#8377;{stockSingleData.productmrPrice}</i></s></span> <span style={{ color: 'black' }}> &#8377; {stockSingleData.productSellingPrice}</span> </p>
            </div>
          }
          {
            stockSingleData.offerDenom === OfferType.Percentage &&
            <div>
              <p style={{ color: 'red' }}>{stockSingleData.offerAmt + "% off "}
                <span style={{ color: 'black' }}><s><i>&#8377;{stockSingleData.productmrPrice}</i></s></span> <span style={{ color: 'black' }}> &#8377; {stockSingleData.productSellingPrice}</span> </p>
            </div>
          }
          {
            stockSingleData.offerDenom === OfferType.No_Offer &&
            <p style={{ color: 'black' }}>&#8377;{stockSingleData.productSellingPrice}
            </p>
          }
          </React.Fragment>
      )
  };

  const getSmallPriceTag =()=> {
    return (
        <React.Fragment>
        {
          stockSingleData.sofferDenom === OfferType.Flat &&
          <div>
            <p style={{ color: 'red' }}>Flat &#8377;{stockSingleData.sofferAmt + " "}
              <span style={{ color: 'black' }}><s><i>&#8377;{stockSingleData.sproductmrPrice}</i></s></span> <span style={{ color: 'black' }}> &#8377; {" " + stockSingleData.sproductSellingPrice}</span> </p>
          </div>
        }
        {
          stockSingleData.sofferDenom === OfferType.Percentage &&
          <div>
            <p style={{ color: 'red' }}>{stockSingleData.sofferAmt + "% off "}
              <span style={{ color: 'black' }}><s><i>&#8377;{stockSingleData.sproductmrPrice}</i></s></span> <span style={{ color: 'black' }}> &#8377; {" " + stockSingleData.sproductSellingPrice}</span> </p>
          </div>
        }
        {
          stockSingleData.sofferDenom === OfferType.No_Offer &&
          <p style={{ color: 'black' }}>&#8377;{stockSingleData.sproductSellingPrice}
          </p>
        }
        </React.Fragment>
    )
};

  const getGroceryDetailTable = () => {
    //console.log("getGroceryDetailTable ",isRoleAllowed(ADMINRIGHTS))
    return (
      
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            {
              isRoleAllowed(ADMINRIGHTS) &&
              <TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left">WholeSale Price(Without GST)</TableCell>
                {
                  !stockSingleData.unitRestrictions ?
                  <TableCell align="left">&#8377;{stockSingleData.productWholeSalePrice}/&#8377;{stockSingleData.sproductWholeSalePrice}</TableCell>
                  : <TableCell align="left">&#8377;{stockSingleData.productWholeSalePrice}</TableCell>
                }
                
              </TableRow>
            }
            <TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">MRP(Without GST) per {stockSingleData.bigUnitName}</TableCell>
              <TableCell align="left">{getPriceTag()}</TableCell>
            </TableRow>
            {
              !stockSingleData.unitRestrictions && <TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left">MRP(Without GST) per {stockSingleData.unitName}</TableCell>
                <TableCell align="left">{getSmallPriceTag()}</TableCell>
              </TableRow>
            }
            <TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">Total Count ({getLiveStockDetails()})</TableCell>
              <FormControlLabel disabled={stockSingleData.bigTotalCount === 0} control={<Checkbox checked={choiceUnit.unitChecked}
                onChange={handleInputChange} id="unitChecked" />} name="unitChecked" label={stockSingleData.bigUnitName} />
              {
                stockSingleData.customSmallTotalCount > 0 && <FormControlLabel disabled={stockSingleData.smallTotalCount === 0 || stockSingleData.unitRestrictions} control={<Checkbox checked={choiceUnit.smallUnitChecked}
                  onChange={handleInputChange} id="smallUnitChecked" />} name="smallUnitChecked" label={stockSingleData.unitName} />
              }
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const getLiveStockDetails = () => {
    let check = stockSingleData.customSmallTotalCount > 0 && !stockSingleData.unitRestrictions;
    return check ? (stockSingleData.bigTotalCount + " " + stockSingleData.bigUnitName + "/" + stockSingleData.smallTotalCount + " " + stockSingleData.unitName)
      : stockSingleData.customSmallTotalCount > 0 ? (stockSingleData.bigTotalCount + " " + stockSingleData.bigUnitName + "(A " + stockSingleData.bigUnitName + " of " + stockSingleData.customSmallTotalCount + " " + stockSingleData.unitName) : (stockSingleData.bigTotalCount + " " + stockSingleData.bigUnitName);
  };


  const [dataAlert, setDataAlert] = useState(false);

  const test = (option) => {

    const [text] = productData.filter(data => {
      if (data.prdId === option.prdId) {
        return data;
      }
    })
    //console.log("text", text);
    if (text && storeCartType === CartDets.Pharma) {
      return `${option.prdName} ${text.brandName}`

    }
    return option.prdName
  }
  const getSmallUnitQuanSelling = () => {
    let data = "";
    //console.log("getSmallUnitQuanSelling",billStockData);
    if (billStockData.sQuantity > 0) {
       if (choiceUnit.unitChecked && billStockData.quantity > 0) 
          data = " + ";
       data = data + (billStockData.sQuantity + " " + billStockData.smallUnitName);
       if (parseInt(billStockData.offerDenom) === OfferType.No_Offer) 
          data = data + (" x " + stockSingleData.sproductSellingPrice);
       else if (parseInt(billStockData.offerDenom) === OfferType.Flat)
          data = data + (" x (" + stockSingleData.sproductSellingPrice + " - Flat " + billStockData.offerAmt + ")");
       else 
          data = data + (" x (" + stockSingleData.sproductSellingPrice + " - " + billStockData.offerAmt + "%)");
    }
    return data;
  };
  const renderDialogContent = () => {
    //console.log(" skand => ",selectOption)
    return (
      <React.Fragment>
        <div>
          <Box
            component="form"
            autoComplete="off">

            {
              
              !utils.isDataLoaded &&
              <Autocomplete
                disabled={utils.wantLoader || utils.isEditProduct}
                id="search-select"
                sx={{ width: 300 }}
                options={optionList}
                autoHighlight
                onChange={handleSelectChange}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.optName}
                defaultValue={selectOption}
                value={selectOption}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                  >
                    {option.optName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Option to Search Product"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            }
            <br />
            {!utils.isDataLoaded && (
              (selectOption.id === 1) ?
                <TextField
                  disabled={utils.isEditProduct}
                  id="search-barccode"
                  label="Enter Bar Code"
                  name="search-barccode"
                  variant="filled"
                  size="small"
                  defaultValue={searchValue}
                  onChange={handleInputChange}
                />
                : (selectOption.id === 2) ?
                  <TextField
                    id="search-batchno"
                    label="Enter Batch Number"
                    name="search-batchno"
                    variant="filled"
                    size="small"
                    defaultValue={searchValue}
                    onChange={handleInputChange}
                  /> : <div>
                    {utils.wantLoader ?
                      <CircularProgress /> :
                      <Autocomplete
                        id="product-select"
                        sx={{ width: 300 }}
                        options={productData}
                        autoHighlight
                        onChange={handleProductSelectChange}
                        // getOptionLabel={(option) => option.prdName}
                        isOptionEqualToValue={(option, value) => option.prdId === value.prdId}
                        getOptionLabel={(option) => test(option)}
                        defaultValue={productSingleData}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                          >
                            {option.prdName + "(" + option.brandName + ")"}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Product to Search Stock"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    }
                    {dataAlert &&
                      <div>
                        <br />
                        <Alert severity="warning">Data is not available!!</Alert>
                      </div>
                    }
                  </div>
            )

            }
            {/* &nbsp;&nbsp; */}
            {
              (searchValue.length > 3) ?
                <SearchIcon onClick={handleSearch} /> : null
            }
            {
              utils.isDataLoaded ?
                <div>
                  <Grid container spacing={0}>
                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                      {productSingleData.productImage &&
                        <div>
                          <img
                          alt=""
                          style={{ height: "100px", width: "100px", borderRadius: "40%" }}
                          src={storeApiUrl.BACKEND_BASE_URL + "files/" + stockSingleData.productImage}
                        /> <br /><br />
                        </div>
                      }
                      <Typography variant="p" color="#02056e" fontWeight='bold'>
                        {"Item Details : "}
                      </Typography>
                      <Typography variant="p" color="#135f2c">
                        {stockSingleData.prdName + "(" + stockSingleData.brandName + ")"}
                      </Typography>
                      <br />
                     
                      <Typography variant="p" color="#02056e" fontWeight='bold'>
                        {"Cateogory : "}
                      </Typography>
                      <Typography variant="p" color="#135f2c">
                        {stockSingleData.catgName}
                      </Typography>
                      <br />
                      
                      {
                        stockSingleData.rack_no  && 
                        <div>
                          <Typography variant="p" color="#02056e" fontWeight='bold'>
                            {"Location : "}
                          </Typography>
                          <Typography variant="p" color="#135f2c">
                            {"Rack No -" + stockSingleData.rack_no}
                          </Typography>
                        </div>
                      }
                    </Grid>
                    <Grid item xs={12} md={12}>
                      {getGroceryDetailTable()}
                      <br />
                    </Grid>
                    <br />
                    {
                      choiceUnit.unitChecked && 
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          id="quantity"
                          label={"Enter Quantity(in " + stockSingleData.bigUnitName + ")"}
                          type="number"
                          name="quantity"
                          value={billStockData.quantity}
                          variant="filled"
                          size="small"
                          onChange={handleInputChange}
                          sx={{ width: "100%" }}
                          inputProps={{ min: '0' }}
                          error={addNewItem && !billStockData.quantity}
                          helperText={getErrorMessage('quantity')}
                        />
                      </Grid>
                    }
                    {
                      choiceUnit.smallUnitChecked && 
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          id="sQuantity"
                          label={"Enter Quantity(in " + stockSingleData.unitName + ")"}
                          type="number"
                          name="sQuantity"
                          value={billStockData.sQuantity}
                          variant="filled"
                          size="small"
                          onChange={handleInputChange}
                          sx={{ width: "100%" }}
                          inputProps={{ min: '0' }}
                          error={addNewItem && !billStockData.sQuantity}
                          helperText={getErrorMessage('sQuantity')}
                        />
                      </Grid>
                    }
                  </Grid>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Additional Offer</FormLabel>
                    <RadioGroup row aria-label="offerDenom" name="offerDenom" value={billStockData.offerDenom.toString()} onChange={handleInputChange}>
                      <FormControlLabel value={OfferType.Flat.toString()} control={<Radio />} label="Discount In Flat" />
                      <FormControlLabel value={OfferType.Percentage.toString()} control={<Radio />} label="Discount In Percentage" />
                      <FormControlLabel value={OfferType.No_Offer.toString()} control={<Radio />} label="No Offer" />
                    </RadioGroup>
                  </FormControl>                  
                  <div>
                    {
                      (billStockData.offerDenom > 1) &&
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>

                          <TextField
                            id="offerAmt"
                            name="offerAmt"
                            variant="filled"
                            size="small"
                            label="Enter Offer Value"
                            value={billStockData.offerAmt.toString()}
                            onChange={handleInputChange}
                            style={{ width: "100%" }}
                          />

                        </Grid>
                      </Grid>
                    }
                    <Typography variant="p" color="#02056e" fontWeight='bold'>
                        {"Selling Price : "}
                      </Typography>
                    <BrownTextTypography variant="p" style={{ color: "#00333E" }} noWrap={false}>
                      <br/>
                      {billStockData.quantity > 0 && (billStockData.quantity + " " + billStockData.unitName) + ((parseInt(billStockData.offerDenom) === OfferType.No_Offer) ?
                        " x " + stockSingleData.productSellingPrice
                        : (parseInt(billStockData.offerDenom) === OfferType.Flat) ?
                          " x (" + stockSingleData.productSellingPrice + " - Flat " + billStockData.offerAmt + ")"
                          : " x (" + stockSingleData.productSellingPrice + " - " + billStockData.offerAmt + "%)")}

                      {getSmallUnitQuanSelling()}
                      {" = "}
                      &#8377; {billStockData.price.toFixed(2)}
                      <br />
                      {"(Inc. GST@" + stockSingleData.localGst + "%)"}
                      <br />
                      {
                        (billStockData.sbcCessTax > 0.0) ?
                          "& Cess Tax " + stockSingleData.sbcCessTax + "% applied" : null
                      }
                    </BrownTextTypography>
                    <br/> 
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <TextField
                          id="note"
                          name="note"
                          variant="filled"
                          size="small"
                          rows={4}
                          maxRows={4}
                          minRows={1}
                          multiline
                          onChange={handleInputChange}
                          label="Note(If Any)"
                          value={billStockData.note}
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
                : utils.showLoading ? <Loader /> : null
            }

          </Box>
        </div>
      </React.Fragment >

    );
  }


  const renderDialogAction = () => {
    return (
      <React.Fragment>
        {
          !isPaid && <Button variant="contained" color="primary" onClick={handleReserveQuantity}>
            {utils.isEditProduct ? "Update" : "Add"}
          </Button>
        }
        <Button variant="contained" color="primary" onClick={handleDialogClose}>
          Cancel
        </Button>
      </React.Fragment>
    );
  }


  const doTotal = (oldPrice, billTotal, mPointRedeem) => {
    //console.log(oldPrice, billTotal, mPointRedeem,billDetails.subTotal);
    if (isNaN(mPointRedeem)) {
      mPointRedeem = 0.0;
    }
    let mTotal = 0.0;
    let mRoundOff = 0.0;
    billTotal = parseFloat(billDetails.subTotal) + parseFloat(billTotal) - parseFloat(oldPrice);
    //console.log(mPointRedeem,billTotal);
    if (parseFloat(mPointRedeem) >= parseFloat(billTotal)) {
      mPointRedeem = billTotal.toFixed(2);
      mTotal = 0.0;
    } else {
      mTotal = parseFloat(billTotal) - parseFloat(mPointRedeem);
      mRoundOff = mTotal.toFixed() - mTotal;
      mTotal = mTotal + mRoundOff;
    }
    //console.log(mPointRedeem,billTotal,mTotal);

    setBillDetails({
      ...billDetails,
      subTotal: billTotal.toFixed(2),
      total: mTotal.toFixed(2),
      roundOff: parseFloat(mRoundOff.toFixed(2)),
      pointRedeem: mPointRedeem,
      isPointAdded: (mPointRedeem > 0.0),
    });
  }

  const [customerInputDialog, setCustomerInputDialog] = useState({
    custInputopen: false,
    custInputAdd: false,
    redeemRoyaltyPrice: false,
  });

  const customerInputDialogClose = () => {
    setCustomerInputDialog({ ...customerInputDialog, custInputopen: false, custInputAdd: false })
    setBillingData({ ...listbillingData, custMob: "", custName: "", custEmail: "" });
    setCustFormSubmitted(false);
  }

  const addCustomerInput = () => {
    setCustFormSubmitted(true);
    if (!isValid()) {
      return;
    }
    setCustomerInputDialog({ ...customerInputDialog, custInputopen: false, custInputAdd: true })
    setIsCustDataAvailable(false);

  }

  const customerInputDialogActions = () => {
    return (
      <React.Fragment>
        <Button variant="contained" color="primary" onClick={addCustomerInput}>
          Add
        </Button>
        <Button variant="contained" color="primary" onClick={customerInputDialogClose}>
          Cancel
        </Button>
      </React.Fragment>
    )
  }

  const customerInputFields = () => {
    return (
      <div style={{ width: "400px" }}>
        <TextField id="outlined-basic" label="Customer Name" variant="filled"
          size="small" required
          onChange={e => setBillingData({ ...listbillingData, custName: e.target.value })}
          sx={{ width: "100%" }}
          error={custFormSubmitted && !listbillingData.custName}
          helperText={getErrorMessage('custName')} />
        <br /><br />
        <TextField id="outlined-basic" label="Customer Mobile Number" variant="filled" size="small"
          required defaultValue={listbillingData.custMob} sx={{ width: "100%" }}
          error={custFormSubmitted && !listbillingData.custMob}
          helperText={getErrorMessage('custMob')} />
        <br /><br />
        <TextField id="outlined-basic" label="Customer Email Address" variant="filled" size="small" onChange={e => setBillingData({ ...listbillingData, custEmail: e.target.value })} sx={{ width: "100%" }} />
      </div>
    )
  }

  const inputCustomerDetails = () => {
    return (
      <DialogBox
        showDialog={customerInputDialog.custInputopen}
        title="Enter Customer Details"
        onClose={customerInputDialogClose}
        dialogContent={customerInputFields()}
        dialogActions={customerInputDialogActions()}
      >
      </DialogBox>

    )
  }

  const applyRoyaltyPrice = () => {
    if (listbillingData.value_2.length > 0) {
      // console.log("applyRoyaltyPrice => ", listbillingData.pointRedeem);
      if (billDetails.isPointAdded) {
        doTotal(0.0, 0.0, 0.0)
      } else {
        doTotal(0.0, 0.0, parseFloat(listbillingData.pointRedeem))
      }
    }
  }

  const shopDetails = () => {
    return (
      <div className="shop-details-container billing-data-container">
        <Typography variant="p" component="div" className="shop-details" >Paid To :</Typography>
        <Typography variant="p" component="div" className="shop-name" >{storeActiveShopHash.shop} </Typography>
        <Typography variant="p" component="div" className="shop-details shop-address" >{storeActiveShopHash.location}</Typography>
        <Typography variant="p" component="div" className="shop-details shop-gst" >{storeActiveShopHash.gstDetail} </Typography>
      </div>
    )
  }
  const [listAllBill, setListAllBill] = useState([]);
  const clearPreviousData = () => {
        setBillStockData(INITIAL_BILSTOCK_DATA);
        setBillingData(INITIAL_LISTBILLING_DATA);
  };

  const handleSelectBill = (event,option) => {
    console.log("handleSelectBill",option);
    if (option!=null) {
      let isInitialStatgeBill = option.billStatus === BillStatus.INITIATED;
      setBillingData({
        ...listbillingData, ...option,
        pointRedeem: isInitialStatgeBill ? parseFloat(option.royalityPoint) : option.pointRedeem,
      });
      setBillDetails({
        ...billDetails,
        subTotal: option.totalPrice,
        pendingAmt: option.pendingAmt,
        partialAmt: option.partialAmt,
        roundOff: option.roundOff,
        cashTender: option.cashTender,
        cashReturn: option.cashReturn,
        pointRedeem: option.pointRedeem,
        isPointAdded: parseFloat(option.pointRedeem) > 0.0,
        total: option.finalPrice,
      });
      setCustomerInputDialog({
        ...customerInputDialog,
        custInputopen: false,
        custInputAdd: !isEmpty(option.custId),
        redeemRoyaltyPrice: isInitialStatgeBill ? parseFloat(option.royalityPoint) > 0.0 : false,
        getRoyaltyDiscount: false,
      });
      setIsCustDataAvailable(isEmpty(option.custId));
      setIsPaid(!isInitialStatgeBill);
    }
  };

  const handleBillSearch = (event) => {
    const { name, value } = event.target;
    //console.log("handleBillSearch",name,value)
    if (value.length >= 4) {
        clearPreviousData();        
        fetchBill(value);
      }
  };
  
  const renderCustomerForm = () => {
    return (
      <React.Fragment>
        {
          !isEmpty(listbillingData.billNo) &&
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h5" component="div">Invoice No: {listbillingData.billNo}</Typography>
          </div>
        }
        <div className="input-bill-number">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ padding: 2 }}>
            <Button
              variant="contained"
              color="primary"
              style={{ float: "right" }}
              onClick={() => setComponentIndex(DashboardComponent.SHOWBILLS)}>
              Show All Bills
            </Button>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="p" component="div">Search Bill Number :</Typography>
              
              <Autocomplete id="search-select"
                options={listAllBill}
                autoHighlight
                variant="filled"
                sx={{ minWidth: 250 }}
                fullWidth
                defaultValue={listbillingData}
                onChange={handleSelectBill}
                getOptionLabel={(option) => option.billNo}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                  >
                    {option.billNo+"("+option.custMob+")"}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bill or Mobile Number"
                    onChange={handleBillSearch}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />


              {/* <TextField id="sBillNo" name="sBillNo" onChange={handleInputChange} label="Bill Number" variant="filled" size="small" style={{ minHeight: "40px" }} />
              <SearchIcon onClick={() => fetchBill()} /> */}
            </div>
            {
              isPaid ?
                <Typography variant="p" component="div" className="shop-details" >Date : {listbillingData.createdAt} </Typography>
                : CART_INITIAL_STATE.backDateAllowed ?
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Date"
                      inputFormat="dd/MM/yyyy"
                      value={listbillingData.createdAt}
                      onChange={checkDate}
                      renderInput={(params) => <TextField {...params} />}
                      minDate={new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
                      maxDate={new Date()}
                    />
                  </LocalizationProvider> :
                  <Typography variant="p" component="div" className="shop-details" >Date : {listbillingData.createdAt} </Typography>

            }


          </div>
        </div>
        <div className="billing-data-grid-container">
          {
            !isPaid && <div className="billing-data-container">
              <Typography variant="p" component="div" className="existing-customer-search" style={{ marginBottom: "10px" }}>Search for Customer :</Typography>
              <TextField id="outlined-basic"
                label="Mobile Number"
                variant="filled" size="small"
                style={{ marginBottom: "15px" }}
                onChange={getCustomerData}
                value={listbillingData.custMob}
                disabled={isCustLoading ? true : false} required
                error={formSubmitted && !listbillingData.custMob}
                helperText={getErrorMessage('custMob')} />
              <span style={{ verticalAlign: "middle" }}>
                {isCustLoading ? <CircularProgress color="primary" size={30} /> : ''}
              </span>
            </div>
          }
          {shopDetails()}
          {(isCustDataAvailable || customerInputDialog.custInputAdd) &&
            <div className="billing-data-container">
              <Typography variant="p" component="div" className="existing-customer__error" style={{ whiteSpace: "initial" }}>{isCustDataAvailable ? "Customer Not Found!! Please make a new entry." : ''}</Typography>

              {inputCustomerDetails()}
              {customerInputDialog.custInputAdd &&
                <div className="customer-details-container">
                  <Typography variant="p" component="div" className="customer-details" >Bill To : </Typography>
                  <Typography variant="p" component="div" className="customer-name" >{listbillingData.custName} </Typography>
                  <Typography variant="p" component="div" className="customer-details customer-mob-num" >{listbillingData.custMob} </Typography>
                  <Typography variant="p" component="div" className="customer-details customer-email-id" style={{ whiteSpace: "initial", wordBreak: 'break-word' }}>{listbillingData.custEmail}</Typography>
                </div>
              }
            </div>
          }
        </div>
      </React.Fragment>
    )
  }

  const getCustomerData = (e) => {
    let custgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + GET_CUSTOMER_DETAIS;
    const { name, value } = e.target;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash:storeActiveShopHash.id,
        custMob: value
      }
    };

    if (value.length === 10) {
      //console.log("getCustomerData data",value,data);
      setIsCustLoading(true);
      //setBillingData({ ...listbillingData,custMob: value  });
      axios.post(
        custgUrl,
        data
      ).then(response => {
        if (response.data.status !== 200) {
          console.log("getCustomerData value",value);
          setBillingData({ ...listbillingData, custMob: value,custId: "", custName: "", custEmail: "" });
          
          throw new Error('Customer Data Not Found!!');
        }
        let jcJson = response.data.jcJson;
        console.log("getCustomerData jcJson",value,jcJson,listbillingData);
        setBillingData({ ...listbillingData, ...jcJson, pointRedeem: jcJson.royalityPoint })
        setCustomerInputDialog({ ...customerInputDialog, custInputAdd: true, redeemRoyaltyPrice: jcJson.royalityPoint > 0 })
        setIsCustDataAvailable(false);
      }).catch(error => {
        setIsCustDataAvailable(true);
        console.log(error);
      }).finally(() => {
        setIsCustLoading(false);
      })

    }
    else {
      setBillingData({ ...listbillingData, custId: "", custMob: value, custName: "", custEmail: "", pointRedeem: 0.0 });
      setCustomerInputDialog({ ...customerInputDialog, custInputAdd: false, redeemRoyaltyPrice: false, getRoyaltyDiscount: false })
      setIsCustDataAvailable(false);
    }
    setAlert(false);
  }


  const paymentOptionSelection = (e) => {
    setBillingData({ ...listbillingData, onlinePayMedium: e.target.value });
  }

  const [custCopy, setCustCopy] = useState(false);

  const raiseInvoiceClicked = (print) => {
    setCustCopy(false);
    if (!isEmpty(print)) {
      const url = storeApiUrl.BACKEND_BASE_URL + "files/" + print;
      window.open(url, '_blank');
    } else {
      console.log("raiseInvoiceClicked => " + print);
    }
  }

  const custCopyUi = () => {
    return (
      <React.Fragment>
        <Typography variant="subtitle4" component="div" style={{ color: "#2f1160" }} >Do You Want to Print Customer Copy</Typography>
      </React.Fragment>
    )
  };

  const custCopyAction = () => {
    return (
      <React.Fragment>
        <Button variant="contained" color="primary" onClick={() => raiseInvoiceClicked(custPrint)}>
          Yes
        </Button>
        <Button variant="contained" color="primary" onClick={() => setCustCopy(false)}>
          No
        </Button>
      </React.Fragment>
    )
  };

  const custCopyFunc = () => {
    return < DialogBox
      showDialog={custCopy}
      title="Alert!"
      // onClose={customerInputDialogClose}
      dialogContent={custCopyUi()}
      dialogActions={custCopyAction()}
    ></DialogBox>
  };

  const printBillAfterPay = (e) => {
    //console.log("printBillAfterPay",e.target.id);
    let printFor = e.target.id === 'shopbill' ? 1 : 0;
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + PRINT_BILL + "/" + CART_INITIAL_STATE.apiToken + "/" + listbillingData.billNo + "/"  + storeActiveShopHash.id+ "/" +printFor;
    let data = {

    };
    console.log("printBillAfterPay => ", catgUrl);
    axios.get(
      catgUrl,
      data
    )
      .then((response) => {
        console.log(response.data);
        if (response.data !== '') {
          raiseInvoiceClicked(response.data);
        }
      }).catch((error) => {
        console.log("error : ", error);
        //window.location.href = "#/error";
      });
  };

  const [billEInvoice, setBillEInvoice] = useState(false);
  const [paymentLink, setPaymentLink] = useState('');
  const eInvoiceDialogActions = () => {
    return (
      <React.Fragment>
        <Button variant="contained" color="primary" onClick={() => saveBill(true)} >
          Add
        </Button>
        <Button variant="contained" color="primary" onClick={() => setBillEInvoice(false)}>
          Cancel
        </Button>
      </React.Fragment>
    )
  };

  const eInvoiceInputFields = () => {

    return (
      <div style={{ width: "400px" }}>
        <TextField label="Enter Payment Link" variant="filled" size="small" sx={{ width: "100%" }}

          onChange={e => setPaymentLink(e.target.value)} />
      </div>
    )
  };

  const eInvoice = () => {

    return < DialogBox
      showDialog={billEInvoice}
      title="E-Invoice"
      // onClose={customerInputDialogClose}
      dialogContent={eInvoiceInputFields()}
      dialogActions={eInvoiceDialogActions()}
    ></DialogBox>
  };

  const saveBill = (mIsInvoice) => {
    //console.log(mIsInvoice, billDetails.cashTender);
    setFormSubmitted(true);
    if (!isValid()) {
      return;
    }

    let isError = false;
    if (mIsInvoice) {
      setBillEInvoice(false);
    } else {
      if (isEmpty(listbillingData.onlinePayThrough) && isEmpty(listbillingData.cashPayThrough)) {
        isError = true;
        setUtils({
          ...utils,
          snackBarMsg: "Please Select any of the Payment method",
          snackBarOpen: true,
        })
      }
      if (!isError) {
        if (isNumberEmpty(parseFloat(billDetails.cashTender)) || billDetails.cashTender === 0 || parseFloat(billDetails.cashTender) <= 0.0) {
          isError = true;
          setUtils({
            ...utils,
            snackBarMsg: "Please Input the Cash Tender Amount",
            snackBarOpen: true,
          })
        }
      }
      if (!isError) {
        if (billDetails.partialAmt > 0.0) {
          if (parseFloat(billDetails.cashTender) < billDetails.partialAmt) {
            isError = true;
            setUtils({
              ...utils,
              snackBarMsg: "Please Input the Cash Tender Amount Greater than Partial Amt i.e " + billDetails.partialAmt,
              snackBarOpen: true,
            })
          }
        } else {
          if (parseFloat(billDetails.cashTender) < billDetails.total) {
            isError = true;
            setUtils({
              ...utils,
              snackBarMsg: "Please Input the Cash Tender Amount Greater than Grand Amt i.e " + billDetails.total,
              snackBarOpen: true,
            })
          }
        }
      }
    }
    //console.log(isError);
    var today = new Date();
    var time = " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

    if (!isError) {
      if (listbillingData.custName && listbillingData.value_2.length > 0) {
        setUtils({
          ...utils,
          showMainLoader: true,
        });
        let custSaveUrl = storeApiUrl.BACKEND_BASE_URL + POST_BILL;
        let jcJson = {
          Admin: CART_INITIAL_STATE.userEmail,
          value_2: listbillingData.value_2,
          shopHash:storeActiveShopHash.id,
          onlinePayThrough: listbillingData.onlinePayThrough,
          onlinePayMedium: listbillingData.onlinePayMedium,
          cashPayThrough: listbillingData.cashPayThrough,
          onlinePay: parseFloat(listbillingData.onlinePay),
          cashPay: parseFloat(listbillingData.cashPay),
          einvoice: mIsInvoice,
          einvoiceStr: mIsInvoice ? paymentLink : "",
          billNo: listbillingData.billNo,
          custId: listbillingData.custId,
          custMob: listbillingData.custMob,
          custEmail: listbillingData.custEmail,
          custName: listbillingData.custName,
          cartType: storeCartType,
          roundOff: parseFloat(billDetails.roundOff),
          cashTender: parseFloat(billDetails.cashTender),
          cashReturn: parseFloat(billDetails.cashReturn),
          subTotal: parseFloat(billDetails.subTotal),
          total: parseFloat(billDetails.total),
          billStatus: parseInt(listbillingData.billStatus),
          pointRedeem: billDetails.isPointAdded ? parseFloat(billDetails.pointRedeem) : 0.0,
          partialAmt: parseFloat(billDetails.partialAmt),
          createdAt: CART_INITIAL_STATE.backDateAllowed ? listbillingData.createdAt + time : listbillingData.createdAt,
        };

        let data = { jcJson };
        axios.post(
          custSaveUrl,
          data
        ).then(response => {
          //console.log("Submit Bill =>", response.data);
          setUtils({
            ...utils,
            showMainLoader: false,
          });
          if (response.data.status === SUCCESS) {
            if (mIsInvoice) {
              raiseInvoiceClicked(response.data.jcJson.einvoice);
            } else {
              setCustPrint(response.data.jcJson.custprint);
              raiseInvoiceClicked(response.data.jcJson.shopprint);
              setCustCopy(true);
            }
            setBillingData({
              ...listbillingData,
              billNo: response.data.jcJson.billNo,
            });
            searchValue = response.data.jcJson.billNo;
            fetchBill();
          }

        }).catch(error => {
          console.log(error);
          setUtils({
            ...utils,
            showMainLoader: false,
          });
        });

        setAlert(false);
        setFormSubmitted(false);
      } else {
        //console.log("Please update requied details");
        setAlert(true);
      }
    }
  };


  const checkDate = (event) => {
    setBillingData({
      ...listbillingData,
      createdAt: event
    })
  };

  const getErrorMessage = (field) => {
    if (!formSubmitted) {
      // return;
    } else if (!listbillingData[field]) {
      return 'Field is required';
    }

    if (!addNewItem) {
      // return;
    } else if (!billStockData[field]) {
      return 'Field is required';
    }
    return;
  }

  const isValid = () => {
    let isBillingDataValid = true;

    if (!listbillingData.custMob || !listbillingData.custName ) {
      isBillingDataValid = false;
    }

    return isBillingDataValid;
  }

  const isItemValid = () => {
    let isItemDataValid = true;
    if(billStockData.quantity <= 0) {
      if (billStockData.sQuantity <= 0) 
        isItemDataValid = false;
    }
    return isItemDataValid;
  }



  return (
    <React.Fragment>
      {
        !utils.showMainLoader ?
          <div>
            <div>
              <Snackbar
                open={utils.snackBarOpen}
                autoHideDuration={3000}
                message={utils.snackBarMsg}
                onClose={() => handleSnackClose()}
              />
            </div>
            <div>{renderCustomerForm()}</div>
            <div>{custCopyFunc()}</div>
            <div>
              <div style={{ display: "flex", justifyContent: "flex-end", margin: "20px 0 15px 0" }}>
                {customerInputDialog.redeemRoyaltyPrice &&
                  <Button variant="contained" color="primary" onClick={applyRoyaltyPrice}>
                    {billDetails.isPointAdded ? "Remove Royalty Price" : "Redeem Royalty Price"}
                  </Button>
                }
                {isCustDataAvailable && <Button variant="contained" className="new-customer-entry" onClick={event => setCustomerInputDialog({ ...customerInputDialog, custInputopen: true })}>Add New Customer</Button>}
                {!isPaid ? <Button variant="contained" color="primary" className="float-left" style={{ marginLeft: "15px" }}
                  onClick={(event) => {
                    setUtils({
                      ...utils,
                      showDialog: true,
                    });
                    setAlert(false);
                  }}>
                  Add New Item
                </Button> : null}

              </div>
              < DialogBox
                showDialog={utils.showDialog}
                title={utils.isEditProduct ? "Update Item" : "Add Item"}
                onClose={handleDialogClose}
                dialogContent={renderDialogContent()}
                dialogActions={renderDialogAction()}
              ></DialogBox>
            </div>
            <div style={{ maxHeight: 400, width: "100%" }}>
              <CustomDataGrid
                getRowId={row => row.prdId}
                rows={listbillingData.value_2}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
              />
            </div>
            <div>

              <Grid container spacing={0} className="summary">
                <Grid item xs={8} className="summary-section summary-section__left">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <FormControl>
                        <FormLabel id="paymentMethod">Our Payment Methods :</FormLabel>
                        <FormControlLabel disabled={isPaid} name="onlinePay" value={ONLINEPAY} checked={listbillingData.onlinePayThrough === ONLINEPAY}
                          onChange={handleInputChange} control={<Checkbox />} label="Online" />
                        {isOnlinePayEnabled &&
                          <FormControl style={{ width: "200px" }}>
                            <InputLabel id="demo-simple-select-label">Online Payment Options</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              disabled={isPaid}
                              value={listbillingData.onlinePayMedium}
                              label="Online Payment Options"
                              onChange={paymentOptionSelection}
                            >
                              {CART_INITIAL_STATE.onlinePayMethod.map(data => <MenuItem value={data}>{data}</MenuItem>)}
                            </Select>
                            <TextField
                              id="onlinePayAmt"
                              name="onlinePayAmt"
                              variant="filled"
                              size="small"
                              disabled={isPaid}
                              label="Enter Online Amount"
                              value={listbillingData.onlinePay.toString()}
                              onChange={handleInputChange}
                              sx={{ marginTop: 2 }}
                              style={{ width: "80%" }}
                            />
                          </FormControl>
                        }
                        <FormControlLabel name="cashPay" disabled={isPaid} value={CASHPAY} checked={listbillingData.cashPayThrough === CASHPAY} onChange={handleInputChange} control={<Checkbox />} label="Cash" />
                        {
                          isCashPayEnabled ?
                            <TextField
                              id="cashPayAmt"
                              name="cashPayAmt"
                              variant="filled"
                              size="small"
                              disabled={isPaid}
                              label="Enter Cash Amount"
                              value={listbillingData.cashPay.toString()}
                              onChange={handleInputChange}
                              style={{ width: "80%" }}
                            />
                            : null
                        }
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <FormControl>
                        <FormLabel id="billStatus">Bill Status :</FormLabel>
                        <RadioGroup
                          // row
                          aria-labelledby="billStatus"
                          value={listbillingData.billStatus}
                          name="billStatus"
                          disabled={isPaid}
                          onChange={(e) => {
                            setBillDetails({
                              ...billDetails,
                              pendingAmt: 0, partialAmt: 0,
                            });
                            setBillingData({ ...listbillingData, billStatus: parseInt(e.target.value) });

                          }}
                        >
                          <FormControlLabel disabled={isPaid} value={BillStatus.INITIATED} control={<Radio />} label="E-Invoice Generate" />
                          <FormControlLabel disabled={isPaid} value={BillStatus.PAID} control={<Radio />} label="Fully Paid" />
                          <FormControlLabel disabled={isPaid} value={BillStatus.PARTIALLYPAID} control={<Radio />} label="Partially Paid" />
                          {parseInt(listbillingData.billStatus) === 4 &&
                            <div>
                              <TextField style={{ width: "180px" }} value={billDetails.partialAmt}
                                id="partialAmt" name="partialAmt" disabled={isPaid} label="Enter Amount Paid" variant="filled" size="small" onChange={handleInputChange} />
                            </div>
                          }
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} className="summary-section summary-section__right">
                  <Grid container spacing={3}>
                    <Grid item xs="auto">
                      <Typography variant="subtitle4" component="div" style={{ color: "#2f1160" }} >Sub Total: ₹{billDetails.subTotal}</Typography>
                      {
                        billDetails.roundOff !== 0.0 || billDetails.roundOff !== -0.0 ?
                          <Typography variant="subtitle4" component="div" style={{ color: "#A52A2A" }} >RoundOff{billDetails.roundOff > 0.0 ? "(+)" : "(-)"} {billDetails.roundOff}</Typography>
                          : null
                      }
                      {
                        billDetails.isPointAdded &&
                        <Typography variant="subtitle4" component="div" style={{ color: "#A52A2A" }}>Royality Point(-) ₹{billDetails.pointRedeem}</Typography>
                      }
                      <Typography variant="subtitle4" component="div" style={{ color: "#2f1160" }} >Grand Total: ₹{billDetails.total}</Typography>
                      {billDetails.partialAmt > 0.0 &&
                        <Grid item xs="auto">
                          <Typography variant="subtitle4" style={{ color: "#2f1160" }} component="div" >Partial Amt Paid ₹{billDetails.partialAmt}</Typography>
                        </Grid>
                      }
                      <TextField
                        id="cashTender"
                        name="cashTender"
                        variant="filled"
                        size="small"
                        disabled={isPaid}
                        label="Enter Cash Tender"
                        defaultValue={billDetails.cashTender}
                        onChange={handleInputChange}
                        sx={{
                          marginTop: 2,
                        }}
                        style={{ width: "70%" }}
                      />
                      {
                        billDetails.cashReturn > 0.0 ?
                          <Typography variant="subtitle4" component="div" style={{ color: "#A52A2A" }} >Cash Return: ₹{billDetails.cashReturn}</Typography>
                          : null
                      }
                      {billDetails.pendingAmt > 0.0 &&
                        <Grid item xs="auto">
                          <Typography variant="subtitle4" component="div" >Dues ₹{billDetails.pendingAmt}</Typography>
                        </Grid>
                      }

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            {eInvoice()}
            <br />
            {alert &&
              <Alert severity="warning">Please Add Items And Proceed With Billing. Also Update Customer Details If Not Done! </Alert>
            }
            {
              listbillingData.billStatus === BillStatus.INITIATED ?
                <Button variant="contained" color="primary" style={{ marginTop: "15px", marginLeft: "24px" }} onClick={() => setBillEInvoice(true)}>
                  E-Invoice
                </Button> : null
            }
            {
              (isPaid) ?
                <Button variant="contained" style={{ marginTop: "15px", marginRight: "5px", float: "left" }} id="custbill" name="custbill" color="primary" onClick={printBillAfterPay}>
                  Customer Copy
                </Button> : null
            }

            {
              isPaid ?
                <Button variant="contained" id="shopbill" name="shopbill" color="primary" style={{ marginTop: "15px", marginRight: "5px", float: "left" }} onClick={printBillAfterPay}>
                  Shop Copy
                </Button> : null
            }
            {
              !isPaid && listbillingData.billStatus !== BillStatus.INITIATED ?
                <Button variant="contained" color="primary" style={{ marginTop: "15px", marginRight: "5px", float: "right" }} onClick={() => saveBill(false)}>
                  Submit Bill
                </Button> : null
            }

          </div> : <Loader />
      }
    </React.Fragment>
  );
}
