import React, {  useState } from "react";
import CustomDataGrid from "../global/CustomDataGrid";
import { Grid, TextField, Checkbox, Autocomplete, Box, Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import HideSourceIcon from '@mui/icons-material/HideSource';
import Loader from "../global/loader/Loader";
import Paper from "@mui/material/Paper";
import "../common.css";
import {
  GET_SINGLE_PRODUCT_DATA,
  SET_KIRANA_STOCK_DATA,
  SET_PHARMA_STOCK_DATA,
  SET_PIPE_STOCK,
  GET_SUPPLIER_FEW_DATA,
  GET_STOCK_DATA,
  GETSTOCKBARCODE,
  DELETESTOCK,
  getPreUrlAccordingToCart,
  SUCCESS,
  UPDATE_CUSTOMER_PRICES,
  CART_INITIAL_STATE,
  isEmpty,
  CartDets,
  COMPONENT_PARAMS,
  OfferType,
  BillStatus, DEBIT, AccountingPaidMode, isRoleAllowed, STOCKUPDATE, INVENTORYUPDATEPRICING, NODATA, ACCESSDENIED, SESSIONEXPIRED, FAILED, STOCK_PRIORITY,
  printDebugLog,
  printInfoLog,
  DashboardComponent
} from "../../constants/constant";
import axios from "axios";
import DialogBox from "../global/DialogBox";
import {
  Button, FormControl, RadioGroup, FormControlLabel,
  FormLabel, Radio, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { snpAxios } from '../global/api';
import SnpComponent from "../global/SnpComponent";
export default function StockEntry({ setComponentIndex }) {
  const INITIAL_SNP_COMPONENT = {
    showDialog: false,
    outSideClickDismiss: true,
    title: "",
    subTitle: "",
    negativeAction: "",
    positiveAction: "",
    positiveReq: false,
    isLoader: false,
    iconReq: false,
    iconContent: "",
    snackBarOpen: false,
    snackBarMsg: "",
    cancelExtra: false,
    negativeReq: false,
    requestType: 0,
    handleSnpSubmit: null,
    handleSnpCancel: null,
    gui: "",
  };
  const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
  const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
  const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
  const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
  const CustomTextTypography = withStyles({
    root: {
      color: "#135f2c"
    }
  })(Typography);

  const GreenTextTypographyBold = withStyles({
    root: {
      color: "#135f2c",
      fontWeight: 'bold'
    }
  })(Typography);

  const BlueTextTypography = withStyles({
    root: {
      color: "#02056e",
      fontWeight: 'bold'
    }
  })(Typography);

  const [isDataLoaded, setDataLoaded] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isEditProduct, setEditProduct] = useState(false);
  const [stockData, setStockData] = useState([]);


  useEffect(() => {
    setDataLoaded(false);
    getSupplierDetails();
    return () => {
      COMPONENT_PARAMS.prdId = "";
    };
  }, [storeCartType,storeApiUrl,storeActiveShopHash]);

  const [itemDets, setItemDets] = useState({
    prd: "",
    prdId: "",
    catgName: "",
    prdName: "",
    brandName: "",
    hsnCode: "",
    productImage: [],
    cnvId: "",
    unitName: "",
    unitCount: 1,
    unitRestrictions: false,
    smallUnitName: "",
    smallUnitCount: 0,
    composition:[],
  });

  const INITIAL_STOCK_MGMT = {
    productmrPrice: 0.0,
    offerDenom: 1,
    offerAmt: 0.0,
    productSellingPrice: 0.0,
    productWholeSalePrice: 0.0,
    sproductmrPrice: 0.0,
    sofferDenom: 1,
    sofferAmt: 0.0,
    sproductSellingPrice: 0.0,
    sproductWholeSalePrice: 0.0,
    totalBalance: 0.0,
    smallTotalCount: 0,
    bigTotalCount: 0,
    taxMethod:false,
  };
  const [stockManagement, setStockManagement] = useState(INITIAL_STOCK_MGMT);

  const [supplierData, setSupplierData] = useState([]);
  const [supplierSingleData, setSupplierSingleData] = useState({
    supplierId: "",
    supplierName: "",
  });



  const [account, setAccount] = useState({
    accStatus: BillStatus.NO_ENTRY,
    accAmt: 0.0,
    partialAmt: 0.0,
  });

  const [choiceUnit, setChoiceUnit] = useState({
    unitChecked: false,
    smallUnitChecked: false,
  });

  const INITIAL_STOCK_SINGLE_DATA = {
    prdId: "",
    stockRefNo: "",
    billNo: "",
    batchNo: "",
    barcodeNo: "",
    mfgDate: "",
    expiryDate: "",
    nearByExpiry: false,
    isExpired: false,
    quantity: 0,
    sQuantity: 0,
    notGoodQuality: false,
    supplierId: "",
    supplierName: "",
    sgst: 2.5,
    cgst: 2.5,
    igst: 0.0,
    taxMethod:false,
    custGstDisabled: true,
    buyerGstTaken: true,
    sbcCessTax: 0.0,
    status: 1,
    outOfStock: false,
    productmrPrice: 0.0,
    offerDenom: 1,
    offerAmt: 0.0,
    productSellingPrice: 0.0,
    productWholeSalePrice: 0.0,
    sproductmrPrice: 0.0,
    sofferDenom: 1,
    sofferAmt: 0.0,
    sproductSellingPrice: 0.0,
    sproductWholeSalePrice: 0.0,
    purchasePrice:0.0,
    priorityHighinSell: false,
  };
  const [stockSingleData, setSingleStockData] = useState(INITIAL_STOCK_SINGLE_DATA);

  const getSupplierDetails = async () => {
    let cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + GET_SUPPLIER_FEW_DATA;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
      }
    };
    //  console.log("getSupplierDetails  => ",data);
    snpAxios.post(
      cartUrl,
      data
    )
      .then((response) => {
        // console.log("response  => ", response.data);
        if (response.data.status === SUCCESS) {
          setSupplierData(response.data.jcJson.value_2);
        }
        getProductByProductId();
      }).catch((error) => {
        console.log("errr ", error);
        getProductByProductId();
        //window.location.href = "#/error";
      });
  };



  const getStockDetails = async () => {
    //console.log("setItemDets1 => ",itemDets);
    let cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + GET_STOCK_DATA;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        value_1: COMPONENT_PARAMS.prdId,
        cartType: storeCartType,
      }
    };
    //console.log("getStockDetails  => ",data);
    axios.post(
      cartUrl,
      data
    )
      .then((response) => {
        printDebugLog("stock response  => ", response.data);
        setDataLoaded(true);
        if (COMPONENT_PARAMS.isEditStock === 1) {
          COMPONENT_PARAMS.isEditStock = 0;
          //COMPONENT_PARAMS.prdId = "";
          setShowDialog(true);
        }
        if (response.data.status === SUCCESS) {
          //console.log("stock Success => ");
          setStockData(response.data.jcJson.value_2);
          setStockManagement({
            ...stockManagement,
            smallTotalCount: response.data.jcJson.smallTotalCount,
            bigTotalCount: response.data.jcJson.bigTotalCount,
            productmrPrice: response.data.jcJson.productmrPrice,
            offerDenom: response.data.jcJson.offerDenom,
            offerAmt: response.data.jcJson.offerAmt,
            productSellingPrice: response.data.jcJson.productSellingPrice,
            productWholeSalePrice: response.data.jcJson.productWholeSalePrice,
            taxMethod:response.data.jcJson.taxMethod,
            sproductmrPrice: response.data.jcJson.sproductmrPrice,
            sofferDenom: response.data.jcJson.sofferDenom,
            sofferAmt: response.data.jcJson.sofferAmt,
            sproductSellingPrice: response.data.jcJson.sproductSellingPrice,
            sproductWholeSalePrice: response.data.jcJson.sproductWholeSalePrice,
            totalBalance: response.data.jcJson.totalBalance,
          });
          //console.log("setItemDets2 => ",itemDets);
          // let mUnitChecked = (response.data.jcJson.bigTotalCount > 0);
          // let mSmallUnitChecked = (response.data.jcJson.smallTotalCount > 0
          //   && itemDets.smallUnitCount > 0 && !itemDets.unitRestrictions);
          // setChoiceUnit({
          //   ...choiceUnit,
          //   smallUnitChecked: mSmallUnitChecked,
          //   unitChecked: mUnitChecked,
          // })
        }
      }).catch((error) => {
        setDataLoaded(true);
        printInfoLog("getStockDetails errr ", error);
        //window.location.href = "#/error";
      });
  };
  const getProductByProductId = async () => {
    let prdId = ""
    if (isEmpty(COMPONENT_PARAMS.prdId) && isEmpty(itemDets.prdId)) {
      setSnpComponent({
        ...snpComponent,
        isLoader: false,
        negativeReq: false,
        positiveReq: true,
        positiveAction: "Go Back",
        showDialog: true,
        outSideClickDismiss: true,
        cancelExtra: true,
        requestType: 99,
        subTitle: "Internal Error, Please Select Product again.",
        handleSnpSubmit: () => {setSnpComponent(INITIAL_SNP_COMPONENT);setComponentIndex(DashboardComponent.PRODUCTENTRY) },
      });
    } else if (!isEmpty(COMPONENT_PARAMS.prdId))
        prdId = COMPONENT_PARAMS.prdId;
    else
        prdId = itemDets.prdId;

    let cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + GET_SINGLE_PRODUCT_DATA;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        value_1: prdId,
      }
    };
    snpAxios.post(
      cartUrl,
      data
    )
      .then((response) => {
        printDebugLog("getProductByProductId response  => ", response.data);
        if (response.data.status === SUCCESS) {
          setItemDets({
            ...itemDets,
            prdId: response.data.jcJson.prdId,
            hsnCode: response.data.jcJson.hsnCode,
            brandName: response.data.jcJson.brandName,
            composition: response.data.jcJson.composition,
            productImage: response.data.jcJson.productImage,
            catgName: response.data.jcJson.catgName,
            catgId: response.data.jcJson.catgId,
            cnvId: response.data.jcJson.cnvId,
            unitName: response.data.jcJson.unitName,
            unitCount: response.data.jcJson.unitCount,
            unitRestrictions: response.data.jcJson.unitRestrictions,
            smallUnitName: response.data.jcJson.smallUnitName,
            smallUnitCount: response.data.jcJson.smallUnitCount,
            prdName: response.data.jcJson.prdName,
            prd: response.data.jcJson.prdName + "(" + response.data.jcJson.brandName + ")",
          });

        }
        getStockDetails();
      }).catch((error) => {
        printInfoLog("errr ", error);
        //window.location.href = "#/error";
        getStockDetails();
      });
  };






  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSupplierChange = (event, option) => {
    if (option != null) {
      //console.log("handleSupplierChange", event, option);
      setSupplierSingleData({
        ...supplierSingleData,
        supplierId: option.supplierId,
        supplierName: option.supplierName
      });
      setSingleStockData({
        ...stockSingleData, supplierId: option.supplierId,
        supplierName: option.supplierName
      });
    }
  };

  const getOfferTag = (offerDenom,offerAmt,value) => {
    let price = 0.0;
    if (parseInt(offerDenom) === OfferType.No_Offer) {
      price = value;
    } else if (parseInt(offerDenom) === OfferType.Flat) {
      price = value - offerAmt;
    } else if (parseInt(offerDenom) === OfferType.Percentage) {
      price = value
        - ((value * offerAmt) / 100);
    }
    return price;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    //console.log("handleInputChange", name, value);
    if (name === 'unitChecked') {
      let previousCheckedValue = choiceUnit.unitChecked;
      setChoiceUnit({
        ...choiceUnit,
        unitChecked: !previousCheckedValue,
      });
      setSingleStockData({
        ...stockSingleData,
        quantity: 0,
      });
    } else if (name === 'smallUnitChecked') {
      let previousCheckedValue = choiceUnit.smallUnitChecked;
      setChoiceUnit({
        ...choiceUnit,
        smallUnitChecked: !previousCheckedValue,
      });
      setSingleStockData({
        ...stockSingleData,
        sQuantity: 0,
      });
    } else if (name === 'outOfStock') {
      let previousCheckedValue = stockSingleData.outOfStock;
      setSingleStockData({
        ...stockSingleData,
        outOfStock: !previousCheckedValue,
      });
    } else if (name === 'taxMethod') {
      let previousCheckedValue = stockSingleData.taxMethod;
      setSingleStockData({
        ...stockSingleData,
        taxMethod: !previousCheckedValue,
      });
    } else if (name === 'isExpired') {
      let previousCheckedValue = stockSingleData.isExpired;
      setSingleStockData({
        ...stockSingleData,
        isExpired: !previousCheckedValue,
      });
    } else if (name === 'custGstDisabled') {
      let previousCheckedValue = stockSingleData.custGstDisabled;
      setSingleStockData({
        ...stockSingleData,
        custGstDisabled: !previousCheckedValue,
      });
    } else if (name === 'buyerGstTaken') {
      let previousCheckedValue = stockSingleData.buyerGstTaken;
      setSingleStockData({
        ...stockSingleData,
        buyerGstTaken: !previousCheckedValue,
      });
    } else if (name === 'notGoodQuality') {
      let previousCheckedValue = stockSingleData.notGoodQuality;
      setSingleStockData({
        ...stockSingleData,
        notGoodQuality: !previousCheckedValue,
      });
    // } else if (name === 'productWholeSalePrice') {
    //   let sPrice = 0.0;
    //   if (!itemDets.unitRestrictions)
    //     sPrice = value / itemDets.smallUnitCount;
    //   setSingleStockData({
    //     ...stockSingleData,
    //     productWholeSalePrice: value,
    //     sproductWholeSalePrice: sPrice,
    //   });
    // } else if (name === 'sproductWholeSalePrice') {
    //   let sPrice = value * itemDets.smallUnitCount;
    //   setSingleStockData({
    //     ...stockSingleData,
    //     sproductWholeSalePrice: value,
    //     productWholeSalePrice: sPrice,
    //   });
    } else if (name === 'productmrPrice') {
      let price = getOfferTag(stockSingleData.offerDenom,stockSingleData.offerAmt,value);
      // let sPrice = 0.0,ssPrice = 0.0;
      // if (!itemDets.unitRestrictions) { 
      //    sPrice = value / itemDets.smallUnitCount;
      //    ssPrice = getOfferTag(stockSingleData.sofferDenom,stockSingleData.sofferAmt,sPrice);
      // }
      setSingleStockData({
        ...stockSingleData,
        productmrPrice: value,
        productSellingPrice: price,
        // sproductmrPrice:sPrice,
        // sproductSellingPrice:ssPrice,
      });
    } else if (name === 'sproductmrPrice') {
      let price = getOfferTag(stockSingleData.sofferDenom,stockSingleData.sofferAmt,value);
      // let sPrice = value * itemDets.smallUnitCount;
      // let ssPrice = getOfferTag(stockSingleData.offerDenom,stockSingleData.offerAmt,sPrice);
      setSingleStockData({
        ...stockSingleData,
        sproductmrPrice: value,
        sproductSellingPrice: price,
        // productmrPrice: sPrice,
        // productSellingPrice: ssPrice,
      });
    } else if (name === 'itemdetsproductmrPrice') {
      let price = getOfferTag(stockManagement.offerDenom,stockManagement.offerAmt,value);
      
      // let sPrice = 0.0;
      // if (!itemDets.unitRestrictions)
      //   sPrice = value / itemDets.smallUnitCount;
      setSingleStockData({
        ...stockSingleData,
        productmrPrice: value,
        productSellingPrice: price,
        // sproductmrPrice: sPrice,
        // sproductSellingPrice: sPrice,
      });
    } else if (name === 'sitemdetsproductmrPrice') {
      let price = getOfferTag(stockManagement.sofferDenom,stockManagement.sofferAmt,value);
      
      // let sPrice = value * itemDets.smallUnitCount;
      setSingleStockData({
        ...stockSingleData,
        sproductmrPrice: value,
        sproductSellingPrice: price,
        // productmrPrice: sPrice,
        // productSellingPrice: sPrice,
      });
    } else if (name === 'offerDenom') {
      let price = stockSingleData.productSellingPrice;
      if (stockSingleData.productmrPrice > 0.0) {
        if (parseInt(value) === OfferType.No_Offer) {
          price = stockSingleData.productmrPrice;
        } else if (parseInt(value) === OfferType.Flat) {
          price = stockSingleData.productmrPrice - stockSingleData.offerAmt;
        } else if (parseInt(value) === OfferType.Percentage) {
          price = stockSingleData.productmrPrice
            - ((stockSingleData.productmrPrice * stockSingleData.offerAmt) / 100);
        }
      }
      setSingleStockData({
        ...stockSingleData,
        offerDenom: value,
        productSellingPrice: price,
      });
    } else if (name === 'sofferDenom') {
      let price = stockSingleData.sproductSellingPrice;
      if (stockSingleData.sproductmrPrice > 0.0) {
        if (parseInt(value) === OfferType.No_Offer) {
          price = stockSingleData.sproductmrPrice;
        } else if (parseInt(value) === OfferType.Flat) {
          price = stockSingleData.sproductmrPrice - stockSingleData.sofferAmt;
        } else if (parseInt(value) === OfferType.Percentage) {
          price = stockSingleData.sproductmrPrice
            - ((stockSingleData.sproductmrPrice * stockSingleData.sofferAmt) / 100);
        }
      }
      setSingleStockData({
        ...stockSingleData,
        sofferDenom: value,
        sproductSellingPrice: price,
      });
    } else if (name === 'itemdetsofferDenom') {
      let price = stockManagement.productSellingPrice;
      if (stockManagement.productmrPrice > 0.0) {
        if (parseInt(value) === OfferType.No_Offer) {
          price = stockManagement.productmrPrice;
        } else if (parseInt(value) === OfferType.Flat) {
          price = stockManagement.productmrPrice - stockManagement.offerAmt;
        } else if (parseInt(value) === OfferType.Percentage) {
          price = stockManagement.productmrPrice
            - ((stockManagement.productmrPrice * stockManagement.offerAmt) / 100);
        }
      }
      setStockManagement({
        ...stockManagement,
        offerDenom: value,
        productSellingPrice: price,
      });
    } else if (name === 'sitemdetsofferDenom') {
      let price = stockManagement.sproductSellingPrice;
      if (stockManagement.sproductmrPrice > 0.0) {
        if (parseInt(value) === OfferType.No_Offer) {
          price = stockManagement.sproductmrPrice;
        } else if (parseInt(value) === OfferType.Flat) {
          price = stockManagement.sproductmrPrice - stockManagement.sofferAmt;
        } else if (parseInt(value) === OfferType.Percentage) {
          price = stockManagement.sproductmrPrice
            - ((stockManagement.sproductmrPrice * stockManagement.sofferAmt) / 100);
        }
      }
      setStockManagement({
        ...stockManagement,
        sofferDenom: value,
        sproductSellingPrice: price,
      });
    } else if (name === 'offerAmt') {
      let price = stockSingleData.productSellingPrice;
      if (stockSingleData.productmrPrice > 0.0) {
        if (parseInt(stockSingleData.offerDenom) === OfferType.No_Offer) {
          price = stockSingleData.productmrPrice;
        } else if (parseInt(stockSingleData.offerDenom) === OfferType.Flat) {
          price = stockSingleData.productmrPrice - value;
        } else if (parseInt(stockSingleData.offerDenom) === OfferType.Percentage) {
          price = stockSingleData.productmrPrice
            - ((stockSingleData.productmrPrice * value) / 100);
        }
      }
      setSingleStockData({
        ...stockSingleData,
        offerAmt: value,
        productSellingPrice: price,
      });
    } else if (name === 'sofferAmt') {
      let price = stockSingleData.sproductSellingPrice;
      if (stockSingleData.sproductmrPrice > 0.0) {
        if (parseInt(stockSingleData.sofferDenom) === OfferType.No_Offer) {
          price = stockSingleData.sproductmrPrice;
        } else if (parseInt(stockSingleData.sofferDenom) === OfferType.Flat) {
          price = stockSingleData.sproductmrPrice - value;
        } else if (parseInt(stockSingleData.sofferDenom) === OfferType.Percentage) {
          price = stockSingleData.sproductmrPrice
            - ((stockSingleData.sproductmrPrice * value) / 100);
        }
      }
      setSingleStockData({
        ...stockSingleData,
        sofferAmt: value,
        sproductSellingPrice: price,
      });
    } else if (name === 'itemdetsofferAmt') {
      let price = stockManagement.productSellingPrice;
      if (stockManagement.productmrPrice > 0.0) {
        if (parseInt(stockManagement.offerDenom) === OfferType.No_Offer) {
          price = stockManagement.productmrPrice;
        } else if (parseInt(stockManagement.offerDenom) === OfferType.Flat) {
          price = stockManagement.productmrPrice - value;
        } else if (parseInt(stockManagement.offerDenom) === OfferType.Percentage) {
          price = stockManagement.productmrPrice
            - ((stockManagement.productmrPrice * value) / 100);
        }
      }
      setStockManagement({
        ...stockManagement,
        offerAmt: value,
        productSellingPrice: price,
      });
    } else if (name === 'sitemdetsofferAmt') {
      let price = stockManagement.sproductSellingPrice;
      if (stockManagement.sproductmrPrice > 0.0) {
        if (parseInt(stockManagement.sofferDenom) === OfferType.No_Offer) {
          price = stockManagement.sproductmrPrice;
        } else if (parseInt(stockManagement.sofferDenom) === OfferType.Flat) {
          price = stockManagement.sproductmrPrice - value;
        } else if (parseInt(stockManagement.sofferDenom) === OfferType.Percentage) {
          price = stockManagement.sproductmrPrice
            - ((stockManagement.sproductmrPrice * value) / 100);
        }
      }
      setStockManagement({
        ...stockManagement,
        sofferAmt: value,
        sproductSellingPrice: price,
      });
    } else if (name === 'accStatus') {
      setAccount({
        ...account,
        [name]: value,
      });
    } else if (name === 'accAmt' || name === 'partialAmt') {
      if (parseFloat(itemDets.totalBalance) >= value) {
        setAccount({
          ...account,
          [name]: value,
        });
      } else {
        setSnpComponent({
          ...snpComponent,
          snackBarOpen: true,
          snackBarMsg: "Exceeds Total Bal i.e " + itemDets.totalBalance,
        });
      }
    } else {
      setSingleStockData({
        ...stockSingleData,
        [name]: value,
      });
    }
  };

  const clearstockData = () => {
    setChoiceUnit({
      ...choiceUnit,
      unitChecked: false,
      smallUnitChecked: false,
    });
    setSupplierSingleData({
      ...supplierSingleData,
      supplierId: "",
      supplierName: "",
    });
    setAccount({
      ...account,
      accStatus: BillStatus.NO_ENTRY,
      accAmt: 0.0,
      partialAmt: 0.0,
    })

    setSingleStockData({
      ...stockSingleData,
      stockRefNo: "",
      billNo: "",
      batchNo: "",
      barcodeNo: "",
      mfgDate: "",
      expiryDate: "",
      nearByExpiry: "",
      isExpired: false,
      quantity: 0,
      notGoodQuality: false,
      supplierId: "",
      supplierName: "",
      custGstDisabled: true,
      buyerGstTaken: true,
      status: 1,
      outOfStock: false,
      name: "",
      priorityHighinSell: false,
      purchasePrice:0.0,
      taxMethod:false,
    });
  };
  const getColumnUnit = (data) => {
    let check = itemDets.smallUnitCount > 0 && !itemDets.unitRestrictions;
    return check ? (data.quantity+" "+itemDets.unitName + "/" +data.sQuantity+" "+itemDets.smallUnitName)
      : (data.quantity+" "+itemDets.unitName);
  };

  const getWholeSalePrice = () => {
    let check = itemDets.smallUnitCount > 0 && !itemDets.unitRestrictions;
    return check ? (
      stockManagement.productWholeSalePrice+"/per "+itemDets.unitName + " or " +stockManagement.sproductWholeSalePrice+"/per "+itemDets.smallUnitName)
      : (stockManagement.productWholeSalePrice+"/per "+itemDets.unitName);
  };
  
  const columns = [
    { field: 'stockRefNo', headerName: 'Stock Ref No', width: 200 ,
    renderCell: (val) =>  (
      <Tooltip title={val.row.stockRefNo} >
       <span className="table-cell-trucate">{val.row.stockRefNo}</span>
       </Tooltip>
     ),},
    { field: 'billNo', headerName: 'Bill No', width: 140 },
   
    {
      field: 'quan', headerName: 'Quantity', width: 200,
      renderCell: (val) => {
        return (getColumnUnit(val.row));
      }
    },
    {
      field: 'expiry', headerName: 'Expired On', width: 140,
      renderCell: (val) => {
        return (val.row.isExpired) ? val.row.expiryDate + "(Expired)" : val.row.expiryDate;
      }
    },
    {
      field: 'outOfStock', headerName: 'Out Of Stock', width: 110,
      renderCell: (val) => {
        return (val.row.outOfStock ? "Yes" : "No");
      }
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <EditIcon
            onClick={(event) => {
              if (isRoleAllowed(STOCKUPDATE)) {
                handleCellClick(event, cellValues);
              } else {
                setSnpComponent({
                  ...snpComponent,
                  isLoader: false,
                  negativeReq: false,
                  positiveReq: true,
                  positiveAction: "Ok Got it",
                  showDialog: true,
                  outSideClickDismiss: true,
                  cancelExtra: true,
                  requestType: 99,
                  subTitle: "You Are not allowed to Add/Update Stock Details.Contact the Owner",
                  handleSnpSubmit: () => handleDialogClose(),
                });
              }
            }}
          >
          </EditIcon>
        );
      }
    },
    {
      field: "del",
      headerName: "Del",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <DeleteIcon style={{ cursor: "pointer" }}
            onClick={(event) => {
              handleCellClick(event, cellValues);
            }}
          >
          </DeleteIcon>
        );
      }
    }
  ];




  const handleCellClick = (param, event) => {
    printDebugLog("setSingleStockData : ", event.row);
    if (event.field === 'edit') {
      setSupplierSingleData({
        ...supplierSingleData,
        supplierId: event.row.supplierId,
        supplierName: event.row.supplierName,
      });

      setSingleStockData({
        ...stockSingleData,
        prdId: event.row.prdId,
        stockRefNo: event.row.stockRefNo,
        billNo: event.row.billNo,
        batchNo: event.row.batchNo,
        barcodeNo: event.row.barcodeNo,
        mfgDate: event.row.mfgDate,
        expiryDate: event.row.expiryDate,
        nearByExpiry: event.row.nearByExpiry,
        isExpired: event.row.isExpired,
        quantity: event.row.quantity,
        sQuantity: event.row.sQuantity,
        notGoodQuality: event.row.notGoodQuality,
        supplierId: event.row.supplierId,
        supplierName: event.row.supplierName,
        sgst: event.row.sgst,
        cgst: event.row.cgst,
        igst: event.row.igst,
        custGstDisabled: event.row.custGstDisabled,
        buyerGstTaken: event.row.buyerGstTaken,
        sbcCessTax: event.row.sbcCessTax,
        status: event.row.status,
        outOfStock: event.row.outOfStock,
        productmrPrice: event.row.productmrPrice,
        offerDenom: event.row.offerDenom,
        offerAmt: event.row.offerAmt,
        productSellingPrice: event.row.productSellingPrice,
        productWholeSalePrice: event.row.productWholeSalePrice,
        purchasePrice: event.row.purchasePrice,
        sproductmrPrice: event.row.sproductmrPrice,
        sofferDenom: event.row.sofferDenom,
        sofferAmt: event.row.sofferAmt,
        sproductSellingPrice: event.row.sproductSellingPrice,
        sproductWholeSalePrice: event.row.sproductWholeSalePrice,
        priorityHighinSell: event.row.priorityHighinSell,
        taxMethod:event.row.taxMethod,
      });
      let mUnitChecked = (event.row.quantity > 0);
      let mSmallUnitChecked = (event.row.sQuantity > 0
        && itemDets.smallUnitCount > 0 && !itemDets.unitRestrictions);
      setChoiceUnit({
        ...choiceUnit,
        smallUnitChecked: mSmallUnitChecked,
        unitChecked: mUnitChecked,
      })
      setShowDialog(true);
      setEditProduct(true);
    } else if (event.field === 'del') {
      deleteStock(event.row.stockRefNo);
    }
  };
  function getPersistUrl() {
    if (storeCartType === CartDets.Pharma)
      return SET_PHARMA_STOCK_DATA;
    else if (storeCartType === CartDets.Kirana)
      return SET_KIRANA_STOCK_DATA;
    else if (storeCartType === CartDets.Pipe)
      return SET_PIPE_STOCK;
    return "";
  }
  const handleSubmitStock = async () => {

    setFormSubmitted(true);
    if (!isValid()) {
      return;
    }


    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + getPersistUrl();

    let filledData = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        prdId: itemDets.prdId,
        stockRefNo: stockSingleData.stockRefNo,
        supplierId: supplierSingleData.supplierId,
        billNo: stockSingleData.billNo,
        barcodeNo: stockSingleData.barcodeNo,
        batchNo: stockSingleData.batchNo,
        mfgDate: stockSingleData.mfgDate,
        expiryDate: stockSingleData.expiryDate,
        isExpired: stockSingleData.isExpired,
        nearByExpiry: stockSingleData.nearByExpiry,
        quantity: parseInt(stockSingleData.quantity),
        sQuantity: parseInt(stockSingleData.sQuantity),
        notGoodQuality: stockSingleData.notGoodQuality,
        sgst: parseFloat(stockSingleData.sgst),
        cgst: parseFloat(stockSingleData.cgst),
        igst: parseFloat(stockSingleData.igst),
        sbcCessTax: parseFloat(stockSingleData.sbcCessTax),
        custGstDisabled: stockSingleData.custGstDisabled,
        buyerGstTaken: stockSingleData.buyerGstTaken,
        outOfStock: stockSingleData.outOfStock,
        productmrPrice: parseFloat(stockSingleData.productmrPrice),
        offerDenom: parseInt(stockSingleData.offerDenom),
        offerAmt: parseFloat(stockSingleData.offerAmt),
        productSellingPrice: parseFloat(stockSingleData.productSellingPrice),
        productWholeSalePrice: parseFloat(stockSingleData.productWholeSalePrice),
        taxMethod:stockSingleData.taxMethod,
        sproductmrPrice: parseFloat(stockSingleData.sproductmrPrice),
        sofferDenom: parseInt(stockSingleData.sofferDenom),
        sofferAmt: parseFloat(stockSingleData.sofferAmt),
        sproductSellingPrice: parseFloat(stockSingleData.sproductSellingPrice),
        sproductWholeSalePrice: parseFloat(stockSingleData.sproductWholeSalePrice),
        purchasePrice:parseFloat(stockSingleData.purchasePrice),
        isEdit: isEditProduct,
        amount: parseFloat(isEditProduct ? 0.0 : account.accAmt),
        partialAmt: parseFloat(isEditProduct ? 0.0 : account.partialAmt),
        paidStatus: parseInt(isEditProduct ? 0 : account.accStatus),
        drCr: isEditProduct ? "" : DEBIT,
        mode: parseInt(isEditProduct ? 0 : AccountingPaidMode.VIASTOCK),
        choiceUnit: parseInt(choiceUnit ? 1 : 2),
      }
    };
    snpAxios.post(catgUrl,filledData)
    .then(response => {
      printDebugLog("stock update responses => ", response.data);
      setShowDialog(false);
      if (response.data.status === SUCCESS) {
        getStockDetails();
      }
    }).catch(error => {
      setShowDialog(false);
      printInfoLog("error : ", error);
      setFormSubmitted(false);
      //window.location.href = "#/error";
    });
  };

  const handleSubmitCustomerPrices = async () => {
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + UPDATE_CUSTOMER_PRICES;
    let filledData = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        prdId: itemDets.prdId,
        productmrPrice: parseFloat(stockManagement.productmrPrice),
        offerDenom: parseInt(stockManagement.offerDenom),
        offerAmt: parseFloat(stockManagement.offerAmt),
        productSellingPrice: parseFloat(stockManagement.productSellingPrice),
        productWholeSalePrice: parseFloat(stockManagement.productWholeSalePrice),
        sproductmrPrice: parseFloat(stockManagement.sproductmrPrice),
        sofferDenom: parseInt(stockManagement.sofferDenom),
        sofferAmt: parseFloat(stockManagement.sofferAmt),
        sproductSellingPrice: parseFloat(stockManagement.sproductSellingPrice),
        sproductWholeSalePrice: parseFloat(stockManagement.sproductWholeSalePrice),
      }
    };
    //console.log("handleSubmitCustomerPrices => ", catgUrl, filledData);
    setDataLoaded(false);
    snpAxios.post(catgUrl,filledData)
    .then(response => {
      //console.log(" handleSubmitCustomerPrices responses => ", response.data);
      setDataLoaded(true);
      if (response.data.status === SUCCESS) {
        getStockDetails();
      } else {
        console.log("No Data display in dialog ", response.data.status);
      }
    }).catch(error => {
      setDataLoaded(true);
      console.log("error : ", error);
      //window.location.href = "#/error";
    });
  };




  const getStockBarcode = async () => {
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + GETSTOCKBARCODE + "/" + CART_INITIAL_STATE.apiToken + "/" + stockSingleData.stockRefNo;
    //console.log("getStockBarcode => ",catgUrl);
    setDataLoaded(false);
    handleDialogClose();
    let data = {
    };
    axios.get(
      catgUrl,
      data
    )
      .then((response) => {
        setDataLoaded(true);
        printDebugLog("response getStockBarcode = > "+stockSingleData.stockRefNo,response.data);
        if (!isEmpty(response.data) && !(response.data === NODATA || response.data === ACCESSDENIED
          || response.data === SESSIONEXPIRED || response.data === FAILED)) {
          const url = storeApiUrl.BACKEND_BASE_URL + "files/" + response.data;
          window.open(url, '_blank');
        } else {
          printInfoLog("response getStockBarcode => " + response.data);
        }
      }).catch((error) => {
        printInfoLog("getStockBarcode ", error);
        setDataLoaded(true);
      });
  };

  const setStockPriority = async () => {
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + STOCK_PRIORITY;
    //console.log("setStockPriority => ",catgUrl);
    setDataLoaded(false);
    handleDialogClose();
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        value_1: stockSingleData.stockRefNo,
      }
    };
    snpAxios.post(catgUrl,data)
      .then((response) => {
        printDebugLog("setStockPriority response=> ",response.data);
        setDataLoaded(true);
        if (response.data.status === SUCCESS) {          
          getStockDetails();
        }
      }).catch((error) => {
        printInfoLog("setStockPriority ", error);
        setDataLoaded(true);
      });
  };

  const handleDelete = (stockRefNo) => {
    var array = [...stockData];
    console.log("handleDelete -> ", array);
    var index = array.findIndex(singleValue => singleValue.stockRefNo === stockRefNo);
    console.log("handleDelete -> ", index);
    if (index !== -1) {
      array.splice(index, 1);
      setStockData(array);
    }
    handleDialogClose();
    getStockDetails();
  };


  const deleteStock = async (stockRefNo) => {
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + DELETESTOCK + "/" + CART_INITIAL_STATE.apiToken + "/" + stockRefNo+"/"+storeActiveShopHash.id;
    //console.log("deleteStock => ",catgUrl);
    setDataLoaded(false);
    let data = {
    };
    axios.get(
      catgUrl,
      data
    )
      .then((response) => {
        setDataLoaded(true);
        printDebugLog("response deleteStock = > "+stockSingleData.stockRefNo,response.data);
        if (!isEmpty(response.data) && response.data === SUCCESS) {
          setSnpComponent({
            ...snpComponent,
            snackBarOpen: true,
            snackBarMsg: "Successfully Deleted ",
          });
          handleDelete(stockRefNo);
        } else {
          printInfoLog("response deleteStock => " + response.data);
          setSnpComponent({
            ...snpComponent,
            snackBarOpen: true,
            snackBarMsg: response.data,
          });
        }
      }).catch((error) => {
        printInfoLog("deleteStock ", error);
        setDataLoaded(true);
      });
  };
 
  const getTaxTag = () => {
     return stockSingleData.taxMethod ? "Inc. GST" : "Exl. GST";
  };

  const renderUnitPrice = () => {
    return (
      <>
        {
          choiceUnit.unitChecked && <div>
            <Paper
              elevation={0}
              autoHeight
              sx={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#f6f6f6",
                boxShadow: 1,
                borderRadius: 1,
                mb:2,
                padding:1
              }}
            >
              <div>
                <Grid md={12} xs={12} sm={12} lg={12}>
                  <center>
                    <p ><u>{"Enter Price Details per " + itemDets.unitName}</u> </p></center>
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <Grid container spacing={1}>
                    <Grid item md={6} xs={12} sm={6} lg={6}>
                      <TextField
                        id="productWholeSalePrice"
                        name="productWholeSalePrice"
                        variant="filled"
                        size="small"
                        label={"WholeSale Price(Exl. GST)/per " + itemDets.unitName}
                        value={stockSingleData.productWholeSalePrice}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={6} xs={12} sm={6} lg={6}>
                      <TextField
                        id="productmrPrice"
                        name="productmrPrice"
                        variant="filled"
                        size="small"
                        label={"Enter MRP("+getTaxTag()+")/per " + itemDets.unitName}
                        value={stockSingleData.productmrPrice}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Any Offer : </FormLabel>
                    <RadioGroup row aria-label="offerDenom" name="offerDenom" value={stockSingleData.offerDenom.toString()} onChange={handleInputChange}>
                      <FormControlLabel value={OfferType.Flat.toString()} control={<Radio />} label="Discount In Flat" />
                      <FormControlLabel value={OfferType.Percentage.toString()} control={<Radio />} label="Discount In Percentage" />
                      <FormControlLabel value={OfferType.No_Offer.toString()} control={<Radio />} label="No Offer" />
                    </RadioGroup>
                  </FormControl>
                  {
                    (stockSingleData.offerDenom > 1) &&
                    <Grid item md={12} xs={12} sm={12} lg={12}>
                      <TextField
                        id="offerAmt"
                        name="offerAmt"
                        variant="filled"
                        size="small"
                        label="Enter Offer Value"
                        value={stockSingleData.offerAmt.toString()}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </Grid>
                  }
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <GreenTextTypographyBold variant="p">
                    {"Selling Price("+getTaxTag()+") : "}
                  </GreenTextTypographyBold>
                  <CustomTextTypography variant="p">
                    {stockSingleData.productSellingPrice + "/per " + itemDets.unitName}
                  </CustomTextTypography> </Grid>
              </div>
            </Paper>
          </div>
        }
      </>
    )
  };

  const renderSmallUnitPrice = () => {
    return (
      <>
        {
          choiceUnit.smallUnitChecked && <div>
            <Paper
              elevation={0}
              autoHeight
              sx={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#f6f6f6",
                boxShadow: 1,
                borderRadius: 1,
                mb:2,
                padding:1
              }}
            >
              <div>
              <Grid item md={12} xs={12} sm={12} lg={12}>
                  <Grid md={12} xs={12} sm={12} lg={12}>
                    <center>
                      <p ><u>{"Enter Price Details per " + itemDets.smallUnitName}</u> </p></center>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item md={6} xs={12} sm={6} lg={6}>
                      <TextField
                        id="sproductWholeSalePrice"
                        name="sproductWholeSalePrice"
                        variant="filled"
                        size="small"
                        label={"WholeSale Price(Exl. GST)/per " + itemDets.smallUnitName}
                        value={stockSingleData.sproductWholeSalePrice}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={6} xs={12} sm={6} lg={6}>
                      <TextField
                        id="sproductmrPrice"
                        name="sproductmrPrice"
                        variant="filled"
                        size="small"
                        label={"Enter MRP("+getTaxTag()+")/per " + itemDets.smallUnitName}
                        value={stockSingleData.sproductmrPrice}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>


                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Any Offer : </FormLabel>
                    <RadioGroup row aria-label="sofferDenom" name="sofferDenom" value={stockSingleData.sofferDenom.toString()} onChange={handleInputChange}>
                      <FormControlLabel value={OfferType.Flat.toString()} control={<Radio />} label="Discount In Flat" />
                      <FormControlLabel value={OfferType.Percentage.toString()} control={<Radio />} label="Discount In Percentage" />
                      <FormControlLabel value={OfferType.No_Offer.toString()} control={<Radio />} label="No Offer" />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                {
                  (stockSingleData.sofferDenom > 1) &&


                    <Grid item md={12} xs={12} sm={12} lg={12}>
                      <TextField
                        id="sofferAmt"
                        name="sofferAmt"
                        variant="filled"
                        size="small"
                        label="Enter Offer Value"
                        value={stockSingleData.sofferAmt.toString()}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </Grid>
                }

                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <GreenTextTypographyBold variant="p">
                    {"Selling Price("+getTaxTag()+") : "}
                  </GreenTextTypographyBold>
                  <CustomTextTypography variant="p">
                    {stockSingleData.sproductSellingPrice + "/per " + itemDets.smallUnitName}
                  </CustomTextTypography> </Grid>
              </div>
            </Paper>
          </div>
        }
      </>
    )
  };

  const renderDialogContent = () => {
    return (
      <>
        <div>
          <div style={{ textAlign: "center" }}>{getProductDetailsUI(false)}</div>
          <br />
          <Grid container spacing={1}>
            {
              stockSingleData.isExpired && <Grid item xs={12}>
                <FormControlLabel disabled control={<Checkbox checked={stockSingleData.isExpired}
                  onChange={handleInputChange} id="isExpired" />} name="isExpired" label="Is Expired" />
              </Grid>
            }
            
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <TextField
                required
                id="billNo"
                name="billNo"
                disabled={isEditProduct}
                variant="filled"
                size="small"
                label="Enter Bill Number"
                value={stockSingleData.billNo}
                onChange={handleInputChange}
                fullWidth
                error={formSubmitted && !stockSingleData.billNo}
                helperText={getErrorMessage('billNo')} />
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} sm={6} lg={6}>
                  <TextField
                    id="batchNo"
                    disabled={isEditProduct}
                    name="batchNo"
                    variant="filled"
                    size="small"
                    label="Enter Batch Number"
                    value={stockSingleData.batchNo}
                    onChange={handleInputChange}
                    fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6} sm={6} lg={6}>
                  <TextField
                    id="barcodeNo"
                    name="barcodeNo"
                    disabled={isEditProduct}
                    variant="filled"
                    size="small"
                    label="Enter Bar Code Number"
                    value={stockSingleData.barcodeNo}
                    onChange={handleInputChange}
                    fullWidth
                    />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <Autocomplete
                required
                id="supplier-select"
                disabled={isEditProduct}
                options={supplierData}
                autoHighlight
                variant="filled"
                size="small"
                onChange={handleSupplierChange}
                fullWidth
                getOptionLabel={(option) => option.supplierName}
                defaultValue={supplierSingleData}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                  >
                    {option.supplierName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Supplier Information"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    error={formSubmitted && !stockSingleData.supplierId}
                    helperText={getErrorMessage('supplier-select')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} sm={6} lg={6}>
                  <TextField
                    id="mfgDate"
                    name="mfgDate"
                    variant="filled"
                    size="small"
                    label="Enter Manufacture Date"
                    value={stockSingleData.mfgDate}
                    onChange={handleInputChange}
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }} />
                </Grid>
                <Grid item xs={12} md={6} sm={6} lg={6}>
                  <TextField
                    id="expiryDate"
                    name="expiryDate"
                    variant="filled"
                    label="Enter Expiry Date"
                    size="small"
                    value={stockSingleData.expiryDate}
                    onChange={handleInputChange}
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} sm={12} lg={12}>
            <FormLabel component="legend">{"Enter Quantity: in " + getUnitDetails()}</FormLabel>
                <FormControlLabel row control={<Checkbox checked={choiceUnit.unitChecked}
                  onChange={handleInputChange} id="unitChecked" />} name="unitChecked" label={itemDets.unitName} />
                {
                  itemDets.smallUnitCount > 0 && <FormControlLabel disabled={itemDets.unitRestrictions} control={<Checkbox checked={choiceUnit.smallUnitChecked}
                    onChange={handleInputChange} id="smallUnitChecked" />} name="smallUnitChecked" label={itemDets.smallUnitName} />
                }
            </Grid>
            {
              choiceUnit.unitChecked &&
              <Grid item xs={12} md={6} sm={6} lg={6}>
                <TextField
                  id="quantity"
                  name="quantity"
                  variant="filled"
                  size="small"
                  label={"Enter Quantity(for " + itemDets.unitName + ")"}
                  value={stockSingleData.quantity}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            }
            {
              choiceUnit.smallUnitChecked &&
              <Grid item xs={12} md={6} sm={6} lg={6}>
                <TextField
                  id="sQuantity"
                  name="sQuantity"
                  variant="filled"
                  size="small"
                  label={"Enter Quantity(for " + itemDets.smallUnitName + ")"}
                  value={stockSingleData.sQuantity}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            }
            {
              (choiceUnit.unitChecked || choiceUnit.smallUnitChecked) && <Grid item xs={12} md={6} sm={6} lg={6}>
                <FormLabel component="legend">Tax Method(Only For MRP) : </FormLabel>
                  <RadioGroup row aria-label="taxMethod" name="taxMethod" value={stockSingleData.taxMethod} onChange={handleInputChange}>
                    <FormControlLabel value={false} control={<Radio />} label="Without GST" />
                    <FormControlLabel value={true} control={<Radio />} label="With GST" />
                  </RadioGroup>
              </Grid>
            }
            <Grid item xs={12} md={6} sm={6} lg={6}>
              {renderUnitPrice()}
            </Grid>
            <Grid item xs={12} md={6} sm={6} lg={6}>
              {renderSmallUnitPrice()}
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} sm={6} lg={6}>
                  <TextField
                    id="sgst"
                    name="sgst"
                    variant="filled"
                    size="small"
                    label="Enter SGST(In %)"
                    value={stockSingleData.sgst}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6} lg={6}>
                  <TextField
                    id="cgst"
                    name="cgst"
                    variant="filled"
                    size="small"
                    label="Enter CGST(In %)"
                    value={stockSingleData.cgst}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} sm={6} lg={6}>
              <TextField
                id="sbcCessTax"
                name="sbcCessTax"
                variant="filled"
                size="small"
                label="Enter SbcCess Tax(In %)"
                value={stockSingleData.sbcCessTax}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            
            <Grid item xs={12} md={6} sm={6} lg={6}>
              <TextField
                required
                id="purchasePrice"
                name="purchasePrice"
                variant="filled"
                size="small"
                label="Enter Purchase Price"
                value={stockSingleData.purchasePrice}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12} style={{ marginTop: "-10px" }}>
              <FormControlLabel control={<Checkbox checked={stockSingleData.custGstDisabled}
                onChange={handleInputChange} id="custGstDisabled" />} name="custGstDisabled" label="Do you want to enable GST for Customer?" />
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12} style={{ marginTop: "-20px" }}>
              <FormControlLabel control={<Checkbox checked={stockSingleData.buyerGstTaken}
                onChange={handleInputChange} id="buyerGstTaken" />} name="buyerGstTaken" label="Have you taken GST from Supplier" />
            </Grid>
            
            <Grid item xs={12} md={12} sm={12} lg={12}>
              {
                stockSingleData.outOfStock ?
                  <CustomTextTypography variant="h3">
                    **Out Of Stock**
                  </CustomTextTypography> : null
              }
            </Grid>
            <Grid item xs={6} md={6} sm={6} lg={6}>
              {
                isEditProduct &&
                <Button variant="contained" color="primary" onClick={getStockBarcode}>
                  Generate BarCode
                </Button>
              }
            </Grid>
            <Grid item xs={6} md={6} sm={6} lg={6}>
              {
                isEditProduct &&
                <Button variant="contained" color="bRed" onClick={setStockPriority}>
                  {stockSingleData.priorityHighinSell ? "Remove Priority" : "Make First Priority"}
                </Button>
              }
            </Grid>
            <br/><br/>
            <Grid item xs={6} md={6} sm={6} lg={6}>
              {
                isEditProduct &&  <Button variant="contained" color="bRed">
                Not Good Quality
              </Button>
              }
            </Grid>
          </Grid>
        </div>
      </ >
    );
  }

  const renderDialogAction = () => {
    return (
      <>
        <Button variant="contained" color="primary" onClick={handleSubmitStock}>
          {isEditProduct ? "Update" : "Submit"}
        </Button>
        <Button variant="contained" color="primary" onClick={handleDialogClose}>
          Cancel
        </Button>
      </>
    );
  }

  const handleDialogClose = () => {
    // console.log("handleDialogClose");
    setShowDialog(false);
    clearstockData();
    setEditProduct(false);
    setFormSubmitted(false);
    setChoiceUnit({
      ...choiceUnit,
      unitChecked: false,
      smallUnitChecked: false,
    });
  };


  const getErrorMessage = (field) => {
    if (!formSubmitted) {
      return;
    } else if (!stockSingleData[field]) {
      return 'Field is required';
    }
  }

  const isValid = () => {
    let isStockDataValid = true;

    if (!stockSingleData.billNo || !stockSingleData.supplierId) {
      isStockDataValid = false;
    }

    return isStockDataValid;
  }

  const getLiveStockDetails = () => {
    if (stockManagement.bigTotalCount <= 0 && stockManagement.smallTotalCount <= 0) {
      return "No Stock Found! Add New Stock";
    }
    let check = itemDets.smallUnitCount > 0 && !itemDets.unitRestrictions;
    return check ? (stockManagement.bigTotalCount + " " + itemDets.unitName + " or " + stockManagement.smallTotalCount + " " + itemDets.smallUnitName)
      : (stockManagement.bigTotalCount + " " + itemDets.unitName);
  };

  const getUnitDetails = () => {
    let check = itemDets.smallUnitCount > 0 && !itemDets.unitRestrictions;
    return check ? (itemDets.unitName + "=" + itemDets.smallUnitCount + " " + itemDets.smallUnitName + "(Loose)")
      : itemDets.unitName;
  };

  const [hideCustomerPrice, setHideCustomerPrice] = useState(true);

  const handleHideChange = () => {
    setHideCustomerPrice(!hideCustomerPrice);
  };

  const getProductDetailsUI = (outside) => {
      return (
         <>
          <BlueTextTypography variant="p" fontWeight="bold">
            {"Item Name : "}
          </BlueTextTypography>
          <CustomTextTypography variant="p" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
            {itemDets.prd}
          </CustomTextTypography>
          <br />
          <BlueTextTypography variant="p" fontWeight="bold">
            {"HSN Code : "}
          </BlueTextTypography>
          <CustomTextTypography variant="p" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
            {itemDets.hsnCode}
          </CustomTextTypography>
          <br />
          <BlueTextTypography variant="p">
            {"Cateogory : "}
          </BlueTextTypography>
          <CustomTextTypography variant="p" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
            {itemDets.catgName}
          </CustomTextTypography>
          <br />
          {
            itemDets.composition.length > 0 && (
              <div>
                <BlueTextTypography variant="p">
                  {"Sub-Cateogory : "}
                </BlueTextTypography>
                <CustomTextTypography variant="p" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                  {itemDets.composition.join()}
                </CustomTextTypography>
                <br />
              </div>
            )
          }
          
          {
            outside ? <div>
              <BlueTextTypography variant="p">
                {"Live Stock Count : "}
              </BlueTextTypography>
              <CustomTextTypography variant="p" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                {getLiveStockDetails()}
              </CustomTextTypography>
              <br />
              <BlueTextTypography variant="p">
                {"WholeSale Price(exl. GST): "}
              </BlueTextTypography>
              <CustomTextTypography variant="p" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                {getWholeSalePrice()}
              </CustomTextTypography>
             </div> : <div>
             <BlueTextTypography variant="p">
                {"Unit Details : "}
              </BlueTextTypography>
              <CustomTextTypography variant="p" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                {getUnitDetails()}
              </CustomTextTypography>
             </div>
          }
         </>
      )
  };

  const renderSnpComponent = () => {
    return (
      <>
        <div>
          {
            isDataLoaded ?
              <div>
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "background.paper",
                    boxShadow: 9,
                    borderRadius: 4,
                    padding: 2,
                  }}
                >
                  <Grid container spacing={1}>
                    {
                      itemDets.productImage &&
                      <Grid item xs={12} sm={3} md={2} lg={2}>
                        <img  
                          alt=""
                          style={{border:"1",borderColor:"primary", height: "80px", width: "80px", borderRadius: "100%" }}
                          src={storeApiUrl.BACKEND_BASE_URL + "files/" + itemDets.productImage}
                        />
                      </Grid>
                    }
  
                    <Grid item xs={12} sm={12} md={10} lg={10}>
                      {getProductDetailsUI(true)}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<HideSourceIcon />}
                        onClick={handleHideChange}
                      >
                        {(hideCustomerPrice ? "Hide" : "UnHide") + " Customer Prices"}
                      </Button>
                    </Grid>

                    {(hideCustomerPrice && stockData.length > 0) && 
                    <Grid xs={12} md={12} sm={12}>
                      <center>
                      <Typography variant="body1" color="#8b0000">
                        **Note: These prices will change again if you create/update changes while Stock Entry
                      </Typography>
                      </center>
                    </Grid>}
  
                    {(hideCustomerPrice && stockData.length > 0) && 
                      <Grid item xs={12} md={6} sm={6}>
                        <TextField
                          required
                          id="itemdetsproductmrPrice"
                          name="itemdetsproductmrPrice"
                          variant="filled"
                          size="small"
                          label={"MRP/Customer Price (Excl. GST) for " + itemDets.unitName}
                          value={stockManagement.productmrPrice}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                    }
                    {(hideCustomerPrice && stockManagement.sproductSellingPrice > 0 && stockData.length > 0) && 
                      <Grid item xs={12} md={6} sm={6}>
                        <TextField
                          required
                          id="sitemdetsproductmrPrice"
                          name="sitemdetsproductmrPrice"
                          variant="filled"
                          size="small"
                          disabled={itemDets.unitRestrictions}
                          label={"MRP/Customer Price (Excl. GST) for " + itemDets.smallUnitName}
                          value={stockManagement.sproductmrPrice}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                    }
  
                    {(hideCustomerPrice && stockData.length > 0) && 
                      <Grid item xs={12} md={6} sm={6}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">{"Select Offer for " + itemDets.unitName}</FormLabel>
                          <RadioGroup row aria-label="itemdetsofferDenom" name="itemdetsofferDenom" value={stockManagement.offerDenom.toString()} onChange={handleInputChange}>
                            <FormControlLabel value={OfferType.Flat.toString()} control={<Radio />} label="Discount In Flat" />
                            <FormControlLabel value={OfferType.Percentage.toString()} control={<Radio />} label="Discount In Percentage" />
                            <FormControlLabel value={OfferType.No_Offer.toString()} control={<Radio />} label="No Offer" />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    }
                    {(hideCustomerPrice && stockData.length > 0 && stockManagement.sproductSellingPrice > 0) && 
                      <Grid item xs={12} md={6} sm={6}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">{"Select Offer for " + itemDets.smallUnitName}</FormLabel>
                          <RadioGroup row aria-label="sitemdetsofferDenom" name="sitemdetsofferDenom" value={stockManagement.sofferDenom.toString()} onChange={handleInputChange}>
                            <FormControlLabel value={OfferType.Flat.toString()} control={<Radio />} label="Discount In Flat" />
                            <FormControlLabel value={OfferType.Percentage.toString()} control={<Radio />} label="Discount In Percentage" />
                            <FormControlLabel value={OfferType.No_Offer.toString()} control={<Radio />} label="No Offer" />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    }
                    {(hideCustomerPrice && stockData.length > 0 && stockManagement.offerDenom > 1) && 
                      <Grid item xs={12} md={6} sm={6}>
                        <TextField
                          required
                          id="itemdetsofferAmt"
                          name="itemdetsofferAmt"
                          variant="filled"
                          size="small"
                          label={"Offer Value For " + itemDets.unitName}
                          value={stockManagement.offerAmt.toString()}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                    }
                    {(hideCustomerPrice && stockData.length > 0 && stockManagement.sofferDenom > 1) && 
                      <Grid item xs={12} md={6} sm={6}>
                        <TextField
                          required
                          id="sitemdetsofferAmt"
                          name="sitemdetsofferAmt"
                          variant="filled"
                          size="small"
                          label={"Offer Value For " + itemDets.smallUnitName}
                          value={stockManagement.sofferAmt.toString()}
                          onChange={handleInputChange}
                          fullWidth
                        />
                      </Grid>
                    }
                    {(hideCustomerPrice && stockData.length > 0) && 
                      <Grid item xs={12} md={6} sm={6}>
                        <BlueTextTypography variant="body1">
                          {"Selling Price (per " + itemDets.unitName + ") : "}
                        </BlueTextTypography>
                        <CustomTextTypography variant="body1">
                          {stockManagement.productSellingPrice}
                        </CustomTextTypography>
                      </Grid>
                    }
                    {(hideCustomerPrice && stockManagement.sproductSellingPrice > 0) && 
                      <Grid item xs={12} md={6} lg={6} sm={6}>
                        <BlueTextTypography variant="body1">
                          {"Selling Price (per " + itemDets.smallUnitName + ") : "}
                        </BlueTextTypography>
                        <CustomTextTypography variant="body1">
                          {stockManagement.sproductSellingPrice}
                        </CustomTextTypography>
                      </Grid>
                    }
                    {(hideCustomerPrice && stockData.length > 0) &&
                      <Grid item xs={12} md={3} sm={6} lg={3}>
                        <Button variant="contained" color="primary"
                          onClick={(event) => {
                            if (isRoleAllowed(INVENTORYUPDATEPRICING)) {
                              handleSubmitCustomerPrices();
                            } else {
                              setSnpComponent({
                                ...snpComponent,
                                isLoader: false,
                                negativeReq: false,
                                positiveReq: true,
                                positiveAction: "Ok Got it",
                                showDialog: true,
                                outSideClickDismiss: true,
                                cancelExtra: true,
                                requestType: 99,
                                subTitle: "You Are not allowed to Add/Update Stock Details.Contact the Owner",
                                handleSnpSubmit: () => handleDialogClose(),
                              });
                            }
                          }}>
                          Update New Prices
                        </Button>
                      </Grid>}
                    <Grid item xs={12} md={3} sm={6}>
                      <Button variant="contained" color="primary"
                        onClick={(event) => {
                          if (isRoleAllowed(STOCKUPDATE)) {
                            setShowDialog(true);
                          } else {
                            setSnpComponent({
                              ...snpComponent,
                              isLoader: false,
                              negativeReq: false,
                              positiveReq: true,
                              positiveAction: "Ok Got it",
                              showDialog: true,
                              outSideClickDismiss: true,
                              cancelExtra: true,
                              requestType: 99,
                              subTitle: "You Are not allowed to Add/Update Stock Details.Contact the Owner",
                              handleSnpSubmit: () => handleDialogClose(),
                            });
                          }
                        }}>
                        Create New Stock Entry
                      </Button>
                    </Grid>
                    
                  </Grid>
                </Paper>

  
                <DialogBox
                  showDialog={showDialog}
                  title={isEditProduct ? "Update Stock" : "Create New Stock Entry"}
                  onClose={handleDialogClose}
                  dialogContent={renderDialogContent()}
                  dialogActions={renderDialogAction()}
                />
  
                <CustomDataGrid
                  getRowId={row => row.stockRefNo}
                  rows={stockData}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                />
              </div>
              : <Loader />
          }
        </div>
      </>
    );
  }
  

  const handleSnackClose = () => {
    setSnpComponent({
        ...snpComponent,
        snackBarOpen: false,
        snackBarMsg: "",
    });
};

return (
    <>
      <SnpComponent showDialog={snpComponent.showDialog}
        title={snpComponent.title}
        subTitle={snpComponent.subTitle}
        negativeAction={snpComponent.negativeAction}
        positiveReq={snpComponent.positiveReq}
        positiveAction={snpComponent.positiveAction}
        negativeReq={snpComponent.negativeReq}
        handleDialogClose={() => setSnpComponent(INITIAL_SNP_COMPONENT)}
        iconReq={snpComponent.iconReq}
        iconContent={snpComponent.iconContent}
        content={renderSnpComponent()}
        onSnackClose={handleSnackClose}
        cancelExtra={snpComponent.cancelExtra}
        onCancel={snpComponent.handleSnpCancel}
        snackBarOpen={snpComponent.snackBarOpen}
        snackBarMsg={snpComponent.snackBarMsg}
        outSideClickDismiss={snpComponent.outSideClickDismiss}
        handleSubmit={snpComponent.handleSnpSubmit}
        gui={snpComponent.gui}
      />
    </>
  );
}