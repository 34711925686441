import React, { useEffect, useState } from 'react';
import SnpComponent from '../global/SnpComponent';
import { Grid, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { isEmpty, printDebugLog, printInfoLog, SUCCESS } from '../../constants/constant';
import utilities from '../../utilities/utilities';
import { useLocation } from 'react-router-dom';


export default function CustomerBillSection() {
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const location = useLocation();
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        iconReq: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
        handleSnpSubmit: null,
        handleSnpCancel: null,
        gui: "",
        snackColor: '',
        snackVertical: '',
        snackHorizontal: '',
    };

    const INITIAL_SHOP_INFO = {
        shopName: "",
        location: "",
        gstDetail: "",
        companyName: "",
        companyWebAddress: "",
        companyMail: "",
    };

    const [pdfContent, setPdfContent] = useState("");
    const [shopInfo, setShopInfo] = useState(INITIAL_SHOP_INFO);
    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const [isLoader, setLoader] = useState(false);
    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };

    const printCustomerBill = (billNo, shopHash) => {
        setLoader(true);
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + "billing/gen-cust-bill/" + billNo + "/" + shopHash;
        let data = {};
        axios.get(
            catgUrl,
            data
        )
            .then((response) => {
                printDebugLog(response.data);
                setLoader(false);
                if (response.data !== '')
                    setPdfContent(response.data);
            }).catch((error) => {
                printInfoLog("error : ", error);
                setLoader(false);
            });
    };

    const getShopInfo = (shopHash) => {
        shopHash = utilities.encode(shopHash);
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + "resto/get-shop-info?shopHash=" + shopHash;
        let data = {};
        axios.get(
            catgUrl,
            data
        )
            .then((response) => {
                printDebugLog(response.data);
                if (response.data.status === SUCCESS) {
                    setShopInfo({
                        ...shopInfo, ...response.data.jcJson,
                    })
                }
            }).catch((error) => {
                printInfoLog("error : ", error);

            });
    }

    useEffect(() => {
        //setPdfContent("https://ecartdev.snpnextgen.com:8083/files/pdf/JCA-SYik50/Bill-0O-498.pdf");
        printInfoLog("CustomerBillSection: useEffect");
        const queryParams = new URLSearchParams(location.search);
        const shopHash = queryParams.get('s');
        const billNo = queryParams.get('q');
        if (!isEmpty(shopHash))
            getShopInfo(shopHash);
        printCustomerBill(billNo, shopHash);
    }, []);

    const renderShopDetails = () => {
        return (
            <Stack spacing={1}>
                <Typography variant="h4" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', color: '#e4f3f7' }}>
                    {shopInfo.shopName}
                </Typography>
                {
                    !isEmpty(shopInfo.gstDetail)
                    && <Typography variant="h6" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', color: '#f0f4f5' }}>
                        {"GSTIN: "+shopInfo.gstDetail}
                    </Typography>
                }
                {
                    !isEmpty(shopInfo.companyName) && !isEmpty(shopInfo.companyMail)
                    && <Typography variant="p" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', color: '#f0f4f5' }}>
                        {"Company Name: " + shopInfo.companyName + "(@: " + shopInfo.companyMail}
                    </Typography>
                }

                {
                    !isEmpty(shopInfo.companyWebAddress)
                    && <Typography variant="p" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', color: '#f0f4f5' }}>
                        {"Web: " + shopInfo.companyWebAddress}
                    </Typography>
                }

                {
                    !isEmpty(shopInfo.location)
                    && <Typography variant="p" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', color: '#f0f4f5' }}>
                        {"Address: " + shopInfo.location}
                    </Typography>
                }
                <Typography
                    variant="body2"
                    style={{
                        whiteSpace: 'pre-wrap',
                        overflowWrap: 'break-word',
                        color: '#f0f4f5',
                        fontSize: '12px',
                    }}
                >
                    <em>
                        powered By{' '}
                        <a
                            href="https://snpnextgen.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#f0f4f5', textDecoration: 'underline' }}
                        >
                            A2z Unit SNP Pvt. Ltd
                        </a>
                        {' '}ISO 9001-2008 certified.
                    </em>
                </Typography>


            </Stack>
        );
    }

    const renderSnpContent = () => {
        return (
            <div>
                <Grid container spacing={1}>
                    <Grid item md={0} 
                        lg={0}  sm={12} xs={12}  sx={{ display: { md: 'none', lg: 'none', sm: 'block', xs: 'block' } }}>
                        {renderShopDetails()}
                    </Grid>
                    <Grid item md={6} xs={12} sm={12} lg={6}>
                        {
                            !isEmpty(pdfContent) ?
                                <iframe
                                    src={storeApiUrl.BACKEND_BASE_URL + "files/" + pdfContent}
                                    title="Open Bills"
                                    style={{ width: '75%', height: '400%', border: 'none' }}
                                ></iframe>
                                :
                                <div>
                                    <Typography variant="h1" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', color: '#1b9ab5' }}>
                                        The above link is invalid or expired
                                    </Typography>
                                </div>
                        }
                    </Grid>
                    <Grid item md={6} 
                        lg={6}  sm={0} xs={0}  sx={{ display: { md: 'block', lg: 'block', sm: 'none', xs: 'none' } }}>
                        {renderShopDetails()}
                    </Grid>
                </Grid>
            </div>
        );
    };

    return (
        <>
            <SnpComponent
                content={renderSnpContent()}
                showDialog={snpComponent.showDialog}
                isLoader={isLoader}
                title={snpComponent.title}
                subTitle={snpComponent.subTitle}
                negativeAction={snpComponent.negativeAction}
                positiveReq={snpComponent.positiveReq}
                positiveAction={snpComponent.positiveAction}
                negativeReq={snpComponent.negativeReq}
                handleDialogClose={() => setSnpComponent(INITIAL_SNP_COMPONENT)}
                iconReq={snpComponent.iconReq}
                onSnackClose={handleSnackClose}
                cancelExtra={snpComponent.cancelExtra}
                onCancel={snpComponent.handleSnpCancel}
                snackBarOpen={snpComponent.snackBarOpen}
                snackBarMsg={snpComponent.snackBarMsg}
                outSideClickDismiss={snpComponent.outSideClickDismiss}
                handleSubmit={snpComponent.handleSnpSubmit}
                gui={snpComponent.gui}
                bgColor='#080808'
                fullWidth={true}
                snackColor={snpComponent.snackColor}
                snackVertical={snpComponent.snackVertical}
                snackHorizontal={snpComponent.snackHorizontal}
            />
        </>
    );
}