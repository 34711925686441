import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, keyframes, styled } from "@mui/material";


const rippleKeyframes = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
`;

  const RippleCircularProgress = styled(CircularProgress)(({ theme }) => ({
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      transform: 'translate(-50%, -50%)',
      animation: `${rippleKeyframes} 1.5s infinite`,
    },
  }));

export default function Loader(props) {


  

  return (
    <Box
      sx={{
        display: "flex",
        "& > * + *": {
          marginLeft: {p:0},
        },
        width: "70px",
        position: "absolute",
        left: "50%",
        top: "50%",
      }}
    >
      <RippleCircularProgress
        color="success"
        size={70}
      />
    </Box>
  );
}
