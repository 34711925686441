import {
    INITIAL_SHOP_HASH
  } from "../constants/constant";
export const initialState = {
    activeShopHash: {INITIAL_SHOP_HASH}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ACTIVE_HASH" : {
            return {
                ...state, 
                activeShopHash : action.activeShopHash
            }
        }
        default : return state;
    }
}

export default reducer;