import { Autocomplete, Box, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomDataGrid from "../global/CustomDataGrid";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { GETQUICKITEMS, isEmpty, isNumberEmpty, OfferType, printDebugLog, printInfoLog, SUCCESS } from "../../constants/constant";
import { useState } from "react";
import axios from "axios";
import { NotificationContainer, NotificationManager } from "react-notifications";
export default function RenderQuickBillContent(props) {

    const {
        listItems = [],
        setListItems = () => { },
        baseUrl = "",
        encodedHash = "",
        isLoader = false,
    } = props;

    const useStyles = makeStyles({
        input: {
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        },
    });
    const [listFoodItems, setListFoodItem] = useState([]);

    const handleSelectItems = (ev, cellData) => {
        setInputValue("");
        printDebugLog("handleSelectItems",ev ,cellData);
        if (cellData != null) {
            let index = listItems.findIndex(singleValue => singleValue.prdId === cellData.id);
            if (parseInt(index) === -1) {
                var billObj = {};
                billObj.cgst = cellData.cgst;
                billObj.prdId = cellData.id;
                billObj.name = cellData.name;
                billObj.sgst = cellData.sgst;
                billObj.price = cellData.price.sAmt;
                billObj.foodQuantityName = cellData.price.name;
                billObj.finalprice = cellData.price.sAmt;
                billObj.note = "";
                billObj.priceIndex = 0;
                billObj.quantity = 1;
                billObj.offerDenom = cellData.offertype;
                billObj.offerAmt = cellData.offerAmt;
                billObj.print = false;
                billObj.combo = (cellData.catgId === 'snp_combo_id');
                setListItems(billObj, true);
                let mBody = cellData.name+' added successfully';
                NotificationManager.info(<p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{mBody}</p>);
            } else {
                let prvCellData = listItems[index];
                handleIncrement(prvCellData);
                let mBody = prvCellData.quantity +" X " + cellData.name+' added successfully';
                NotificationManager.info(<p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{mBody}</p>);
            }
        }
    }


    const updateItems = (cellData, quantity) => {
        let sPrice = 0.0;
        if (cellData.price > 0.0) {
            let offerDenom = isNumberEmpty(cellData.offerDenom) || cellData.offerDenom === Number.MIN_SAFE_INTEGER ? OfferType.No_Offer : Number(cellData.offerDenom);
            if (offerDenom === OfferType.No_Offer) {
                sPrice = cellData.price;
            } else if (offerDenom === OfferType.Flat) {
                sPrice = cellData.price - cellData.offerAmt;
            } else if (offerDenom === OfferType.Percentage) {
                sPrice = cellData.price
                    - ((cellData.price * cellData.offerAmt) / 100);
            }
            //sPrice = sPrice + (((stockSingleData.cgst + stockSingleData.sgst) * sPrice) / 100);
            sPrice = quantity * sPrice;
            let index = listItems.findIndex(singleValue => singleValue.prdId === cellData.prdId);
            listItems[index].finalprice = sPrice;
            listItems[index].quantity = quantity;
            listItems[index].print = false;
            //printDebugLog("handleIncrement end ",updatedCellData);
            setListItems(listItems, false);
        }
    }

    const handleIncrement = (cellData) => {
        printDebugLog("handleIncrement", cellData);
        let value = cellData.quantity;
        if (isNumberEmpty(value))
            value = 0;
        let quantity = value + 1;
        quantity = Number(quantity);
        updateItems(cellData, quantity);
    }

    const handleDecrement = (cellData) => {
        let value = cellData.quantity;
        if (isNumberEmpty(value))
            value = 0;
        let quantity = value - 1;
        if (quantity < 0) //For Decimal Safety
            quantity = 0;
        quantity = Number(quantity);
        updateItems(cellData, quantity);
    }

    const handleColQuantityChange = (cellData, event) => {
        printDebugLog("handleColQuantityChange => ", cellData, event.target.value);
        const value = event.target.value;
        let quantity = parseFloat(value);
        if (isNumberEmpty(quantity)) {
            quantity = 0;
        } else {
            quantity = quantity * 1;
            if (!isEmpty(value) && value.length > 1 && value.charAt(0) === '0')
                event.target.value = quantity;
        }
        quantity = Number(quantity);
        updateItems(cellData, quantity);
    }

    const handleQuickItemsSearch = (event, mode) => {
        const value = event.target.value;
        //console.log("handleQuickItemsSearch",value)
        if (value.length >= 3) {
            fetchQuickItems(value, mode);
        }
    };

    const colQuantityClasses = useStyles();

    const columns = [
        {
            field: 'prddets', headerName: 'Item', flex: 1, minWidth: 190,minHeight:40,
            renderCell: (val) => {
                let data = val.row.name;
                if (val.row.foodQuantityName)
                    data = data + "(" + val.row.foodQuantityName + ")";
                return <Typography style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word',fontSize:"16px"}}>{data}</Typography>;
            }
        },
        {
            field: 'quantity', headerName: 'Qty', flex: 1, minWidth: 180, editable: true,
            renderCell: (val) => {
                return (
                    <Grid container spacing={0}>
                        <Grid item md={12} sm={12} xs={12} lg={12}>
                            <TextField
                                id="colQuantity"
                                className={colQuantityClasses.input}
                                name="colQuantity"
                                type="number"
                                inputMode="numeric"
                                value={Number(val.row.quantity)}
                                variant="standard"
                                size="small"
                                sx={{
                                    justifyContent: "center", alignContent: "center",
                                    '& .MuiInputBase-input': {
                                        textAlign: "center",
                                    },
                                }}
                                onChange={(e) => handleColQuantityChange(val.row, e)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment>
                                            <IconButton>
                                                <AddIcon color="success" sx={{
                                                    fontSize: "2rem",
                                                }} onClick={() => handleIncrement(val.row)} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment>
                                            <IconButton>
                                                <RemoveIcon color="warning" sx={{
                                                    fontSize: "2rem",
                                                }} onClick={() => handleDecrement(val.row)} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                );
            },
        },
        {
            field: 'savedQuantity', headerName: 'P.Qty', flex: 1, minWidth: 70
        },
        {
            field: 'finalPrice', headerName: 'T. Price', flex: 1,
            renderCell: (val) => {
                return "\u20B9 " + val.row.finalprice;
            }
        },
    ]

    const fetchQuickItems = async (regextStr, mode) => {
        let fetchBillUrl = baseUrl + GETQUICKITEMS + "?s=" + encodedHash + "&q=" + regextStr + "&m=" + mode;
        let data = {};
        axios.get(
            fetchBillUrl,
            data
        )
            .then((response) => {
                //console.log("fetchBill   => ", response.data);
                if (response.data.status === SUCCESS) {
                    setListFoodItem(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                console.log("errr ", error);
            });
    };

    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        if (event)
            setInputValue(event.target.value);
    }


    return (
        <Grid container spacing={1} justifyContent={"center"} alignContent={"center"}>
            <NotificationContainer/>
            <Grid item md={5} xs={12} sm={6}>
                <Box sx={{ backgroundColor: 'white' }}>
                    <Autocomplete id="quick-select"
                        options={listFoodItems}
                        autoHighlight
                        variant="filled"
                        fullWidth
                        onChange={handleSelectItems}
                        onInputChange={handleInputChange}
                        onBlur={(e) => setListFoodItem([])}
                        getOptionLabel={(option) => option.price.details}
                        inputValue={inputValue}
                        renderOption={(props, option) => (
                            <Box
                                component="li"
                                {...props}
                            >
                                {option.price.details}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search Item"
                                onChange={(e) => handleQuickItemsSearch(e, 1)}
                                inputProps={{
                                    ...params.inputProps,
                                }}
                            />
                        )}
                    /></Box>
            </Grid>
            {
                
                <Grid item xs={12} md={12} lg={12} sm={12}>
                    <CustomDataGrid
                        getRowId={row => row.prdId}
                        rows={listItems}
                        columns={columns}
                        loading={isLoader}
                    />
                </Grid>
            }
        </Grid>
    )
}