import React from 'react';

function YouTubeVideo(props) {

    const {
        videoId = "",
        onDismiss = () => { },
    } = props;

    const handleDismiss = (event) => {
        onDismiss(event);
    };

    return (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
        <div style={{ position: 'relative', maxWidth: '640px', width:'80%', height: '390px' }}>
            <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${videoId}`}
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="Company Portfolio"
            />
            <button
                onClick={handleDismiss}
                style={{
                    position: 'absolute',
                    bottom: '5px',
                    left: '50%',
                    transform: 'translateX(-10%)',
                    backgroundColor: 'rgba(255, 0, 0, 0.7)', // Red with 70% opacity
                    color: 'white',
                    border: 'none',
                    padding: '5px 10px',
                    borderRadius: '10px',
                    cursor: 'pointer',
                }}
            >
                Close
            </button>
        </div></div>
    );
}

export default YouTubeVideo;