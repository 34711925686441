import { INITIAL_BASE_URL } from "../constants/constant";

export const initialState = {
  MAINURL: {INITIAL_BASE_URL}
}


const reducer = (state = initialState, action) => {
  //console.log("SET_URL_TYPE",action.MAINURL);
  switch (action.type) {
      case "SET_URL_TYPE" : {
          return {
              ...state, 
              MAINURL: action.MAINURL
          }
      }
      default : return state;
  }
}

export default reducer;