// CustomButton.js
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const SnpButton = styled(Button)(({ theme,  mColor = '#ffffff' , mbgcolor = 'primary', mWidth = 0, mHeight = 0,mborderradius = '20px' }) => ({
  backgroundColor: mbgcolor,  // Default white background
  borderRadius: mborderradius,
  color: mColor,             // Primary text color
  ...(mWidth !== 0 && { width: mWidth }),   // Apply width only if it's not 0
  ...(mHeight !== 0 && { height: mHeight }), // Apply height only if it's not 0
  '&:hover': {
    backgroundColor: '#000f12', // Slightly darker on hover
  },
  textTransform: "none",
}));

export default SnpButton;
