import React, { useState } from "react";
import './Ecart.css';

const Carousel = ({ mainUrl , images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Handle dot click
  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="carousel">
      {/* Image display */}
      {/* {console.log("Carousel ",images)} */}
      <div className="carousel-image-container">
        <img
          src={mainUrl + images[currentIndex]}
          alt={`carousel-img-${currentIndex}`}
          className="carousel-image"
        />
      </div>

      {/* Dots */}
      <div className="carousel-dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
