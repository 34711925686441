import React, { useEffect, useState } from "react";
import SnpComponent from "../global/SnpComponent";
import { Autocomplete, Box, Button, Container, FormControlLabel, Grid, Paper, TextField } from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import { ADMINDOWNLOADCONFIGS, ADMINLOGIN, AXIOS_TIMEOUT, SUCCESS, formatDateInReact, isEmpty } from "../../constants/constant";
import DialogBox from "../global/DialogBox";
import axios from "axios";
import utilities from "../../utilities/utilities";
import { useSelector } from "react-redux";

export default function Admin() {
    const todayFormattedDate = new Date().toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
    const [isLoader, setLoader] = useState(false);
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        iconReq: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
    };
    const ADMIN_LOGIN = {
        name: '', userEmail: '', passWord: '', id: '',
    };
    const ADMIN_CONFIGS = {
        packages: [],
        configs: [],
        carts: [],
        onlinePay: [],
    };
    const CART_TYPE = {
        cartNo: 0,
        cartName: "",
    }
    const COMMSHOPHASH = {
        id: "",
        location: "",
        cartType: 0,
        shop: "",
        gstDetail: "",
        selectedConfigs: [],
    };

    const ADMINPAYAUDIT = {
        no: 1,
        amtPaid: 0,
        onlineBillPaidThrough: "",
        onlinePay: 0,
        cashBillPaidThrough: false,
        cashPay: "",
        paidAt: todayFormattedDate,
    }
    const USER_CREATE = {
        phone: "",
        email: "",
        name: "",
        shopName: "",
        shopAddress: "",
        gstDetails: "",
        p_img: "",
        userName: "",
        validity: 0,
        hold: false,
        mapShopConfigs: [],
        packages: [],
        startDate: todayFormattedDate,
        adminComments: "",
        userSpecialRequest: "",
        isDemo: false,
        haveDues: false,
        remainingDues: 0,
        amountFinalized: 0,
    };

    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const [adminLogin, setAdminLogin] = useState(ADMIN_LOGIN);
    const [shopDetails, setShopDetails] = useState(COMMSHOPHASH);
    const [payAudits, setPayAudits] = useState(ADMINPAYAUDIT);
    const [mainCartType, setCartType] = useState(CART_TYPE);
    const [payMenthod, setPayMethod] = useState('');
    const [adminConfisg, setAdminConfigs] = useState(ADMIN_CONFIGS);
    const [userCreate, setUserCreate] = useState(USER_CREATE);
    const [searchPhone, setSearchPhone] = useState('');
    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const [uploadedDocument, setUploadedDocument] = useState([]);
    const [loginDialog, setLoginDialog] = useState(true);
    const [addShopDialog, setAddShopDialog] = useState(false);
    const [payDialog, setPayDialog] = useState(false);

    const handleSnpCancel = () => {
        setSnpComponent(INITIAL_SNP_COMPONENT);
    };

    const handleSnpDialogClose = () => {
        setSnpComponent(INITIAL_SNP_COMPONENT);
    };

    const handleSnpSubmit = () => {
        console.log("handleSnpSubmit", snpComponent.requestType);
    };

    const handleDialogDismiss = () => {
        setAddShopDialog(false);
        setPayDialog(false);
        setShopDetails(COMMSHOPHASH);
        setPayMethod('');
        setCartType(CART_TYPE);
        //console.log("handleDialogDismiss",adminConfisg);
    };

    const handleCartSelection = (event, option) => {
        //console.log("handleCartSelection ",option)
        if (option != null) {
            setCartType({
                ...mainCartType, ...option,
            });
            setShopDetails({
                ...shopDetails, cartType: option.cartNo,
            });

        }
    };

    const handlePayMethod = (event, option) => {
        if (option != null) {
            setPayMethod(option);
            setPayAudits({
                ...payAudits, onlineBillPaidThrough: option,
            })
        }
    };

    const handleInputChange = (event) => {
        //console.log("handleInputChange",event);
        const { name, value } = event.target;
        //console.log("handleInputChange", name, value);
        if (name === 'imgPath') {
            let file = event.target.files[0];
            if (!file) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Invalid File Selected! Please Try Again",
                    snackBarOpen: true,
                })
            } else if (file.size > 5242880) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Invalid File Size Should be less than 5Mb",
                    snackBarOpen: true,
                })
            } else if (!(file.type.includes("image"))) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Please Select Image",
                    snackBarOpen: true,
                })
            } else {
                setUploadedDocument(prevDocuments => [...prevDocuments, file]);
            }
        } else if (name === "userEmail" || name === "passWord") {
            setAdminLogin({
                ...adminLogin,
                [name]: value,
            })
        }
    };

    const getUserPackages = (key) => {
        if (!isEmpty(key)) {
            if (userCreate.packages.length > 0)
                return userCreate.packages.get(key);
            return true;
        }
        return false;
    };

    const getUserConfigs = (key,configs) => {
        if (!isEmpty(key)) {
            if (configs.length > 0)
                return configs.get(key);
            return true;
        }
        return false;
    };

    useEffect(() => {
        console.log("Admin Page");
        if (!isEmpty(adminLogin.id)) {
            setLoginDialog(false);
        }
    }, [storeApiUrl]);

    const handleLogin = () => {
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + ADMINLOGIN;

        let filledData = {
            jcJson: {
                userEmail: adminLogin.userEmail,
                passWord: adminLogin.passWord,
            }
        };
        setLoader(true);
        setLoginDialog(false);
        axios({
            method: "POST",                 //POST_ACTION
            url: catgUrl,
            data: filledData,
            timeout: AXIOS_TIMEOUT
        }).then(response => {

            //console.log("responses => ", response.data);
            if (response.data.status === SUCCESS) {
                setAdminLogin({
                    ...adminLogin, ...response.data.jcJson
                });
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Welcome " + response.data.jcJson.name,
                    snackBarOpen: true,
                })
                getAdminConfis(response.data.jcJson.id);
            } else {
                setLoader(false);
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Invalid Details You Idiot",
                    snackBarOpen: true,
                })
                setLoginDialog(true);
            }
        }).catch(error => {
            setLoader(false);
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Invalid Details You Idiot",
                snackBarOpen: true,
            })
            setLoginDialog(true);
            console.log("error : ", error);
            //window.location.href = "#/error";
        });
    }

    const getAdminConfis = (hash) => {
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + ADMINDOWNLOADCONFIGS;

        let filledData = {
            jcJson: {
                adminHash: utilities.encode(hash),
            }
        };
        setLoader(true);
        axios({
            method: "POST",                 //POST_ACTION
            url: catgUrl,
            data: filledData,
            timeout: AXIOS_TIMEOUT
        }).then(response => {
            setLoader(false);
            //console.log("responses => ", response.data);
            if (response.data.status === SUCCESS) {
                setAdminConfigs({
                    ...adminConfisg, ...response.data.jcJson,
                })
            }
        }).catch(error => {
            setLoader(false);
            console.log("error : ", error);
            //window.location.href = "#/error";
        });
    };


    const renderLoginDialog = () => {
        return (
            <React.Fragment>
                <Grid container spacing={1} justifyContent="center"
                    alignItems="center" sx={{
                        padding: 1,
                    }}>

                    <Grid item md={6} xs={12} sm={6}>
                        <TextField
                            id="userEmail"
                            label="Login Email"
                            variant="filled"
                            size="small"
                            name="userEmail"
                            onChange={handleInputChange}
                            placeholder="Enter Login Name"
                            defaultValue={adminLogin.userEmail}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={6} xs={12} sm={6}>
                        <TextField
                            id="passWord"
                            label="Password"
                            type="password"
                            variant="filled"
                            size="small"
                            name="passWord"
                            onChange={handleInputChange}
                            placeholder="Enter Your Password"
                            defaultValue={adminLogin.passWord}
                            fullWidth
                        />
                    </Grid></Grid>
            </React.Fragment>
        );
    };



    const renderAddShopDialog = () => {
        return (
            <React.Fragment>
                <Grid container spacing={1} justifyContent="center"
                    alignItems="center" sx={{
                        padding: 1,
                    }}>

                    <Grid item md={6} xs={12} sm={6}>
                        <TextField
                            id="shop"
                            label="Shop Name"
                            name="shop"
                            placeholder="Enter Shop Name"
                            defaultValue={shopDetails.shop}
                            fullWidth
                            variant="filled"
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} xs={12} sm={6}>
                        <Autocomplete
                            id="cart-select"
                            options={adminConfisg.carts}
                            autoHighlight
                            variant="filled"
                            size="small"
                            style={{ width: "100%", maxHeight: "20%" }}
                            onChange={handleCartSelection}
                            isOptionEqualToValue={(option, value) => option.cartNo === value.cartNo}
                            getOptionLabel={(option) => option.cartName}
                            defaultValue={mainCartType}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    {...props}
                                >
                                    {option.cartName}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Cart Type"
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} sm={6}>
                        <TextField
                            id="gstDetail"
                            label="GST Details"
                            name="gstDetail"
                            placeholder="Enter GST Details"
                            defaultValue={shopDetails.gstDetail}
                            fullWidth
                            variant="filled"
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} xs={12} sm={6}>
                        <TextField
                            id="location"
                            label="Location"
                            name="location"
                            rows={2}
                            multiline
                            placeholder="Enter Location"
                            defaultValue={shopDetails.location}
                            fullWidth
                            variant="filled"
                            size="small"
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    const renderPayDialog = () => {
        return (
            <React.Fragment>
                <Grid container spacing={1} justifyContent="center"
                    alignItems="center" sx={{
                        padding: 1,
                    }}>
                    <Grid item md={6} xs={12} sm={6}>
                        <TextField
                            disabled
                            id="paidAt"
                            label="Paying Date"
                            name="paidAt"
                            placeholder="Enter Paying Date"
                            defaultValue={payAudits.paidAt}
                            fullWidth
                            variant="filled"
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} xs={12} sm={6}>
                        <TextField
                            id="amtPaid"
                            label="Amount Paid"
                            name="amtPaid"
                            type="number"
                            placeholder="Enter Amount Paid"
                            defaultValue={payAudits.amtPaid}
                            fullWidth
                            variant="filled"
                            size="small"
                        />
                    </Grid>
                    <Grid item md={6} xs={12} sm={6}>
                        <Autocomplete
                            id="cart-select"
                            options={adminConfisg.onlinePay}
                            autoHighlight
                            variant="filled"
                            size="small"
                            style={{ width: "100%", maxHeight: "20%" }}
                            onChange={handlePayMethod}
                            getOptionLabel={(option) => option}
                            defaultValue={payMenthod}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    {...props}
                                >
                                    {option}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Online PayMethod"
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} sm={6}>
                        <TextField
                            id="onlinePay"
                            label="Online Amount"
                            name="onlinePay"
                            type="number"
                            placeholder="Enter Online Amount"
                            defaultValue={payAudits.onlinePay}
                            fullWidth
                            variant="filled"
                            size="small"
                        />
                    </Grid>
                    <Grid item md={4} xs={6} sm={4}>
                        <FormControlLabel name="hold" checked={payAudits.cashBillPaidThrough} control={<CheckBox sx={{
                            color: "#3e0b55",
                            '&.Mui-checked': {
                                color: "#cf9a26",
                            },
                        }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />} label="Cash" />
                    </Grid>
                    <Grid item md={6} xs={12} sm={6}>
                        <TextField
                            id="cashPay"
                            label="Cash Amount"
                            name="cashPay"
                            type="number"
                            placeholder="Enter Cash Amount"
                            defaultValue={payAudits.cashPay}
                            fullWidth
                            variant="filled"
                            size="small"
                        />
                    </Grid>
                    {
                        userCreate.remainingDues === 0 &&
                        <Grid item md={6} xs={12} sm={6}>
                            <TextField
                                disabled
                                id="remainingDues"
                                label="Remaining Dues"
                                name="remainingDues"
                                defaultValue={userCreate.remainingDues}
                                fullWidth
                                variant="filled"
                                size="small"
                            />
                        </Grid>
                    }
                </Grid>
            </React.Fragment>
        );
    };

    const renderDialogAction = () => {
        return (
            <React.Fragment>
                {
                    loginDialog && <Button variant="contained" color="primary" onClick={handleLogin}>
                        Login
                    </Button>
                }
                {
                    addShopDialog && <Button variant="contained" color="primary">
                        Add Shop
                    </Button>
                }
                {
                    payDialog && <Button variant="contained" color="primary">
                        Initiate Pay
                    </Button>
                }
                {
                    (!loginDialog && (addShopDialog || payDialog)) &&
                    <Button variant="contained" color="primary" onClick={handleDialogDismiss}>
                        Dismiss
                    </Button>
                }

            </React.Fragment>
        )
    };

    const renderSnpContent = () => {
        return (
            <Container component="main">
                <div>
                    <DialogBox
                        showDialog={loginDialog}
                        title="Sign In"
                        outsideClickedDismiss={false}
                        // onClose={customerInputDialogClose}
                        dialogContent={renderLoginDialog()}
                        dialogActions={renderDialogAction()} />
                    <DialogBox
                        showDialog={addShopDialog}
                        title="Add Shop"
                        dialogContent={renderAddShopDialog()}
                        dialogActions={renderDialogAction()} />
                    <DialogBox
                        showDialog={payDialog}
                        title="Payment!"
                        dialogContent={renderPayDialog()}
                        dialogActions={renderDialogAction()} />
                </div>
                <Box
                    sx={{
                        my: 1,
                        mx: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <h3 style={{
                        fontFamily: "monospace"
                    }}><u>Admin Portal</u></h3>
                </Box>
                <Grid container spacing={1} display="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                    <Grid item md={6} xs={12} sm={6} component={Paper} elevation={1}>
                        <Grid container>
                            <Grid item md={6} sm={6} xs={12}>
                                <TextField
                                    id="searchPhone"
                                    label="Search Shop By Phone Number"
                                    variant="filled"
                                    size="small"
                                    name="searchPhone"
                                    placeholder="Search Shop By Phone Number"
                                    defaultValue={searchPhone}
                                />
                            </Grid>
                            <Grid item md={6} sm={6} xs={12}>
                                <Button
                                    variant="contained" color="lightBlue">
                                    Search
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} justifyContent="center"
                            alignItems="center" sx={{
                                padding: 1,
                            }}>

                            <Grid item md={6} xs={12} sm={6}>
                                <TextField
                                    id="userName"
                                    label="Name"
                                    variant="filled"
                                    size="small"
                                    name="userName"
                                    placeholder="Enter Your Name"
                                    defaultValue={userCreate.name}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sm={6}>
                                <TextField
                                    id="phone"
                                    label="Phone"
                                    variant="filled"
                                    size="small"
                                    name="phone"
                                    placeholder="Enter Your Phone"
                                    defaultValue={userCreate.phone}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sm={6}>
                                <TextField
                                    id="userEmail"
                                    label="Email"
                                    name="userEmail"
                                    placeholder="Enter Your E-mail Address"
                                    defaultValue={userCreate.email}
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sm={6}>
                                <TextField
                                    id="shopName"
                                    label="Company Name"
                                    name="shopName"
                                    placeholder="Enter Company Name"
                                    defaultValue={userCreate.shopName}
                                    fullWidth
                                    variant="filled"
                                    size="small"

                                />
                            </Grid>
                            <Grid item md={8} xs={12} sm={8}>
                                <TextField
                                    id="shopAddress"
                                    label="Shop Address"
                                    name="shopAddress"
                                    placeholder="Enter Shop Address"
                                    defaultValue={userCreate.shopAddress}
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                    rows={2}
                                    multiline
                                />
                            </Grid>
                            <Grid item md={3} xs={10} sm={3}>
                                <Button
                                    variant="contained" color="lightBlue" onClick={() => setAddShopDialog(true)}>
                                    Add Shops
                                </Button>
                            </Grid>
                            <p> Employee Package Management</p>
                            <br />
                            <Grid container spacing={1} justifyContent="center"
                                alignItems="center" sx={{
                                    padding: 1,
                                }}>
                                {[...adminConfisg.packages].map(key => (
                                    <Grid item xs={12} md={6} sm={6} lg={6}>
                                        <FormControlLabel control={<CheckBox
                                            checked={getUserPackages(key)}
                                            onChange={handleInputChange}
                                            name="packageChecked"
                                            fullWidth
                                            id={key}
                                        />}
                                            label={key} />
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid item md={4} xs={6} sm={4}>
                                <FormControlLabel name="isDemo" checked={userCreate.isDemo} control={<CheckBox sx={{
                                    color: "#3e0b55",
                                    '&.Mui-checked': {
                                        color: "#cf9a26",
                                    },
                                }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />} label="Demo Purpose" />
                            </Grid>
                            <Grid item md={4} xs={6} sm={4}>
                                <FormControlLabel name="Hold" checked={userCreate.hold} control={<CheckBox sx={{
                                    color: "#3e0b55",
                                    '&.Mui-checked': {
                                        color: "#cf9a26",
                                    },
                                }}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />} label="Hold" />
                            </Grid>
                            <Grid item md={6} xs={12} sm={6}>
                                <TextField
                                    id="validity"
                                    label="Validity(In Months)"
                                    name="validity"
                                    type="number"
                                    placeholder="Enter Validity in Month"
                                    defaultValue={userCreate.validity}
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sm={6}>
                                <TextField
                                    type="date"
                                    id="startDate"
                                    name="Start Date"
                                    variant="filled"
                                    size="small"
                                    label="Enter Start Date"
                                    value={formatDateInReact(userCreate.startDate)}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            min: formatDateInReact(todayFormattedDate),
                                            max: '2999-01-01',
                                        },
                                    }} />
                            </Grid>
                            <Grid item md={6} xs={12} sm={6}>
                                <TextField
                                    id="amountFinalized"
                                    label="Amount Finalized"
                                    name="amountFinalized"
                                    type="number"
                                    placeholder="Enter Amount"
                                    defaultValue={userCreate.amountFinalized}
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                />
                            </Grid>
                            <Grid item md={4} xs={12} sm={6}>
                                <TextField
                                    disabled
                                    id="remainingDues"
                                    label="Dues"
                                    name="remainingDues"
                                    type="number"
                                    placeholder="Remaining Dues(If Any)"
                                    defaultValue={userCreate.remainingDues}
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sm={6}>
                                <TextField
                                    id="userSpecialRequest"
                                    label="Special Request(If Any)"
                                    name="userSpecialRequest"
                                    rows={3}
                                    multiline
                                    defaultValue={userCreate.userSpecialRequest}
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                />
                            </Grid>
                            <Grid item md={6} xs={12} sm={6}>
                                <TextField
                                    id="adminComments"
                                    label="Comments"
                                    name="adminComments"
                                    rows={3}
                                    multiline
                                    defaultValue={userCreate.adminComments}
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                />
                            </Grid>
                            <Grid item md={4} xs={12} sm={6}>
                                <Button
                                    variant="contained" color="bRed" onClick={() => setPayDialog(true)}>
                                    Pay & Save
                                </Button>
                            </Grid>
                            <Grid item md={4} xs={12} sm={6}>
                                <Button
                                    variant="contained" color="primary">
                                    Save
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item md={6} xs={12} sm={6}>

                        <Grid container spacing={1} justifyContent="center"
                            alignItems="center" sx={{
                                padding: 1,
                            }}>


                            <h4 style={{
                                fontFamily: "monospace"
                            }}><u>Shop Details</u></h4>

                            
                        </Grid>
                    </Grid>

                </Grid>
            </Container>

        );
    };

    return (
        <React.Fragment>
            <SnpComponent
                content={renderSnpContent()}
                showDialog={snpComponent.showDialog}
                isLoader={isLoader}
                title={snpComponent.title}
                subTitle={snpComponent.subTitle}
                negativeAction={snpComponent.negativeAction}
                positiveReq={snpComponent.positiveReq}
                positiveAction={snpComponent.positiveAction}
                negativeReq={snpComponent.negativeReq}
                handleDialogClose={handleSnpDialogClose}
                iconReq={snpComponent.iconReq}
                onSnackClose={handleSnackClose}
                cancelExtra={snpComponent.cancelExtra}
                onCancel={handleSnpCancel}
                snackBarOpen={snpComponent.snackBarOpen}
                snackBarMsg={snpComponent.snackBarMsg}
                outSideClickDismiss={snpComponent.outSideClickDismiss}
                handleSubmit={handleSnpSubmit}
            />
        </React.Fragment>
    );
}