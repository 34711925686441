// src/utils/indexedDB.js
import { openDB } from 'idb';

const DB_NAME = 'snp_react_db';
const STORE_NAME = 'snp_react_prd_table';
const DB_VERSION = 1;

const dbPromise = openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAME)) {
            db.createObjectStore(STORE_NAME, { keyPath: 'id' });
        }
    },
});

export async function addDataIndb(data) {
    const db = await dbPromise;
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    await store.put(data);
    await tx.done;
}

export async function getDataFromDb(id) {
    const db = await dbPromise;
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);
    const data = await store.get(id);
    await tx.done;
    return data;
}

export async function getAllData() {
    const db = await dbPromise;
    return db.getAll(STORE_NAME);
}

export async function deleteItemForDb(id) {
    const db = await dbPromise;
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    await store.delete(id);
    await tx.done;
}
