import React, { useEffect } from 'react';

const NotificationSound = ({ message, show }) => {
  useEffect(() => {
    if (show) {
      console.log("initiating sound");
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      fetch('/order_received_grab.mp3')
        .then(data => data.arrayBuffer())
        .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
        .then(decodedData => {
          const sound = audioContext.createBufferSource();
          sound.buffer = decodedData;
          sound.connect(audioContext.destination);
          sound.start(0);
        })
        .catch(error => console.error('Error playing audio:', error));
    }
  }, [show]);

  return (
    show && (
      <div className="notification">
        <p>{message}</p>
      </div>
    )
  );
};

export default NotificationSound;
