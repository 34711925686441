import React from 'react';
import { getRandomColor } from '../../constants/constant';

const NameCircle = ({ name, size = 100, textColor = '#fff',defaultIcon }) => {
  // Get the first letter of the name
  const firstLetter = name ? name.charAt(0).toUpperCase() : defaultIcon;

  // Function to generate a random color excluding black (#000000) and white (#FFFFFF)
  const bgColor = getRandomColor();

  const circleStyle = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: '50%',
    backgroundColor: bgColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: textColor,
    fontSize: `${size / 2}px`,
    fontWeight: 'bold',
    textAlign: 'center',
  };

  return <div style={circleStyle}>{firstLetter}</div>;
};

export default NameCircle;
