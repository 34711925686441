import React, { useState } from "react";
import LoginForm from "./LoginForm";
import { Navigate } from 'react-router-dom';
import { CART_INITIAL_STATE } from "../../constants/constant";
//import SsoLogin from "./SsoLogin";

const Login = () => {
  const [isSubmitted, setIsSubmitted] = useState(CART_INITIAL_STATE.loggedIn);

  const submitForm = () => {
    setIsSubmitted(true);
  };
  return (
    <>{
        !isSubmitted ? <LoginForm submitForm={submitForm} /> : <Navigate replace to="/dashboard" />}</>
  );
};

export default Login;
