import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import './fabstyle.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';

const SingleFab = ({ onClick, backgroundColor = '#007bff', color = '#ffffff' }) => {
  const styles = {
    fab: {
      position: 'fixed',
      width: '56px',
      height: '56px',
      borderRadius: '50%',
      backgroundColor: backgroundColor,
      color: color,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      bottom: '24px',
      right: '24px',
      cursor: 'pointer',
      boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
      border: 'none',
      outline: 'none',
      transition: 'all 0.2s ease-in-out',
    },
  };

  return (
    <Tooltip title="Watch Me">
    <button style={styles.fab} onClick={onClick}>
      <OndemandVideoIcon/>
    </button>
    </Tooltip>
  );
};

SingleFab.propTypes = {
  onClick: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
};

export default SingleFab;