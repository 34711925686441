import React, { useLayoutEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "../global/loader/Loader";
import {
    GET_PRODUCT_FOR_CHARGE,
    SET_PRODUCT_SERVICE_CHARGE,
    AXIOS_TIMEOUT,
    getPreUrlAccordingToCart,
    SUCCESS,
    CART_INITIAL_STATE,
    isRoleAllowed,
    ADMINRIGHTS
} from "../../constants/constant";
import axios from "axios";
import DialogBox from "../global/DialogBox";
import {
    Button
} from "@mui/material";
import CustomDataGrid from "../global/CustomDataGrid";
import { useSelector } from "react-redux";


export default function ProductServiceCharge({ showDialogBoxWithPaper }) {
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [productData, setProductData] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);


    const [productSingleData, setSingleProductData] = useState({
        catgName: "",
        prdName: "",
        composition: "",
        brandName: "",
        prdId: "",
        productImage: [],
        vId: "",
        vName: "",
        vServiceCharge: 0,
    });
    const [isError, setError] = useState(false);
    const handleInputChange = (event) => {
        //console.log("handleInputChange",event);
        const { name, value } = event.target;
        setSingleProductData({
            ...productSingleData,
            [name]: value,
        });
    };



    const columns = [
        {
            field: "productImage",
            headerName: "Icon",
            width: 120,
            renderCell: (rowData) => (
                <div>
                    <img
                        alt=""
                        style={{ height: "80px", width: "80px", borderRadius: "40%" }}
                        src={storeApiUrl.BACKEND_BASE_URL + "files/" + rowData.row.productImage[0]}
                    ></img>
                </div>
            ),
        },
        { field: 'catgName', headerName: 'Category Name', width: 200 },
        {
            field: 'productName-brandName', headerName: 'Product Details', width: 230,
            renderCell: (val) => {
                return val.row.prdName + "-" + val.row.brandName;
            }
        },
        {
            field: 'vServiceCharge', headerName: 'Service Charge', width: 200
        },
        {
            field: "edit",
            headerName: "Edit",
            width: 150,
            renderCell: (cellValues) => {
                return (
                    <EditIcon
                        onClick={(event) => {
                            if (isRoleAllowed(ADMINRIGHTS)) {
                                handleCellClick(event, cellValues);
                            } else {
                                showDialogBoxWithPaper("You Are not allowed to Add/Update.Contact the Owner");
                            }
                        }}
                    >
                    </EditIcon>
                );
            }
        }
    ];


    const handleCellClick = (param, event) => {
        //  console.log("setSingleproductData : ", event.row);

        setSingleProductData({
            ...productSingleData,
            prdName: event.row.prdName,
            brandName: event.row.brandName,
            prdId: event.row.prdId,
            productImage: event.row.productImage,
            vId: event.row.vId,
            vName: event.row.vName,
            vServiceCharge: event.row.vServiceCharge,
            catgName: event.row.catgName,
        });
        setShowDialog(true);
    };

    const clearProductData = () => {
        setSingleProductData({
            ...productSingleData,
            catgName: "",
            prdName: "",
            composition: "",
            brandName: "",
            prdId: "",
            productImage: [],
            vId: "",
            vName: "",
            vServiceCharge: 0.25,
        })
    }

    const handleSubmitCharge = async () => {

        setFormSubmitted(true);
        if (!isValid()) {
            return;
        }

        if (parseFloat(productSingleData.vServiceCharge) < 0.25) {
            setError(true);
        } else {
            let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + SET_PRODUCT_SERVICE_CHARGE;
            let filledData = {
                jcJson: {
                    Admin: CART_INITIAL_STATE.userEmail,
                    value: CART_INITIAL_STATE.userPassWord,
                    vId: productSingleData.vId,
                    vName: productSingleData.vName,
                    serviceCharge: parseFloat(productSingleData.vServiceCharge),
                    prdId: productSingleData.prdId,
                }
            };
            // console.log("handleSubmitCharge => ", filledData);
            axios({
                method: "POST",                 //POST_ACTION
                url: catgUrl,
                data: filledData,
                timeout: AXIOS_TIMEOUT
            }).then(response => {
                //console.log("responses => ", response.data);
                setShowDialog(false);
                if (response.data.status === SUCCESS) {
                    //console.log("responses => ", response.data.jcJson.prdId);
                    getProductDetails();
                }
            }).catch(error => {
                setShowDialog(false);
                console.log("error : ", error);
                // window.location.href = "#/error";
            });
        }
    };

    const getProductDetails = async () => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + GET_PRODUCT_FOR_CHARGE;
        let data = {
            jcJson: {
                Admin: CART_INITIAL_STATE.userEmail,
                value: CART_INITIAL_STATE.userPassWord,
            }
        };
        //  console.log("getProductDetails  => ",data);
        axios.post(
            cartUrl,
            data
        )
            .then((response) => {
                //   console.log("response  => ",response.data);
                setDataLoaded(true);
                if (response.data.status === SUCCESS) {
                    setProductData(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                setDataLoaded(true);
                console.log("errr ", error);
                //window.location.href = "#/error";
            });
    };
    useLayoutEffect(() => {
        //  console.log('Printing Login Details : ',CART_INITIAL_STATE);
        getProductDetails();
    }, [storeCartType,storeApiUrl]);




    const renderDialogContent = () => {
        return (
            <React.Fragment>
                <div>
                    {productSingleData.productImage[0] ?
                        <img
                            alt=""
                            style={{ height: "80px", width: "80px", borderRadius: "40%" }}
                            src={storeApiUrl.BACKEND_BASE_URL + "files/" + productSingleData.productImage[0]}
                        /> : null
                    }
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                disabled
                                id="catgName"
                                label="Cateogory Name"
                                name="catgName"
                                defaultValue={productSingleData.catgName}
                                onChange={handleInputChange}

                                fullWidth
                            />
                            <br /><br />
                            <TextField
                                disabled
                                id="brandName"
                                label="Brand Name"
                                name="brandName"
                                defaultValue={productSingleData.brandName}
                                onChange={handleInputChange}

                                fullWidth
                            />
                            <br /><br />
                            <TextField
                                disabled
                                id="vName"
                                label="Service Name"
                                name="vName"
                                defaultValue={productSingleData.vName}
                                onChange={handleInputChange}

                                fullWidth
                            />
                            <br /><br />

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                disabled
                                id="prdName"
                                label="Item Name"
                                name="prdName"
                                defaultValue={productSingleData.prdName}
                                onChange={handleInputChange}

                                fullWidth
                            />
                            <br /><br />
                            <TextField
                                disabled
                                id="composition"
                                label="Composition"
                                name="composition"
                                defaultValue={productSingleData.composition}
                                onChange={handleInputChange}

                                fullWidth
                            />
                            <br /><br />
                            <TextField
                                required
                                id="vServiceCharge"
                                label="Enter Service Charge(Min : 0.25)"
                                name="vServiceCharge"
                                value={productSingleData.vServiceCharge}
                                onChange={handleInputChange}
                                fullWidth
                                error={formSubmitted && !productSingleData.vServiceCharge}
                                helperText={getErrorMessage('vServiceCharge')}
                            />
                            <br /><br />
                        </Grid>
                    </Grid>
                    {isError ?
                        <p className="error">Service Charge can not be less than 0.25</p>
                        :
                        null
                    }
                </div>
            </React.Fragment>

        );
    }

    const renderDialogAction = () => {
        return (
            <React.Fragment>
                <Button variant="contained" color="primary" onClick={handleSubmitCharge}>
                    Submit
                </Button>
                <Button variant="contained" color="primary" onClick={handleDialogClose}>
                    Cancel
                </Button>
            </React.Fragment>
        );
    }

    const handleDialogClose = () => {
        // console.log("handleDialogClose");
        setShowDialog(false);
        clearProductData();
        setFormSubmitted(false);
    };

    const getErrorMessage = (field) => {
        if (!formSubmitted) {
            return;
        } else if (!productSingleData[field]) {
            return 'Field is required';
        }
    }

    const isValid = () => {
        let isServiceDataValid = true;
    
        if (!productSingleData.vServiceCharge ) {
                isServiceDataValid = false;
        }
    
        return isServiceDataValid;
    }



    return (
        <React.Fragment>
            <div>
                < DialogBox
                    showDialog={showDialog}
                    title={"Update Service Charge"}
                    onClose={handleDialogClose}
                    dialogContent={renderDialogContent()}
                    dialogActions={renderDialogAction()}
                ></DialogBox>
            </div>
            <div style={{ height: 400, width: "100%" }}>
                {
                    isDataLoaded ? <CustomDataGrid
                        getRowId={row => row.prdId}
                        rows={productData}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                    /> : <Loader />
                }
            </div>
        </React.Fragment>
    );
}