import React, { useEffect, useState } from 'react';
import { printDebugLog } from '../../../constants/constant';

function DigitalClock() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    printDebugLog("digitalclock useeffect")
    const timerId = setInterval(() => {
      setTime(new Date());  // Update time every second
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timerId);
  }, []);

  const formatTime = (date) => {
    let hours = date.getHours();
    hours = hours % 12 === 0 ? 12 : hours % 12;
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const period = hours < 12 ? 'AM' : 'PM';
    //printDebugLog("digitalclock ",date)
    return `${hours}:${minutes}:${seconds} ${period}`;
  };

  return (
    <div>
      <p>{formatTime(time)}</p>
    </div>
  );
}

export default DigitalClock;
