import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
// import Typography from "@mui/material/Typography";
//import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { CartDets,  isRoleAllowed, isFeatureAllowed, ADMINRIGHTS } from "../../constants/constant";
import {  Grid, IconButton, ListItemButton, Tooltip } from "@mui/material";
import { sideBarNavItems } from "./sidebarNavItems";
import "./sidebar.css";
import { useSelector } from "react-redux";

const drawerWidth = 270;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function Sidebar({ open, toggleDrawer, setComponentIndex }) {
  const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
  const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
  React.useEffect(() => {
    //SideBar
  }, [storeCartType,storeActiveShopHash]);

  const isSideBarRoleAllowed = (permission) => {
      if (isRoleAllowed(ADMINRIGHTS)) return true;
      return isRoleAllowed(permission);
  }

  const renderSideNavItem = (item, index) => {
    if (
      (item.cartType === CartDets.All ||
        item.cartType === storeCartType) && isSideBarRoleAllowed(item.permission) && isFeatureAllowed(item.feature)
    ) {
      return (
        <div key={index} style={{ justifyContent: "center" , alignContent:"center" ,alignItems:"center"}}>
          <List >
          
            <ListItemButton sx={{
              '@media (max-width: 600px)': {
                justifyContent:'center',
              },
              '& .MuiTypography-root': {
                color: 'background.paper',
              },
            }} button onClick={() => setComponentIndex(item.index)}>
              <Tooltip title={item.title}>
                <ListItemIcon sx={{
                  '& .MuiSvgIcon-root': {
                    minWidth: '56px',
                    color: 'white',
                  },
                  '& .MuiSvgIcon-root:hover': {
                    minWidth: '56px',
                    color: 'blue',
                  },
                }}>
                  {item.icon}</ListItemIcon>
              </Tooltip>
              <ListItemText primary={item.title} />
              
            </ListItemButton>
          </List>

        </div>
      );
    }
  };
  return (
    <div style={{ alignItems:"center"}}>
      <Drawer
      style={{ alignItems:"center"}}
        sx={{
          boxShadow: 12,
          '& .MuiList-root': {
            backgroundColor: 'primary.datagridrow',
          },
          '& .MuiList-root:hover': {
            backgroundColor: 'rgba(1, 51, 62, 0.8)',
            '& .MuiTypography-root': {
              marginLeft: "10px",
            },
          },
        }} 
        variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
            backgroundColor: "primary.datagridrow"
          }}
        >
          <Grid container spacing={1} 
            alignItems="center" justifyContent="center" sx={{
              padding: 0.1, m: 0.5,
            }}>
            {/* {
              open && <Grid item md={6} lg={6} xs={6} sm={6}>
                <Typography variant="p" alignContent="center" justifyItems="center" color={"white"} style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize:"14px" }}>
                  {storeActiveShopHash.shop}
                </Typography>
              </Grid>
            }  */}
            <Grid item md={6} lg={6} xs={6} sm={6}>
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
        {sideBarNavItems.map((item, index) => renderSideNavItem(item, index))}

      </Drawer>
    </div>
  );
}
