import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { keyframes, styled } from "@mui/material";
import { getRandomColor } from "../../../constants/constant";


const rippleKeyframes = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
`;

  const RippleCircularProgress = styled(CircularProgress)(({ theme }) => ({
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      transform: 'translate(-50%, -50%)',
      animation: `${rippleKeyframes} 1.5s infinite`,
    },
  }));

export default function SnpProgress({mColor="primary", mSize = 70}) {

  return (
    <>
    <RippleCircularProgress
        color={mColor}
        size={mSize}
      /></>
  );
}
