import {  updateProfileOnChangeOfSession } from "../constants/constant";

export const setCartType = cartType => {

    const loginData = JSON.parse(sessionStorage.getItem('carInitialState'));
    const loginDataUpdate = { ...loginData, cartType }; 
    sessionStorage.setItem('carInitialState', JSON.stringify(loginDataUpdate));

    return (dispatch) => new Promise((resolve, reject) => {
        dispatch({
            type: "SET_CART_TYPE",
            cartType: cartType
        })
    })
}

export const setActiveShopHash = activeShopHash => {
    //console.log("activeShopHash => ",activeShopHash);
    const loginData = JSON.parse(sessionStorage.getItem('carInitialState'));
   // console.log("loginData => ",loginData);
    const loginDataUpdate = { ...loginData, activeShopHash };
    //console.log("loginDataUpdate => ",loginDataUpdate); 
    sessionStorage.setItem('carInitialState', JSON.stringify(loginDataUpdate));
    //setLoginResponse(loginDataUpdate, loginDataUpdate.passWord);
    updateProfileOnChangeOfSession(activeShopHash);
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch({
            type: "SET_ACTIVE_HASH",
            activeShopHash: activeShopHash
        })
    })
}


export const setApiUrl = BASE_URL => {
    //console.log("setApiUrl",BASE_URL);
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch({
            type: "SET_URL_TYPE",
            BASE_URL: BASE_URL
        })
    })
}