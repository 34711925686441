import React, { useLayoutEffect, useState } from "react";
import "../resturant/RestoProductStock.css";
import { Grid, TableBody, Typography, Tooltip, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TextField } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AdsClickIcon from '@mui/icons-material/AdsClick';
import {
  SUCCESS,
  CART_INITIAL_STATE,
  GETTRIPDETAILSRIDER,
  SNP_API_KEY,
  COMPONENT_PARAMS,
  DashboardComponent,
  printDebugLog,
  TRIP_RUNNING,
  FETCHRIDERVIEW,
  isEmpty,
  CLEAR_RIDER_CASH_PAY_DEBT,
  GETVIDEOPATH,
  getPreUrlAccordingToCart,
} from "../../constants/constant";
import DialogBox from "../global/DialogBox";
import {
  Button,
} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import CustomDataGrid from "../global/CustomDataGrid";
import { useSelector } from "react-redux";
import { ViewCozyOutlined } from "@mui/icons-material";
import SnpComponent from "../global/SnpComponent";
import Loader from "../global/loader/Loader";
import { snpAxios } from '../global/api';
import YouTubeVideo from "../global/YouTubeVideo";
import SingleFab from "../global/fab/SingleFab";
import { SCREEN_TRACK_RIDER } from "../../constants/VideoConstant";
import axios from "axios";


export default function RiderDashBoard({setComponentIndex}) {
  const INITIAL_SNP_COMPONENT = {
    showDialog: false,
    outSideClickDismiss: false,
    title: "",
    subTitle: "",
    negativeAction: "",
    positiveAction: "",
    positiveReq: false,
    isLoader: false,
    iconReq: "",
    snackBarOpen: false,
    snackBarMsg: "",
    cancelExtra: false,
    negativeReq: false,
    requestType: 0,
  };
  const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);

  const storeApiUrl = useSelector(
    (state) => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL
  );
  const storeCartType = useSelector(
    (state) => state.setCartTypeReducer.cartType
  );
  const storeActiveShopHash = useSelector(
    (state) => state.setActiveShopHashReducer.activeShopHash
  );
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [riderList, setRiderList] = useState([]);
  const [dialogLoader, setDialogLoader] = useState(false);
  const [videoModel, setVideoModel] = useState({show:false,path:""});

  const INITIAL_RIDER_DETAILS = {
    riderId: "",
    liveOrderCount: 0,
    status: "",//No Use
    orderList: [],
    cashDebt:0.0,
  };

  const INITIAL_ORDER_DETAILS = {
    tripId: "",
    tripStatus: "",
    billNo: "",
    destinationLat: "",
    destinationLon: "",
    destinationAddress: "",
    finalPrice: 0.0,
    homeDelvOtp: "",
    createdAt: "",
    assignedBy: "",
    lastUpdatedAddress: "",
    lastLat: 0.0,
    lastLon: 0.0,
    createdAt_trip: "",
  };

  const [riderDetails, setRiderDetails] = useState(INITIAL_RIDER_DETAILS);
  const [mockAddressUpdate,setMockAddressUpdate] = useState({
    address:"",lat:0.0,lon:0.0,
  })

  const [orderDetails, setOrderDetails] = useState(INITIAL_ORDER_DETAILS);
  const [link,setLink] = useState("");
  const [mIndex,setIndex] = useState(-1);

  const handleSnackClose = () => {
    setSnpComponent({
      ...snpComponent,
      snackBarOpen: false,
      snackBarMsg: "",
    });
  };

  const handleView = (param, event) => {
    console.log("handleView", event.row);
    // setDialogLoader(true);
    // setShowDialog(true);
    getRiderDetails(event.row.id,false)
  };
  const columns = [
    { field: "name", headerName: "Rider Name", width: 250 },
    { field: "mob", headerName: "Mobile", width: 200 },
    { field: "status", headerName: "status", width: 280 },
    { field: "cashDebt", headerName: "Debt Cash", width: 200 },
    {
      field: "view",
      headerName: "View",
      width: 90,
      renderCell: (cellValues) => {
        return (
          <ViewCozyOutlined
            onClick={(event) => {
              handleView(event, cellValues);
            }}
          ></ViewCozyOutlined>
        );
      },
    },
  ];


  const getAllRiders = async () => {
    let cartUrl = storeApiUrl.BACKEND_BASE_URL + FETCHRIDERVIEW;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,        
      },
    };
    //  console.log("getRestoDetails  => ",data);
    snpAxios
      .post(cartUrl, data)
      .then((response) => {
        // console.log("response  => ", response.data);
        setDataLoaded(true);
        if (response.data.status === SUCCESS) {
          setRiderList(response.data.jcJson.data);
        }
      })
      .catch((error) => {
        setDataLoaded(true);
        console.log("errr ", error);
        //window.location.href = "#/error";
      });
  };

  const getRiderDetails = async (empId,isRefresh) => {
    printDebugLog("getRiderDetails",empId,isRefresh);
    let cartUrl = storeApiUrl.BACKEND_BASE_URL + GETTRIPDETAILSRIDER;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        riderId: empId
      },
    };
    //  console.log("getRiderDetails  => ",data);
    if (isRefresh) 
      setDialogLoader(true);
    else
      setDataLoaded(false);
    setOrderDetails(INITIAL_ORDER_DETAILS);
    setLink("");
    snpAxios
      .post(cartUrl, data)
      .then((response) => {
        // console.log("response  => ", response.data);
        if (isRefresh)
          setDialogLoader(false);
        else
          setDataLoaded(true);
        if (response.data.status === SUCCESS) {
          if (!isRefresh)
            setShowDialog(true);
          setRiderDetails({
            ...riderDetails, ...response.data.jcJson,
            orderList: response.data.jcJson.value_1,
            riderId: empId
          });
          if (!isEmpty(response.data.jcJson.value_1) && mIndex > -1) {
              let data  = response.data.jcJson.value_1[mIndex];
              createOrderDetails(data,mIndex);
          } 
        }
      })
      .catch((error) => {
        if (isRefresh)
          setDialogLoader(false);
        else
          setDataLoaded(true);
        console.log("errr ", error);
        //window.location.href = "#/error";
      });
  };

  const handleClearCashPay = () => {
    let cartUrl = storeApiUrl.BACKEND_BASE_URL + CLEAR_RIDER_CASH_PAY_DEBT;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        riderId: riderDetails.riderId,
      },
    };
    printDebugLog("handleClearCashPay data", data);
    setDialogLoader(true);
    setShowDialog(false);
    snpAxios
      .post(cartUrl, data)
      .then((response) => {
        printDebugLog("handleClearCashPay response  => ", response.data);
        if (response.data.status === SUCCESS) {
          getAllRiders();
        }
      })
      .catch((error) => {
        setDialogLoader(false);
        console.log("errr ", error);
        //window.location.href = "#/error";
      });
  };

  const handleSubmitMockData = () => {
    printDebugLog("handleSubmitMockData", mockAddressUpdate);
    if (isEmpty(orderDetails.tripId)) {
      setSnpComponent({
        ...snpComponent,
        snackBarMsg:"Select Trip First",
        snackBarOpen:true,
      })
    } else  {
      let cartUrl = storeApiUrl.BACKEND_BASE_URL + "cart/mock-update-trip-for-customer";
      let data = {
        jcJson: {
          apiToken: CART_INITIAL_STATE.apiToken,
          shopHash: storeActiveShopHash.id,
          tripId: orderDetails.tripId,
          tripStatus:TRIP_RUNNING,
          lat:mockAddressUpdate.lat,
          lon:mockAddressUpdate.lon,
          address:mockAddressUpdate.address,
        },
      };
      printDebugLog("handleSubmitMockData data",data);
      setDialogLoader(true);
      snpAxios
        .post(cartUrl, data)
        .then((response) => {
          console.log("handleSubmitMockData response  => ", response.data);
          if (response.data.status === SUCCESS) {
              getRiderDetails(riderDetails.riderId,false);
          }
        })
        .catch((error) => {
          setDialogLoader(false);
          console.log("errr ", error);
          //window.location.href = "#/error";
        });
    }
    
  }

//https://www.grusingh.com/post/google-maps-embed-api/
  const createOrderDetails = (val,index) => {
      let mUrl;
      if (isEmpty(val.lastUpdatedAddress)) {
        mUrl = "https://www.google.com/maps/embed/v1/view?key="+SNP_API_KEY+"&center="+val.destinationLat+","+val.destinationLon+"&zoom=8&maptype=satellite"
        //mUrl = "https://www.google.com/maps/embed/v1/streetview?key="+SNP_API_KEY+"&location="+val.destinationLat+","+val.destinationLon+"&heading=210&pitch=10&fov=35"
        //mUrl = "https://www.google.com/maps/embed/v1/directions?key="+SNP_API_KEY+"&origin=57.737640,12.114230&destination=57.708477,11.965992&mode=driving&zoom=10";
      }else 
        mUrl = "https://www.google.com/maps/embed/v1/directions?key="+SNP_API_KEY+"&origin="+val.lastLat+","+val.lastLon+"&destination="+val.destinationLat+","+val.destinationLon+"&mode=driving&zoom=10";
      console.log("createOrderDetails",val, mUrl,index);
      setLink(mUrl)
      setOrderDetails(val);
      setIndex(index);
  }

  const handleBillIconClick = (event) => {
    printDebugLog("handleBillIconClick : ", event);
    COMPONENT_PARAMS.sBillNo = event.billNo;
    COMPONENT_PARAMS.isSearchByShowBills = true;
    setComponentIndex(DashboardComponent.RESTOBILLINGMODULE);
  };

  
  const getYoutubeVideo = (videoName,lang) => {
    let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETVIDEOPATH + `?adminId=${CART_INITIAL_STATE.apiToken}`
        + `&name=${videoName}` + `&lang=${lang}`;
    let data = {};
    axios.get(
        cUrl,
        data
    )
        .then((response) => {
            //printLog("getSingleConfigs<=",response.data);
            if (response.data !== '') {
                setVideoModel({...videoModel,path : response.data});
            } else 
                setVideoModel({...videoModel,path : ''});
        }).catch((error) => {
            //printLog("error : ", error);
            //window.location.href = "#/error";
            setVideoModel({...videoModel,path : ''});
        });
};

  useLayoutEffect(() => {
    getYoutubeVideo(SCREEN_TRACK_RIDER,'hi');
    getAllRiders();
  }, [storeCartType, storeActiveShopHash, storeApiUrl]);


  const renderTripContent = () => {
    return (
      <>
        <Grid container spacing={1}>
          {
            !isEmpty(link) &&
            <Grid item md={6} xs={12} lg={6} sm={6}>
              <iframe width="300"
                height="300"
                loading="lazy"
                allowfullscreen=""
                referrerpolicy="no-referrer-when-downgrade"
                src={link}
              />
            </Grid>
          }
          <Grid item md={6} xs={12} lg={6} sm={6}>
            {
              !isEmpty(orderDetails.billNo) &&
              <Paper elevation={0}
                autoHeight
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#f6f6f6",
                  boxShadow: 1,
                  borderRadius: 1,
                  mb: 0.5,
                  padding: 0.5
                }} >
                <Grid container spacing={0} sx={{
                  padding: 0.1,
                }}>
                  <Grid md={12} xs={12} lg={12} sm={12}>
                    <center>
                      <Typography variant="subtitle4" component="div" style={{ color: "#3b040e", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '18px' }} ><u>Selected Trip</u></Typography>
                    </center>
                  </Grid>
                  <Grid item md={12} xs={12} lg={12} sm={12}>
                      <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px' }} >Attached Bill:
                        <Typography display={'inline'} style={{ justifyContent: "center", alignContent: "center", alignItems: "center", color: "#2e2b2b", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '12px' }} >{orderDetails.billNo}<>
                          <AdsClickIcon  
                            onClick={(event) => {
                              handleBillIconClick(orderDetails);
                            }} />
                        </></Typography>
                      </Typography>
                    
                  </Grid>
                  {
                    !isEmpty(orderDetails.lastUpdatedAddress) &&
                    <Grid item md={12} xs={12} lg={12} sm={12}>
                      <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '14px' }} >Rider Current Address:</Typography>
                    </Grid>
                  }
                  {
                    !isEmpty(orderDetails.lastUpdatedAddress) &&
                    <Grid item md={12} xs={12} lg={12} sm={12}>
                      <Typography variant="subtitle4" component="div" style={{ color: "#2e2b2b", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '12px' }} >{orderDetails.lastUpdatedAddress}</Typography>
                    </Grid>
                  }
                  <Grid item md={12} xs={12} lg={12} sm={12}>
                    <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '14px' }} >Customer Address:</Typography>
                  </Grid>
                  <Grid item md={12} xs={12} lg={12} sm={12}>
                    <Typography variant="subtitle4" component="div" style={{ color: "#2e2b2b", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '12px' }} >{orderDetails.destinationAddress}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            }
          </Grid>
          <Grid item md={12} xs={12} lg={12} sm={12}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table" className="rate_card">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Bill No</TableCell>
                    <TableCell align="center">Trip Status</TableCell>
                    <TableCell align="center">Address</TableCell>
                    <TableCell align="center">View Trip Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    riderDetails.orderList &&
                    Object.values(riderDetails.orderList).map((val, index) => {
                      return (
                        <TableRow
                          className="rate_card--row"
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell align="center" >{val.billNo}</TableCell>
                          <TableCell align="center" >{val.tripStatus}</TableCell>
                          <TableCell align="center" >{val.destinationAddress}</TableCell>
                          <TableCell align="center" ><>
                            <VisibilityIcon
                              onClick={(event) => {
                                //console.log("VisibilityIcon", val);
                                createOrderDetails(val,index);
                              }}
                            />
                          </></TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          

          {/* <Grid item md={6} xs={12} lg={6} sm={6}>
            <TextField id="lat" label="lat" variant="filled" size="small"
              required onChange={e => setMockAddressUpdate({
                ...mockAddressUpdate,
                lat: e.target.value
              })}  />
          </Grid>
          <Grid item md={6} xs={12} lg={6} sm={6}>
            <TextField id="lon" label="lon" variant="filled" size="small"
              required onChange={e => setMockAddressUpdate({
                ...mockAddressUpdate,
                lon: e.target.value
              })} />
          </Grid>
          <Grid item md={6} xs={12} lg={6} sm={6}>
            <TextField id="address" label="address" variant="filled" size="small"
              required onChange={e => setMockAddressUpdate({
                ...mockAddressUpdate,
                address: e.target.value
              })}  />
          </Grid>
          <Grid item md={6} xs={12} lg={6} sm={6}>
            <Button variant="contained" color="primary" onClick={handleSubmitMockData}>
              Mock Update
            </Button>
          </Grid>
          <Grid item md={6} xs={12} lg={6} sm={6}>
            <a href="https://www.latlong.net/" target="_blank">Get Lat Lon By drag and click on place</a>
          </Grid>
          <a href="https://gps-coordinates.org/coordinate-converter.php" target="_blank">LatLon to Address</a> */}
        </Grid>
      </>
    );
  };

  const renderDialogContent = () => {
    return (
      <React.Fragment>
        <div>
          <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{ p: 1 }}>
            <Grid item md={12} xs={12} sm={12}>
              {
                !isEmpty(riderDetails.riderId) && <Tooltip title="Refresh Rider Details">
                  <RefreshIcon onClick={(e) => getRiderDetails(riderDetails.riderId, true)} />
                </Tooltip>
              }
              
              {
                riderDetails.liveOrderCount === 0 ?
                  <Typography variant="p" component="div" className="bill-header" fontSize="24px">No Trip Assigned</Typography>
                  : dialogLoader ? <Loader /> : renderTripContent()
              }
            </Grid>
            <Grid item md={12} xs={12} lg={12} sm={12}>
              <Typography variant="subtitle4" component="div" style={{ color: "#1a0601", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '18px' }} >{riderDetails.cashDebt > 0.0 ? "Total Cash to pay: ₹" + riderDetails.cashDebt : "Nothing to pay"}</Typography>
            </Grid>
            {
              riderDetails.cashDebt > 0.0 && <Button variant="contained" color="success" onClick={handleClearCashPay}>
                Clear Cash Pay
              </Button>
            }
          </Grid>

        </div>
      </React.Fragment>
    );
  };

  const renderDialogAction = () => {
    return (
      <React.Fragment>
        <Button variant="contained" color="primary" onClick={handleDialogClose}>
          Dismiss
        </Button>
      </React.Fragment>
    );
  };

  const handleDialogClose = () => {
    // console.log("handleDialogClose");
    setRiderDetails(INITIAL_RIDER_DETAILS);
    setOrderDetails(INITIAL_ORDER_DETAILS);
    setShowDialog(false);
    setLink("");
    setIndex(-1);
  };


  const renderSnpContent = () => {
    return (
      <React.Fragment>
        <div>
          <DialogBox
            showDialog={showDialog}
            title={"Rider Details"}
            onClose={handleDialogClose}
            dialogContent={renderDialogContent()}
            dialogActions={renderDialogAction()}
          ></DialogBox>
        </div>
        <CustomDataGrid
          rows={riderList}
          columns={columns}
          loading={!isDataLoaded}
        />
        {videoModel.show && <YouTubeVideo videoId={videoModel.path} onDismiss={(e) => setVideoModel({...videoModel,show:false})}/> }
        {videoModel.path && <SingleFab onClick={(e)=> setVideoModel({...videoModel,show:true})}/> }
      </React.Fragment>
    )
  };


  return (
    <>
      <SnpComponent
        content={renderSnpContent()}
        onSnackClose={handleSnackClose}
        snackBarOpen={snpComponent.snackBarOpen}
        snackBarMsg={snpComponent.snackBarMsg}
        outSideClickDismiss={snpComponent.outSideClickDismiss}
        showDialog={snpComponent.showDialog}
        title={snpComponent.title}
        subTitle={snpComponent.subTitle}
        negativeAction={snpComponent.negativeAction}
        positiveReq={snpComponent.positiveReq}
        positiveAction={snpComponent.positiveAction}
        negativeReq={snpComponent.negativeReq}
        cancelExtra={snpComponent.cancelExtra}
      />
    </>
  );

}
