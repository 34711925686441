import { GridToolbarContainer,GridToolbarColumnsButton
    ,GridToolbarFilterButton,
    GridToolbarDensitySelector, GridToolbarExport,
    GridMoreVertIcon,
    GridToolbarQuickFilter} from "@mui/x-data-grid";
import React ,{useState}from "react";
import {
    Grid,
    Button,Menu,MenuItem
  } from "@mui/material";
const GridToolbarCustom = ({ selectionModel }) => {
    const [anchorElMenu, setAnchorElMenu] = useState(null);
    const openMenu = Boolean(anchorElMenu);

    return (
        <GridToolbarContainer>
            <Grid container item xs>
                {/* default buttons */}
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                {/* <GridToolbarQuickFilter/>
                <GridMoreVertIcon/>
                <GridToolbarContainer/> */}
                {/* <GridToolbarExport /> */}
            </Grid>

            {/* <Grid>
                {
                    selectionModel.length !== 0 && <Button
                        variant="contained"
                        size="small"
                        disabled={selectionModel.length === 0}
                        startIcon={<GridMoreVertIcon />}
                        onClick={(event) => {
                            setAnchorElMenu(event.currentTarget);
                        }}
                    >
                        Actions
                    </Button>
                }
                
                    
                <Menu
                    id="menu-options"
                    anchorEl={anchorElMenu}
                    open={openMenu}
                    onClose={() => {
                        setAnchorElMenu(null);
                    }}
                >
                    <MenuItem /> //Clipped
                    <MenuItem /> //Clipped
                    <MenuItem /> //Clipped
                </Menu>
            </Grid> */}
        </GridToolbarContainer>
    );
}

export default GridToolbarCustom;